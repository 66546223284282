import { Closing } from '__generated__';
import { Card, MultiSelectFilter } from '@equitymultiple/react-eui';
import React, { useMemo } from 'react';
import arrayEqual from 'utils/arrayEqual';

import * as styles from '../OfferingInvestments.module.scss';
import {
  getAccreditationStatusOptions,
  getClosingOptions,
  getInvestmentStatusOptions,
} from './filterOptions';

export interface Props {
  accreditationStatusFilters: string[];
  closingFilters: number[];
  closings: Closing[];
  handleAccreditationStatusFilterChange(filters: string[]): void;
  handleClosingFilterChange(filters: number[]): void;
  handleInvestmentStatusFilterChange(filters: string[]): void;
  investmentStatusFilters: string[];
}

const accreditationStatusOptions = getAccreditationStatusOptions();
const investmentStatusOptions = getInvestmentStatusOptions();

const InvestmentFilters: React.FC<Props> = ({
  accreditationStatusFilters,
  closingFilters,
  closings,
  handleAccreditationStatusFilterChange,
  handleClosingFilterChange,
  handleInvestmentStatusFilterChange,
  investmentStatusFilters,
}) => {
  const closingOptions = useMemo(() => getClosingOptions(closings), [closings]);

  const handleFilterChange = (newFilters: (string | number)[][]) => {
    if (!arrayEqual(newFilters[0], investmentStatusFilters))
      handleInvestmentStatusFilterChange(newFilters[0] as string[]);

    if (!arrayEqual(newFilters[1], accreditationStatusFilters))
      handleAccreditationStatusFilterChange(newFilters[1] as string[]);

    if (!arrayEqual(newFilters[2], closingFilters))
      handleClosingFilterChange(newFilters[2] as number[]);
  };

  return (
    <>
      <Card className={styles.filtersCard}>
        <MultiSelectFilter
          filterGroups={[
            {
              hasClearAll: true,
              options: investmentStatusOptions,
              selectedOptions: [...investmentStatusFilters],
              title: 'Investment Status',
            },
            {
              hasClearAll: true,
              options: accreditationStatusOptions,
              selectedOptions: [...accreditationStatusFilters],
              title: 'Accreditation Status',
            },
            {
              hasClearAll: true,
              options: closingOptions,
              selectedOptions: [...closingFilters],
              title: 'Closings',
            },
          ]}
          mobileLayoutEnabled={false}
          onChange={handleFilterChange}
        />
      </Card>
    </>
  );
};

export default InvestmentFilters;
