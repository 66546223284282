import { Checkbox, Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValue,
  UseFormGetValues,
  UseFormSetValue,
  UseWatchProps,
} from 'react-hook-form';

import {
  Offering,
  OfferingInput,
  useGetStnOfferingsQuery,
} from '../../../__generated__';
import {
  setCheckboxFieldProps,
  setFieldProps,
} from '../../../utils/formHelpers';
import { reinvestmentCampaignOptions } from './constants';

interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors<OfferingInput>;
  getValues: UseFormGetValues<OfferingInput>;
  offering: Offering;
  setValue: UseFormSetValue<OfferingInput>;
  useWatch: (args: UseWatchProps<OfferingInput>) => FieldValue<OfferingInput>[];
}

const ReinvestmentFields: React.FC<Props> = ({
  control,
  errors,
  offering,
  getValues,
  setValue,
  useWatch,
}) => {
  const [sourceOfferingsOptions, setSourceOfferingsOptions] = useState<
    Option[]
  >([]);
  const [campaign, hasDrip] = useWatch({
    control,
    name: ['campaign', 'hasDrip'],
  });

  const { sourceOfferingIds } = getValues();

  const { data: offeringsData, loading: offeringsLoading } =
    useGetStnOfferingsQuery();

  const offerings = offeringsData?.stnOfferings?.data;

  // Build select for source offering
  useEffect(() => {
    const newOptions = [];
    const connectedOfferings = [];
    const availableOfferings = [];

    offerings?.forEach((sourceOffering) => {
      if (sourceOffering.id === offering.id) return;

      if (
        sourceOffering.connected &&
        sourceOfferingIds &&
        !sourceOfferingIds.includes(sourceOffering.id)
      ) {
        connectedOfferings.push({
          label: sourceOffering.title,
          value: sourceOffering.id,
          isDisabled: true,
        });
      } else {
        availableOfferings.push({
          label: sourceOffering.title,
          value: sourceOffering.id,
        });
      }
    });

    newOptions.push({
      label: 'Available Offerings',
      options: availableOfferings,
    });

    newOptions.push({
      label: 'Connected/Unavailable Offerings',
      options: connectedOfferings,
    });

    setSourceOfferingsOptions(newOptions);
  }, [sourceOfferingIds, offerings, offering?.id]);

  useEffect(() => {
    if (campaign && !hasDrip) setValue('campaign', null);
  }, [campaign, hasDrip, setValue]);

  return (
    <>
      <h3>Investment Elections</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="hasDrip"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                label="Investment Elections Available"
                className="margin20"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          {hasDrip && (
            <Controller
              name="campaign"
              control={control}
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  options={reinvestmentCampaignOptions}
                  onChange={(value) => field.onChange(value)}
                  label="Campaign"
                  disabled={offeringsLoading}
                  data-testid="campaign"
                />
              )}
            />
          )}
        </Col>
      </Row>
      <hr />

      <h3>Assign Reinvestment Source Offerings</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="sourceOfferingIds"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                onChange={(val) => field.onChange(val)}
                options={sourceOfferingsOptions}
                label="Source Offerings"
                disabled={offeringsLoading}
                multi
                showMultiLabelsBelow
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default ReinvestmentFields;
