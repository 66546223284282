import {
  Button,
  Card,
  EMLoadingIcon,
  Input,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  InvestmentAccountMoveDwollaBalancesInput,
  namedOperations,
  useGetInvestmentAccountDwollaBalancesQuery,
  useGetInvestmentAccountQuery,
  useMoveDwollaBalancesMutation,
} from '../../../../__generated__';
import * as loadingIconStyles from '../../../../styles/components/EMLoadingIcon.module.scss';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { numberMaskOptions } from '../../../../utils/masks';
import * as styles from '../InvestmentAccountForm.module.scss';
import { moveDwollaBalanceSchema } from './validations';

const toastMessages = {
  loading: 'Moving Dwolla balance',
  error: 'An error occurred while attempting to move the Dwolla balance',
  success: 'Dwolla balance moved',
};

const MoveDwollaBalances: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [moveDwollaBalance, moveDwollaBalanceState] =
    useMoveDwollaBalancesMutation();

  const {
    data: accountData,
    error: accountError,
    loading: accountLoading,
  } = useGetInvestmentAccountQuery({
    variables: {
      investmentAccountId: id as string,
    },
  });

  const {
    data: balanceData,
    error: balanceError,
    loading: balanceLoading,
  } = useGetInvestmentAccountDwollaBalancesQuery({
    variables: {
      investmentAccountId: id as string,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const onSubmit = async (
    submitData: Partial<InvestmentAccountMoveDwollaBalancesInput>,
  ) => {
    await callMutationWithToastMessages(moveDwollaBalance, toastMessages, {
      variables: {
        investmentAccountId: id,
        transferData: submitData,
      },
      refetchQueries: [
        namedOperations.Query.getInvestmentAccountDwollaBalances,
      ],
    });
  };

  const account = accountData?.investmentAccount.investmentAccount;
  const balanceFundingSource =
    balanceData?.investmentAccountDwollaBalances.balanceFundingSource;
  const bankFundingSources =
    balanceData?.investmentAccountDwollaBalances.bankFundingSources;
  const hasError = accountError || balanceError;
  const loading =
    accountLoading || balanceLoading || moveDwollaBalanceState.loading;
  const showForm = !loading && !hasError;

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading Dwolla balances');
      navigate('/accounts/#investment_accounts');
    }
  }, [hasError, navigate]);

  const defaultValues = useMemo(
    () => ({
      balance: balanceFundingSource?.balance || '',
      source: balanceFundingSource?.id || '',
    }),
    [balanceFundingSource],
  );

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<DefaultValues<InvestmentAccountMoveDwollaBalancesInput>>({
    mode: 'onBlur',
    resolver: yupResolver(moveDwollaBalanceSchema),
    defaultValues,
  });

  const formValues = getValues();

  useEffect(() => {
    if (defaultValues && defaultValues.balance !== formValues?.balance) {
      reset({ ...defaultValues });
    }
  }, [defaultValues, reset, formValues?.balance]);

  return (
    <>
      <h2>
        {loading
          ? 'Move Dwolla Balances'
          : `Move Dwolla Balances - ${account?.referenceId}`}
      </h2>
      <Card className="firstChildMarginTop0">
        <div className={styles.formTop}>
          <Link to="/accounts/#investment_accounts">&lt; Back</Link>
        </div>
        {showForm ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="moveDwollaBalancesForm"
          >
            <Row>
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id={field.name}
                    value={field.value || ''}
                    disabled
                    type="hidden"
                  />
                )}
              />
              <Col lg={4} md={6}>
                <h3>Balance</h3>
                <Controller
                  name="balance"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      {...setFieldProps(field, errors)}
                      allowDecimal
                      dollarMask
                      inputMaskOptions={numberMaskOptions}
                      disabled
                      label="Dwolla Balance"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6}>
                <h3>Destination</h3>
                <Controller
                  name="destination"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      options={
                        bankFundingSources?.map((fundingSource) => ({
                          label: `${fundingSource?.bankName} - ${fundingSource?.name}`,
                          value: fundingSource?.id || '',
                        })) || []
                      }
                      onChange={(e) => field.onChange(e)}
                      label="Bank Accounts"
                    />
                  )}
                />
              </Col>
            </Row>
            <Button type="submit" className="floatRight" loading={loading}>
              Move Dwolla Balance
            </Button>
          </form>
        ) : (
          <EMLoadingIcon
            data-testid="emLoadingIcon"
            className={loadingIconStyles.cardLoader}
          />
        )}
      </Card>
    </>
  );
};

export default MoveDwollaBalances;
