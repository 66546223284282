import React from 'react';

import * as styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer} data-testid="appFooter">
      &copy;{currentYear} Equity Multiple, Inc.
    </footer>
  );
};

export default Footer;
