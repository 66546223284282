import {
  namedOperations,
  useDeleteUserMutation,
  useGetUserQuery,
  useResetPasswordMutation,
} from '__generated__';
import { Breadcrumbs, Card } from '@equitymultiple/react-eui';
import { capitalize } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';

import { getUserStatus } from '../helpers';
import Documents from './Documents/Documents';
import InvestmentAccounts from './InvestmentAccounts/InvestmentAccounts';
import * as ovStyles from './overrides.module.scss';
import * as styles from './User.module.scss';
import UserActions from './UserActions/UserActions';

const deleteMessages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the user',
  success: 'User deleted',
};

const resetPasswordMessages = {
  loading: 'Sending the reset password instructions',
  error: 'An error occurred while sending the reset password instructions',
  success: 'Reset password instructions sent',
};

const User = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [resetPasswordClicked, setResetPasswordClicked] = useState(false);

  const { data, loading, error } = useGetUserQuery({
    variables: {
      userId: id,
    },
  });

  const [deleteUser, deleteUserState] = useDeleteUserMutation({
    variables: { userId: id },
    refetchQueries: [namedOperations.Query.getUsers],
  });

  const [resetPassword, resetPasswordState] = useResetPasswordMutation({
    variables: { userId: id },
  });

  const user = data?.user?.user;
  const fullName = user && `${user.firstName} ${user.lastName}`;
  const hasUserData = !loading && user;

  useEffect(() => {
    if (error) {
      toast.error('An error occurred while attempting to load the user');
      navigate('/users');
    }
  }, [error, navigate]);

  const handleDeleteUser = () => {
    callMutationWithToastMessages(deleteUser, deleteMessages).then(() => {
      setDeleteClicked(false);
      deleteUserState.reset();
      navigate('/users');
    });
  };

  const handleResetPassword = () => {
    callMutationWithToastMessages(resetPassword, resetPasswordMessages).then(
      () => {
        setResetPasswordClicked(false);
        resetPasswordState.reset();
      },
    );
  };

  return (
    <>
      {deleteClicked && (
        <ConfirmationModal
          handleCloseModal={() => setDeleteClicked(false)}
          onSubmit={() => handleDeleteUser()}
          title="Are you sure you want to delete this user?"
          content={
            <p className="textCenter">
              Deleting this user will also delete all associated investment
              accounts and documents.
            </p>
          }
          buttonText="Yes, Delete It"
          loading={deleteUserState.loading}
        />
      )}
      {resetPasswordClicked && (
        <ConfirmationModal
          handleCloseModal={() => setResetPasswordClicked(false)}
          onSubmit={() => handleResetPassword()}
          title="Are you sure you want to reset this user's password?"
          content={
            <p className="textCenter">
              This user will receive an email with the link to reset his/her
              password.
            </p>
          }
          buttonText="Reset"
          loading={resetPasswordState.loading}
        />
      )}
      <Breadcrumbs data-testid="breadcrumbs">
        <Link to="/users">Users</Link>
        {hasUserData && <span>{fullName}</span>}
      </Breadcrumbs>
      <Card className={ovStyles.userContainer} data-testid="userDetails">
        <div className={styles.top}>
          <div>
            <div className={styles.userName}>
              <h4>
                {hasUserData ? (
                  fullName
                ) : (
                  <Skeleton width={200} style={{ maxWidth: '100%' }} />
                )}
              </h4>
              {hasUserData && getUserStatus(user)}
            </div>

            {hasUserData ? (
              <span className="subText">ID {user.id}</span>
            ) : (
              <Skeleton />
            )}
          </div>
          <div className={styles.highlights}>
            <span className="textLabel">Email</span>
            {hasUserData ? (
              <span>{user.email}</span>
            ) : (
              <Skeleton width={180} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div className={styles.highlights}>
            <span className="textLabel">Stage</span>
            {hasUserData ? (
              <span>{capitalize(user.investorProfile?.stage)}</span>
            ) : (
              <Skeleton width={60} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div className={styles.highlights}>
            <span className="textLabel">Signed Up</span>
            {hasUserData ? (
              <span>
                {moment.utc(new Date(user.createdAt)).format('M/D/YYYY')}
              </span>
            ) : (
              <Skeleton width={80} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div className={styles.highlights}>
            <span className="textLabel">Lifetime Invested</span>
            {hasUserData ? (
              <span>{formatCurrency(user.lifetimeInvested)}</span>
            ) : (
              <Skeleton />
            )}
          </div>
          {hasUserData ? (
            <UserActions
              key={user.id}
              userId={user.id as string}
              deletable={user.deletable as boolean}
              suspended={user.suspended as boolean}
              setDeleteClicked={setDeleteClicked}
              setResetPasswordClicked={setResetPasswordClicked}
              twoFactorEnabled={user.twoFactorEnabled as boolean}
              locked={user.locked as boolean}
            />
          ) : (
            <Skeleton width={60} />
          )}
        </div>
      </Card>
      <InvestmentAccounts />
      <Documents />
    </>
  );
};
export default User;
