import {
  CreateTransactionInput,
  namedOperations,
  TransactionEventTypeCode,
  TransactionTypeCode,
  useCreateTransactionMutation,
  useGetInvestmentDetailsQuery,
} from '__generated__';
import {
  Button,
  Card,
  Checkbox,
  DateSelect,
  Input,
  Select,
  Tooltip,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  contributionTypeOptions,
  distributionTypeOptions,
  feeTypeOptions,
  fundAccountTypeOptions,
  withdrawAccountTypeOptions,
} from 'constants/transactions';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { enumToSelectOptions } from 'utils/enumToSelectOptions';
import formatCurrency from 'utils/formatCurrency';
import {
  setCheckboxFieldProps,
  setDateSelectFieldProps,
  setFieldProps,
} from 'utils/formHelpers';
import { numberMaskOptions } from 'utils/masks';

import { getPaymentMethodOptions } from '../../helpers';
import { transactionSchema } from './validation';

const messages = {
  loading: 'Creating transaction',
  error: 'An error occurred while creating the transaction',
  success: 'Transaction created',
};

const eventTypeOptions = enumToSelectOptions(TransactionEventTypeCode);
const paymentMethodOptions = getPaymentMethodOptions();

type CreateTransactionFields = Omit<CreateTransactionInput, 'amount'> & {
  amount: string;
};

const NewTransaction = () => {
  const [transactionTypeOptions, setTransactionTypeOptions] = useState(
    contributionTypeOptions,
  );

  const { offeringId, id } = useParams();
  const { data, loading } = useGetInvestmentDetailsQuery({
    variables: {
      offeringId: offeringId,
      investmentId: id,
    },
  });

  const investment = data?.investment?.investment;
  const dateInvested = investment?.closing?.closing.dateInvested;

  const navigate = useNavigate();
  const [createTransaction, createTransactionState] =
    useCreateTransactionMutation();

  const submitting = createTransactionState.loading;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CreateTransactionFields>({
    defaultValues: {
      eventType: TransactionEventTypeCode.Contribution,
      type: TransactionTypeCode.Investment,
      runTransaction: false,
      amount: '',
      paymentMethod: null,
      effectiveDate: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(transactionSchema),
  });

  const eventType = watch('eventType');
  const type = watch('type');
  const isRefund = type === TransactionTypeCode.Refund;

  useEffect(() => {
    switch (eventType) {
      case TransactionEventTypeCode.Contribution:
        setTransactionTypeOptions(contributionTypeOptions);
        break;
      case TransactionEventTypeCode.Distribution:
        setTransactionTypeOptions(distributionTypeOptions);
        break;
      case TransactionEventTypeCode.Fee:
        setTransactionTypeOptions(feeTypeOptions);
        break;
      case TransactionEventTypeCode.FundAccount:
        setTransactionTypeOptions(fundAccountTypeOptions);
        break;
      case TransactionEventTypeCode.WithdrawAccount:
        setTransactionTypeOptions(withdrawAccountTypeOptions);
        break;
    }
  }, [eventType]);

  useEffect(() => {
    if (isRefund) {
      setValue('effectiveDate', dateInvested);
      setValue('runTransaction', true);
    }
  }, [isRefund, dateInvested, setValue]);

  const onSubmit = (values) => {
    const submitValues = {
      ...values,
      amount: Number(values.amount),
      investmentId: id,
    };

    callMutationWithToastMessages(createTransaction, messages, {
      variables: {
        transaction: submitValues,
      },
      refetchQueries: [namedOperations.Query.getInvestmentDetails],
    }).then((res) => {
      if (res.data?.createTransaction?.message) {
        navigate(`/offerings/${offeringId}/investments/${id}/details`);
      }
    });
  };

  return (
    <>
      <h2 className="contentNarrow">
        {loading ? (
          <Skeleton width={700} style={{ maxWidth: '100%' }} />
        ) : (
          `Create Transaction for Investment ID: ${id} (${formatCurrency(
            investment.amount,
          )})`
        )}
      </h2>
      <Card className="contentNarrow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Amount"
                    allowDecimal
                    dollarMask
                    inputMaskOptions={numberMaskOptions}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                name="eventType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Event Type"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={eventTypeOptions}
                    data-testid="eventTypeSelect"
                  />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Transaction Type"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={transactionTypeOptions}
                    data-testid="transactionTypeSelect"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                name="paymentMethod"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Payment Method"
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    options={paymentMethodOptions}
                    data-testid="paymentMethodSelect"
                  />
                )}
              />
            </Col>
            <Col md={6} data-testid="effectiveDate">
              <Controller
                name="effectiveDate"
                control={control}
                render={({ field }) => (
                  <DateSelect
                    {...setDateSelectFieldProps(field, errors)}
                    label="Effective Date"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Controller
                name="runTransaction"
                control={control}
                render={({ field }) => (
                  <>
                    <Checkbox
                      {...setCheckboxFieldProps(field, errors)}
                      id="runTransaction"
                      label={
                        <>
                          <span>Run transaction after create</span>
                          {isRefund && (
                            <Tooltip
                              data-testid="runRefundTooltip"
                              tooltipContent="Refunds must be run at creation time."
                              className="inlineTooltip"
                              infoIcon
                            />
                          )}
                        </>
                      }
                      disabled={isRefund}
                    />
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className="marginTop30">
            <Col className="alignItemsCenter" xs={4}>
              <Link
                type="button"
                className="textLink"
                to={`/offerings/${offeringId}/investments/${id}/details`}
              >
                Cancel
              </Link>
            </Col>
            <Col xs={8}>
              <Button
                type="submit"
                disabled={submitting}
                className="floatRight"
              >
                Create Transaction
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default NewTransaction;
