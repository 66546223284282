import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DwollaCustomer,
  useGetDwollaCustomersQuery,
  useGetOfferingBankAccountQuery,
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import AttachBankAccount from './AttachBankAccount/AttachBankAccount';
import WireAndCheckForm from './WireAndCheckForm/WireAndCheckForm';

const OfferingBankAccount: React.FC = () => {
  const { id } = useParams();
  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useGetOfferingBankAccountQuery({
    variables: {
      offeringId: id as string,
    },
  });
  const {
    data: dwollaData,
    loading: dwollaLoading,
    error: dwollaError,
  } = useGetDwollaCustomersQuery();

  const navigate = useNavigate();

  const loaded = !offeringLoading && !dwollaLoading;
  const offering = offeringData?.offering?.offering;
  const dwollaCustomers = dwollaData?.dwollaCustomers?.data;
  const bankAccountInfo = {
    dwollaCustomerId: offering?.dwollaCustomerId || undefined,
    dwollaFundingSourceId: offering?.dwollaFundingSourceId || undefined,
  };

  useEffect(() => {
    if (offeringError || dwollaError || offeringData?.offering.error) {
      toast.error('An error occurred while loading the offering');
      navigate('/');
    }
  });

  return (
    <>
      <h2>
        {loaded
          ? `Bank Account Information for ${offering?.title}`
          : 'Bank Account Information'}
      </h2>
      <Card>
        {loaded ? (
          <>
            <WireAndCheckForm
              id={id as string}
              checkInfo={offering?.checkInfo}
              wireInfo={offering?.wireInfo}
            />
            <AttachBankAccount
              bankAccountInfo={bankAccountInfo}
              id={id as string}
              dwollaCustomers={dwollaCustomers as DwollaCustomer[]}
            />
          </>
        ) : (
          <EMLoadingIcon
            data-testid="emLoadingIcon"
            className={loadingIconStyles.cardLoader}
          />
        )}
      </Card>
    </>
  );
};

export default OfferingBankAccount;
