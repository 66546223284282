import { Button, Modal, Select } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';

import {
  OfferingTaxStatus,
  OfferingTaxStatusStep,
  useEditOfferingTaxStatusMutation,
} from '../../../__generated__';
import * as modalStyles from '../../../styles/components/Modal.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { stepOptions } from '../constants';

const editOfferingTaxStatusMessages = {
  loading: 'Updating tax status',
  error: 'An error occurred while attempting to updating the tax status',
  success: 'Tax status updated',
};

export interface FlagUnderReviewModalInput {
  newUnderReview: boolean;
  offeringTaxStatus: OfferingTaxStatus;
}

export interface Props {
  handleCloseModal: () => void;
  params?: FlagUnderReviewModalInput;
}

const EditOfferingTaxStatusModal: React.FC<Props> = ({
  params,
  handleCloseModal,
}) => {
  const [newStep, setNewStep] = useState(params?.offeringTaxStatus.step);
  const [editOfferingTaxStatusMutation, editOfferingTaxStatusMutationState] =
    useEditOfferingTaxStatusMutation();

  useEffect(() => {
    if (params?.offeringTaxStatus.step) {
      setNewStep(params.offeringTaxStatus.step);
    }
  }, [params?.offeringTaxStatus.step]);

  const handleEditOfferingTaxStatus = () => {
    callMutationWithToastMessages(
      editOfferingTaxStatusMutation,
      editOfferingTaxStatusMessages,
      {
        variables: {
          offeringTaxStatus: {
            id: params?.offeringTaxStatus.id as string,
            step: newStep || undefined,
            underReview: params?.newUnderReview,
          },
        },
      },
    ).then(() => {
      handleCloseModal();
      editOfferingTaxStatusMutationState.reset();
    });
  };

  return (
    <Modal
      data-testid="flagUnderReviewModal"
      open={!!params?.offeringTaxStatus}
      onClose={handleCloseModal}
      submitButton={
        <Button
          variant="orange"
          onClick={handleEditOfferingTaxStatus}
          loading={editOfferingTaxStatusMutationState.loading}
        >
          Confirm Change
        </Button>
      }
      closeButtonText="Cancel"
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem}>
          <h5>Selected tax status</h5>
          <p>
            <span data-testid="offeringTaxStatusOffering">
              <b>Offering Title: </b>
              {params?.offeringTaxStatus.offeringTitle}
            </span>
            <br />
            <span data-testid="offeringTaxStatusEntity">
              <b>Entity Name: </b>
              {params?.offeringTaxStatus.entityName}
            </span>
            <br />
            <span data-testid="offeringTaxStatusSponsor">
              <b>Sponsor Name: </b>
              {params?.offeringTaxStatus.sponsorName}
            </span>
            <br />
          </p>
          {params?.newUnderReview ? (
            <>
              <p className="textRed">
                Your are about to flag all documents in this offering as "Under
                Review".
                <br />
                You cannot undo this action.
                <br />
              </p>
              <p>Select which step you are reverting the process to.</p>
              <Select
                id={'select-under-review-step-' + params?.offeringTaxStatus.id}
                value={newStep}
                options={stepOptions.slice(
                  0,
                  stepOptions.findIndex(
                    ({ value }) => params?.offeringTaxStatus.step === value,
                  ) + 1,
                )}
                onChange={(value) => setNewStep(value as OfferingTaxStatusStep)}
              />
            </>
          ) : (
            <p>
              The Under Review flag will be removed from this offering's
              document
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditOfferingTaxStatusModal;
