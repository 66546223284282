import { Select, Table } from '@equitymultiple/react-eui';
import Option, {
  NonAsyncValue,
} from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import {
  Address,
  GetOfferingDocument,
  GetOfferingQuery,
  Offering,
  useCreateAddressMutation,
  useDeleteAddressMutation,
} from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import cloneObject from '../../../utils/cloneObject';

interface Props {
  offering: Offering;
}

const createMessages = {
  loading: 'Adding',
  error: 'An error occurred while adding the address',
  success: 'Address added',
};

const deleteMessages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the address',
  success: 'Address deleted',
};

const getAddressAttribute = (values: string[]): string | null => {
  if (values.includes('country')) {
    return 'country';
  } else if (
    values.includes('locality') ||
    values.includes('postal_town') ||
    values.includes('sublocality_level_1')
  ) {
    return 'city';
  } else if (values.includes('administrative_area_level_1')) {
    return 'state';
  } else if (values.includes('postal_code')) {
    return 'postalCode';
  }
  return null;
};

interface AddressInput {
  [key: string]: string;
}

const columnHeaders = ['Address', 'City', 'State', 'Postal Code', 'Actions'];

const OfferingAddresses: React.FC<Props> = ({ offering }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();
  const [addressSuggestions, setAddressSuggestions] = useState<Option[]>([]);

  const [createAddress, createAddressState] = useCreateAddressMutation();

  const [deleteAddress] = useDeleteAddressMutation();

  useEffect(() => {
    if (status === 'OK' && data.length > 0) {
      const selectOptions = data.map(({ description }) => ({
        value: description,
        label: description,
      }));
      setAddressSuggestions(selectOptions);
    }
  }, [status, data]);

  const handleInputChange = (val: string): void => {
    setValue(val);
  };

  const handleAddressSelect = (address: NonAsyncValue): void => {
    setValue(address as string, false);
    getGeocode({ address: address as string }).then((res) => {
      const addressInput: AddressInput = {};
      res[0].address_components.forEach((addressComponent) => {
        const key = getAddressAttribute(addressComponent.types);
        if (key) {
          addressInput[key] = addressComponent.long_name;
        }
      });
      const input = {
        address: address as string,
        city: addressInput.city,
        state: addressInput.state,
        country: addressInput.country,
        postalCode: addressInput.postalCode,
      };

      callMutationWithToastMessages(createAddress, createMessages, {
        variables: {
          offeringId: offering.id,
          address: input,
        },
        update: (cache, result) => {
          const offeringResult = cache.readQuery({
            query: GetOfferingDocument,
            variables: { offeringId: offering.id },
          }) as GetOfferingQuery;

          const newAddress = result.data?.createAddress.address;

          if (offeringResult && newAddress) {
            const newOfferingResult = cloneObject(offeringResult);

            if (newOfferingResult.offering.offering?.addresses) {
              const currentAddresses =
                offeringResult.offering?.offering?.addresses?.addresses;

              newOfferingResult.offering.offering.addresses = {
                addresses: [...(currentAddresses || []), newAddress],
              };

              cache.writeQuery({
                query: GetOfferingDocument,
                data: newOfferingResult,
              });
            }
          }
        },
      });
      setValue('', false);
      setAddressSuggestions([]);
    });
  };

  const handleDeleteAddress = (addressId: string) => {
    callMutationWithToastMessages(deleteAddress, deleteMessages, {
      variables: {
        offeringId: offering.id,
        addressId: addressId,
      },
      update(cache) {
        deleteObjectFromCache(cache, 'Address', addressId);
      },
    });
  };

  const getRows = () => {
    return offering?.addresses?.addresses &&
      offering.addresses.addresses.length > 0
      ? offering.addresses.addresses.map((offeringAddress) => {
          const { address, city, state, postalCode, id } =
            offeringAddress as Address;

          return {
            cells: [
              address,
              city,
              state,
              postalCode,
              <button
                key={id}
                onClick={() => handleDeleteAddress(id as string)}
                type="button"
                className="textLink"
              >
                Delete
              </button>,
            ],
          };
        })
      : [{ cells: ['No addresses found'] }];
  };

  return (
    <>
      <hr />
      <div className="margin30">
        <h3>Addresses</h3>
        <Row>
          <Col lg={8} md={12}>
            <Table columnHeaders={columnHeaders} rows={getRows()} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={4} md={6}>
            <Select
              id="new-address"
              onChange={handleAddressSelect}
              onInputChange={handleInputChange}
              options={addressSuggestions}
              label="Add new address"
              value={value}
              disabled={createAddressState.loading}
              helperText="Type to search for location"
              helperTextAlwaysVisible
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OfferingAddresses;
