import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/images/logo-minimal.svg?url';
import * as styles from './Header.module.scss';
import MainNavigation from './MainNavigation/MainNavigation';

const Header: React.FC = () => {
  return (
    <header className={styles.header} data-testid="appHeader">
      <Link to="/">
        <img className={styles.logo} src={Logo} alt="EquityMultiple Logo" />
      </Link>
      <MainNavigation />
    </header>
  );
};

export default Header;
