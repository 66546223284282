// Sorts an array of objects alphabetically by a given object property
export const sortArrayofObjectsByProperty = <
  T extends Record<K, string>[],
  K extends keyof any, // eslint-disable-line
>(
  arrayOfObjects: T,
  property: K,
): T => {
  return arrayOfObjects.sort((objA, objB) => {
    const propA = objA[property] ? objA[property].toUpperCase() : '';
    const propB = objB[property] ? objB[property].toUpperCase() : '';
    return propA < propB ? -1 : propA > propB ? 1 : 0;
  });
};
