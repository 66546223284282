import { Card, Tab, TabPanel, Tabs, TabsList } from '@equitymultiple/react-eui';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AdminAccounts from './AdminAccounts/AdminAccounts';
import InvestmentAccounts from './InvestmentAccounts/InvestmentAccounts';

const getTabIndexFromHash = (hash: string) => {
  switch (hash) {
    case '#investment_accounts':
      return 1;
    default:
      return 0;
  }
};

const Accounts: React.FC = () => {
  const { hash } = useLocation();

  return (
    <>
      <h1 data-testid="accountsHeading">Accounts</h1>
      <Card>
        <Tabs defaultValue={getTabIndexFromHash(hash)}>
          <TabsList>
            <Tab data-testid="tabAdmin">Admin Accounts</Tab>
            <Tab data-testid="tabInvestment">Investment Accounts</Tab>
          </TabsList>
          <TabPanel value={0}>
            <AdminAccounts />
          </TabPanel>
          <TabPanel value={1}>
            <InvestmentAccounts />
          </TabPanel>
        </Tabs>
      </Card>
    </>
  );
};

export default Accounts;
