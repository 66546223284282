import {
  DocumentType,
  InvestmentAccountDocumentInput,
  InvestmentAccountDocumentType,
  Scalars,
} from '../../../../../__generated__';

interface DocumentTypeOption {
  label: string;
  value: DocumentType;
}

export const idTypeOptions: DocumentTypeOption[] = [
  {
    label: 'Passport',
    value: DocumentType.Passport,
  },
  {
    label: 'License',
    value: DocumentType.License,
  },
  {
    label: 'ID Card',
    value: DocumentType.IdCard,
  },
  {
    label: 'Other',
    value: DocumentType.Other,
  },
];

export type IdFields = Omit<
  InvestmentAccountDocumentInput,
  'investmentAccountId'
>;

type EntityDoc = {
  attachment?: Scalars['Upload']['input'];
  documentType: InvestmentAccountDocumentType;
};

export type DocFieldKey =
  | 'identificationDocument'
  | 'secondaryIdentityDocument'
  | 'entityEvidenceDocument'
  | 'einEvidenceDocument';

export interface FormValues {
  einEvidenceDocument?: EntityDoc;
  entityEvidenceDocument?: EntityDoc;
  identificationDocument?: IdFields;
  investmentAccountId: string;
  secondaryIdentityDocument?: IdFields;
}
