import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { forwardRef, MutableRefObject } from 'react';

import * as inputStyles from './RichEditor.module.scss';

export type Props = {
  content?: string;
  'data-testid'?: string;
  errorMessage?: string;
  onChange: (change: string) => void;
};

const config = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'blockQuote',
    '|',
    'numberedList',
    'bulletedList',
    'outdent',
    'indent',
    '|',
    'link',
    'insertTable',
    'undo',
    'redo',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
    ],
  },
  link: {
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        defaultValue: true,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  typing: {
    transformations: {
      remove: ['oneHalf', 'oneThird', 'twoThirds'],
    },
  },
};

export const handleChange = (onChange: (change: string) => void) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (_: any, editor: { getData: () => any }): void => {
    const data = editor.getData();
    onChange(data);
  };
};

const RichEditor = forwardRef<any, Props>(
  (
    {
      onChange = () => null,
      content = '',
      'data-testid': dataTestId,
      errorMessage = '',
    },
    ref,
  ) => {
    let className = '';
    if (errorMessage) className += ` fieldWithErrors`;

    return (
      <div className={className} data-testid={dataTestId}>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={handleChange(onChange)}
          config={config}
          onReady={(editor) => {
            if (ref && ref.hasOwnProperty('current'))
              (ref as MutableRefObject<HTMLDivElement>).current = editor;
          }}
        />
        {errorMessage && (
          <div className={`${inputStyles.errorMessage}`}>{errorMessage}</div>
        )}
      </div>
    );
  },
);

RichEditor.displayName = 'RichEditor';

export default RichEditor;
