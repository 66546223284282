import {
  Button,
  Checkbox,
  FileUploader,
  RadioButton,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AttachmentLabel,
  DocumentSubtype,
  documentSubtypeOptions,
  DocumentTypeOptions,
  TaxYearOptions,
} from 'constants/documents';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import { DocumentType } from '../../../__generated__/index';
import { serverSideUploadLimit } from '../../../constants';
import { AssociateToOptions } from './constants';
import { documentSchema } from './validation';

// eslint-disable-next-line
type FormValues = Record<string, any>;

export interface Props {
  defaultValues?: DefaultValues<FormValues>;
  loading: boolean;
  onSubmit(submitData: FormValues): unknown;
}

const initialValues = {
  attachment: null,
  taxYear: '',
  documentType: '',
  amended: false,
  associate: null,
  documentReplace: false,
};

const DocumentForm: React.FC<Props> = ({
  onSubmit,
  loading,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(documentSchema),
    defaultValues: {
      ...initialValues,
      defaultValues,
    },
  });

  const watchType = watch('type');

  useEffect(() => {
    reset({
      ...initialValues,
      type: watchType,
    });
  }, [reset, watchType]);

  const handleTypeChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (evt.target?.checked) {
      setValue('type', value as DocumentType);
    }
  };

  const uploadDocument = async (data: FormValues) => {
    data.fileSize = data.attachment ? data.attachment.size : 0;
    const response = await onSubmit(data);

    if (response) {
      reset({
        ...initialValues,
        type: watchType,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(uploadDocument)} data-testid="documentForm">
        <Row className="margin20">
          <Col xl={4} lg={6} md={6}>
            <p>Document Type</p>
            {DocumentTypeOptions.map(({ label, value }) => {
              return (
                <Controller
                  key={`doc-form-acc-type-${value}`}
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <RadioButton
                      {...field}
                      id={`doc-form-acc-type-${value}`}
                      label={label}
                      checked={field.value == value}
                      onChange={(evt) => handleTypeChange(evt, value)}
                    />
                  )}
                />
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={8} md={8}>
            <p>{watchType && AttachmentLabel[watchType]}</p>
            <Controller
              name="attachment"
              control={control}
              render={({ field }) => (
                <FileUploader
                  {...field}
                  onRemove={() => field.onChange(null)}
                  upload={(uploadData: File) => field.onChange(uploadData)}
                  existingFile={field.value}
                  errorMessage={errors.attachment?.message as string}
                  acceptedFileTypes={['ZIP']}
                  reset={!field.value}
                  maxSize={serverSideUploadLimit}
                />
              )}
            />
          </Col>
        </Row>

        <Row className="margin20">
          {watchType === DocumentType.TaxDocument && (
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="taxYear"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id={`doc-form-tax-year`}
                    label="Tax Year"
                    options={TaxYearOptions}
                    errorMessage={errors.taxYear?.message as string}
                  />
                )}
              />
            </Col>
          )}

          {watchType === DocumentType.AccountDocument && (
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="associate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id={`doc-form-associate`}
                    label="Associate To"
                    options={AssociateToOptions}
                    errorMessage={errors.associate?.message as string}
                  />
                )}
              />
            </Col>
          )}

          <Col xl={4} lg={6} md={6}>
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id={`doc-form-document-type`}
                  label={
                    DocumentSubtype[watchType || DocumentType.TaxDocument] +
                    ' Type'
                  }
                  options={documentSubtypeOptions(watchType)}
                  errorMessage={errors.documentType?.message as string}
                />
              )}
            />
          </Col>
        </Row>

        <Row className="margin20">
          {watchType === DocumentType.TaxDocument && (
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="documentReplace"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id={`doc-form-document-replace`}
                    label="Replace the most recently uploaded document with the same filename, type and tax year"
                    checked={field.value}
                  />
                )}
              />
            </Col>
          )}

          <Col xl={4} lg={6} md={6}>
            <Controller
              name="amended"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id={`doc-form-amended`}
                  label="Amended"
                  checked={field.value}
                />
              )}
            />
          </Col>
        </Row>

        <Button type="submit" className="floatRight margin40" loading={loading}>
          Submit
        </Button>
      </form>
    </>
  );
};

export default DocumentForm;
