import React, { useEffect } from 'react';

interface Props {
  children: React.ReactChild;
  title?: string;
}

const Page: React.FC<Props> = ({ children, title = '' }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <>{children}</>;
};

export default Page;
