import React from 'react';

import { useDeleteBeneficialOwnerMutation } from '../../../../__generated__';
import { deleteObjectFromCache } from '../../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';

export interface MutationVariablesIds {
  beneficialOwnerId: string;
  dwollaCustomerId: string;
}

const toastMessages = {
  loading: 'Deleting beneficial owner',
  error: 'An error occurred while attempting to delete the beneficial owner',
  success: 'Beneficial owner deleted',
};

const BeneficialOwnersActions: React.FC<MutationVariablesIds> = ({
  dwollaCustomerId,
  beneficialOwnerId,
}) => {
  const [deleteBeneficialOwner] = useDeleteBeneficialOwnerMutation();

  const handleDeleteBeneficialOwner = () => {
    const mutationVariables = {
      dwollaCustomerId,
      beneficialOwnerId,
    };

    callMutationWithToastMessages(deleteBeneficialOwner, toastMessages, {
      variables: mutationVariables,
      update(cache) {
        deleteObjectFromCache(cache, 'BeneficialOwner', beneficialOwnerId);
      },
    });
  };

  return (
    <button
      data-testid={`beneficialOwnerDeleteButton-${beneficialOwnerId}`}
      onClick={handleDeleteBeneficialOwner}
      className="textLink"
      type="button"
    >
      Delete
    </button>
  );
};

export default BeneficialOwnersActions;
