import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { useDeactivateInvestmentAccountMutation } from '../../../__generated__';
import * as modalStyles from '../../../styles/components/Modal.module.scss';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

const toastMessages = {
  loading: 'Deactivating account',
  error: 'An error occurred while attempting to deactivate the account',
  success: 'Account deactivated',
};

export interface Props {
  email: string;
  handleCloseModal: () => void;
  id: string;
  onDeactivate?: () => void;
}

const DeactivateInvestmentAccountModal: React.FC<Props> = ({
  email,
  id,
  handleCloseModal,
  onDeactivate,
}) => {
  const [deactivateInvestmentAccount, deactivateInvestmentAccountState] =
    useDeactivateInvestmentAccountMutation({
      variables: {
        investmentAccountId: id,
      },
      update(cache) {
        deleteObjectFromCache(cache, 'InvestmentAccount', id);
      },
    });

  const { loading } = deactivateInvestmentAccountState;

  const handleDeactivateInvestmentAccount = () => {
    callMutationWithToastMessages(
      deactivateInvestmentAccount,
      toastMessages,
    ).then(() => {
      handleCloseModal();
      if (onDeactivate) onDeactivate();
    });
  };

  return (
    <Modal
      data-testid="deactivateInvestmentAccountModal"
      open
      title={
        <h6>Are you sure you want to deactivate this investment account?</h6>
      }
      closeButtonText="Cancel"
      submitButton={
        <Button
          variant="orange"
          onClick={handleDeactivateInvestmentAccount}
          loading={loading}
        >
          Yes, Deactivate It
        </Button>
      }
      onClose={handleCloseModal}
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem}>
          <p data-testid="deactivateModalAccountId">
            <strong>ID:</strong> {id}
          </p>
          <p data-testid="deactivateModalUserEmail">
            <strong>Email:</strong> {email}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateInvestmentAccountModal;
