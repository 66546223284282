import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useGetDocumentUrlQuery } from '../../__generated__';

const DocumentDownload: React.FC = () => {
  const id = useParams().id as string;

  const { data, error } = useGetDocumentUrlQuery({
    variables: { id },
  });

  const hasError = error || data?.documentUrl.error?.message;

  useEffect(() => {
    if (hasError) {
      toast.error('Error fetching document URL');
    }
  }, [hasError]);

  const downloadUrl = data?.documentUrl.url;

  useEffect(() => {
    if (downloadUrl) {
      window.location.replace(downloadUrl);

      // Fallback to close the tab after a delay if it's not loaded in tab but got downloaded
      setTimeout(() => {
        if (
          document.readyState === 'complete' &&
          window.location.href !== downloadUrl
        ) {
          window.close();
        }
      }, 5000);
    }
  }, [downloadUrl]);

  return (
    <>
      {hasError ? (
        <Card>
          <div className="textCenter">{hasError.toString()}</div>
        </Card>
      ) : (
        <EMLoadingIcon data-testid="emLoadingIcon" />
      )}
    </>
  );
};

export default DocumentDownload;
