import { Button, Card, Input, Table } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';

import { PostAuthor, useGetPostAuthorsQuery } from '../../../__generated__';
import DeletePostAuthorModal from './DeletePostAuthorModal';
import PostAuthorActions from './PostAuthorActions';
import * as styles from './postAuthors.module.scss';

const PostAuthors: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedPostAuthor, setSelectedPostAuthor] =
    useState<PostAuthor | null>(null);
  const { data, loading } = useGetPostAuthorsQuery();

  const hasPostAuthors = data?.postAuthors.data;

  const navigate = useNavigate();

  const columnHeaders = [
    'ID',
    'First Name',
    'Last Name',
    'Title',
    'Email',
    'Actions',
  ];

  const setSelectedPostAuthorState = (postAuthor: PostAuthor | null) => {
    setSelectedPostAuthor(postAuthor);
  };

  const handleAddPostAuthor = () => {
    navigate('/post_authors/new');
  };

  const getRows = (postAuthors: Array<PostAuthor>) => {
    const filteredPostAuthors = searchValue
      ? postAuthors.filter((postAuthor) => {
          const searchString =
            `${postAuthor.id} ${postAuthor.firstName} ${postAuthor.lastName} ${postAuthor.email}`.toLowerCase();
          return searchString?.includes(searchValue.toLowerCase());
        })
      : postAuthors;

    return filteredPostAuthors.map((postAuthor) => {
      const { firstName, lastName, title, email, id } = postAuthor;

      return {
        cells: [
          id,
          firstName,
          lastName,
          title,
          email,
          <PostAuthorActions
            key={id}
            setSelectedPostAuthor={setSelectedPostAuthorState}
            postAuthor={postAuthor as PostAuthor}
          />,
        ],
      };
    });
  };

  return (
    <>
      <DeletePostAuthorModal
        setSelectedPostAuthorState={setSelectedPostAuthorState}
        selectedPostAuthor={selectedPostAuthor}
      />
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="postAuthorSearch"
              label="Search"
              placeholder="Author ID, Name or Email"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </Col>
          <Col lg={8} md={6}>
            <Button
              data-testid="newPostAuthorButton"
              className={`floatRight margin20 ${styles.addPostAuthorButton}`}
              onClick={handleAddPostAuthor}
            >
              Add Author
            </Button>
          </Col>
        </Row>
        <Table
          loading={loading}
          loadingRows={10}
          columnHeaders={columnHeaders}
          rows={
            hasPostAuthors
              ? getRows(data?.postAuthors.data)
              : [{ cells: ['No post authors found'] }]
          }
        />
      </Card>
    </>
  );
};

export default PostAuthors;
