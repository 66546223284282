import { DateSelect, FileUploader, Select } from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import {
  setDateSelectFieldProps,
  setFieldProps,
} from '../../../../../utils/formHelpers';
import { FormValues, idTypeOptions } from './constants';

interface Props {
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  isSecondary?: boolean;
}

const IdFields: React.FC<Props> = ({ control, errors, isSecondary }) => {
  const fieldPrefix = isSecondary
    ? 'secondaryIdentityDocument'
    : 'identificationDocument';

  return (
    <>
      <h5>Identification Document</h5>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name={`${fieldPrefix}.idType`}
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={idTypeOptions}
                onChange={(e) => field.onChange(e)}
                label="ID Type"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name={`${fieldPrefix}.expiry`}
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="ID Expiration Date"
                anyDate
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={8} id={`${fieldPrefix}File`}>
          <Controller
            name={`${fieldPrefix}.attachment`}
            control={control}
            render={({ field }) => (
              <FileUploader
                {...field}
                onRemove={() => field.onChange(null)}
                upload={(uploadData: File) => field.onChange(uploadData)}
                showImage
                showLink
                existingFile={field.value}
                errorMessage={
                  errors?.[fieldPrefix]?.attachment?.message as string
                }
                acceptedFileTypes={['JPG', 'PNG']}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default IdFields;
