import { ApolloCache, NormalizedCacheObject } from '@apollo/client';

// Deletes an object from the cache
export const deleteObjectFromCache = (
  cache: ApolloCache<NormalizedCacheObject>,
  typename: string,
  id: string,
): void => {
  // This is only needed if using custom cache IDs. We currently don't, adding it in case we choose to down the line
  const normalizedId = cache.identify({ id, __typename: typename });

  // Remove the object from the cache
  // Without this timeout there's some weird behavior where mutation state is not updating.
  // There's never a 'data' response and the loader will be shown in perpetuity
  setTimeout(() => cache.evict({ id: normalizedId }), 0);

  // Perform garbage collection
  cache.gc();
};
