import { InMemoryCacheConfig } from '@apollo/client/cache';

type MergeParam = never | { data: [] };

/**
 * Field policy for fetching posts. Used to handle pagination, so that
 * subsequent results are merged with existing results from the cache.
 * Values in the `keyArgs` array are ignored - meaning the merge logic
 * will not be run on those query parameters.
 */
const postsPaginationFieldPolicy = {
  posts: {
    keyArgs: ['status', 'labels', 'offeringIds'],
    merge(existing: MergeParam, incoming: MergeParam): { data: [] } {
      if (!incoming) return existing;
      if (!existing) return incoming;

      const { data, ...rest } = incoming;
      const result = { data, ...rest };
      result.data = [...existing.data, ...data];

      return result;
    },
  },
};

const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        ...postsPaginationFieldPolicy,
      },
    },
  },
};

export default apolloCacheConfig;
