import { Button, Card, Table } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  TransactionType,
  useGetPaymentPeriodsQuery,
} from '../../../__generated__';
import { enumValueToLabel } from '../../../utils/stringFormatting';
import DeletePeriodModal from '../DeletePeriodModal/DeletePeriodModal';
import PaymentPeriodActions from '../PaymentPeriodActions/PaymentPeriodActions';
import * as styles from './PaymentPeriods.module.scss';

const columnHeaders = [
  'Period',
  'Effective Period',
  'Transaction Type',
  'Status',
  'Allocated To',
  'Amount',
  'Actions',
];

type DeletePeriodState = {
  periodData: null | {
    offeringId: string;
    periodId: string;
  };
};

const PaymentPeriods: React.FC = () => {
  const offeringId = useParams().offeringId as string;
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletePeriodState, setDeletePeriodState] = useState<DeletePeriodState>(
    {
      periodData: null,
    },
  );

  const { data, error, loading, fetchMore } = useGetPaymentPeriodsQuery({
    variables: {
      offeringId,
      pagination: {
        page: pageNumber,
        pageSize: rowsPerPage,
      },
    },
  });

  const hasError = error || data?.paymentPeriods.error || data?.offering.error;

  const offering = data?.offering.offering;
  const paymentPeriods = data?.paymentPeriods.data;
  const uniquePeriodTotal = data?.paymentPeriods.uniquePeriodTotal ?? 0;
  const hasData = offering && paymentPeriods && uniquePeriodTotal > 0;
  const paymentPeriodsCount = data?.paymentPeriods.pageInfo?.count ?? 0;

  const handleDeletePeriod = (periodOfferingId: string, periodId: string) => {
    setDeletePeriodState({
      periodData: { offeringId: periodOfferingId, periodId: periodId },
    });
  };

  const handleCloseDeleteModal = () => {
    setDeletePeriodState({ periodData: null });
  };

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading payment periods');
    }
  }, [hasError]);

  const handleAddPeriod = () =>
    navigate(`/payments/schedule/${offeringId}/periods/new`);

  const getRows = () => {
    if (hasData) {
      const rootPeriods: string[] = [];
      const rows = paymentPeriods.map((paymentPeriod) => {
        let canEdit = true;
        if (rootPeriods.includes(paymentPeriod.id)) {
          canEdit = false;
        } else {
          rootPeriods.push(paymentPeriod.id);
        }

        const investorsCount = paymentPeriod.allocation
          ? paymentPeriod.allocation
          : 0;

        return {
          cells: [
            paymentPeriod.periodId,
            paymentPeriod.effectivePeriod,
            enumValueToLabel(TransactionType, paymentPeriod.transactionType),
            paymentPeriod.status,
            `${investorsCount} Investors`,
            paymentPeriod.amount || paymentPeriod.value,
            canEdit ? (
              <PaymentPeriodActions
                offeringId={offeringId}
                periodId={paymentPeriod.id}
                handleDeletePeriod={handleDeletePeriod}
              />
            ) : (
              ''
            ),
          ],
        };
      });
      return rows;
    } else {
      return [{ cells: ['No payment periods found'] }];
    }
  };

  const fetchMorePaymentPeriods = (page: number, pageSize: number) => {
    fetchMore({
      variables: {
        offeringId,
        pagination: {
          page,
          pageSize,
        },
      },
    });
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const newRowsPerPage = parseInt(event.currentTarget.value);
    if (rowsPerPage !== newRowsPerPage) {
      setRowsPerPage(() => {
        if (pageNumber !== 1) setPageNumber(1);
        fetchMorePaymentPeriods(1, newRowsPerPage);
        return newRowsPerPage;
      });
    }
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const newPageNumber = newPage + 1;
    if (newPageNumber !== pageNumber) {
      setPageNumber(newPageNumber);
      fetchMorePaymentPeriods(newPageNumber, rowsPerPage);
    }
  };

  return (
    <>
      <DeletePeriodModal
        periodData={deletePeriodState.periodData}
        handleCloseModal={handleCloseDeleteModal}
      />
      <h1 data-testid="paymentPeriodsHeading">
        Schedule{offering && ` - ${offering.title}`}
      </h1>
      <Card>
        <h3>Transactions</h3>
        <Row>
          <Col lg={4} md={6}>
            <h5 data-testid="totalPeriodsHeading">{`Showing ${uniquePeriodTotal} period${
              uniquePeriodTotal !== 1 ? 's' : ''
            }`}</h5>
          </Col>
          <Col lg={8} md={6}>
            <Button
              className={`floatRight margin20 ${styles.newPeriodButton}`}
              onClick={handleAddPeriod}
            >
              New Period
            </Button>
          </Col>
        </Row>
        <Table
          data-testid="table"
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={10}
          rows={getRows()}
          pagination={{
            page: hasData && !hasError ? pageNumber - 1 : 0,
            rowsPerPage,
            onRowsPerPageChange: handleRowsPerPageChange,
            onPageChange: handlePageChange,
            count: hasError ? 0 : paymentPeriodsCount,
          }}
        />
      </Card>
    </>
  );
};

export default PaymentPeriods;
