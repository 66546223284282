import * as yup from 'yup';

import { TransactionUploadInput } from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';

export const transactionUploadSchema = yup.object<
  SchemaShape<TransactionUploadInput>
>({
  attachment: yup.mixed().required('Excel file is required'),
});
