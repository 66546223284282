import { Button, Input, TextArea } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import {
  CheckInfoInput,
  useEditOfferingBankAccountMutation,
  WireInfoInput,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { wireAndCheckInfoSchema } from '../validation';

interface FormValues extends WireInfoInput, CheckInfoInput {}

interface Props {
  checkInfo?: CheckInfoInput | null;
  id: string;
  wireInfo?: WireInfoInput | null;
}

const messages = {
  loading: 'Updating',
  error: 'An error occurred while updating wire and check information',
  success: 'Wire and check information updated',
};

const WireAndCheckForm: React.FC<Props> = ({ checkInfo, id, wireInfo }) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(wireAndCheckInfoSchema),
    defaultValues: {
      payeeName: checkInfo.payeeName || '',
      address: checkInfo.address || '',
      accountName: wireInfo.accountName || '',
      account: wireInfo.account || '',
      bankAddress: wireInfo.bankAddress || '',
      bankName: wireInfo.bankName || '',
      wireRouting: wireInfo.wireRouting || '',
    },
  });
  const [editOfferingBankAccount, editOfferingBankAccountState] =
    useEditOfferingBankAccountMutation();
  const { loading } = editOfferingBankAccountState;

  const copyWireInfo = () => {
    const values = getValues();
    const wireText = `Account Name: ${values.accountName}
Account Number: ${values.account}
Routing Number: ${values.wireRouting}
Bank Name: ${values.bankName}
Branch: ${values.bankAddress}`;

    navigator.clipboard.writeText(wireText);

    toast.success('Wire info copied to clipboard');
  };

  const onSubmit = (values: FormValues) => {
    const {
      address,
      payeeName,
      account,
      accountName,
      bankAddress,
      bankName,
      wireRouting,
    } = values;

    callMutationWithToastMessages(editOfferingBankAccount, messages, {
      variables: {
        offering: {
          id,
          checkInfo: {
            payeeName,
            address,
          },
          wireInfo: {
            accountName,
            account: account?.toString(),
            bankAddress,
            bankName,
            wireRouting: wireRouting?.toString(),
          },
        },
      },
    });
  };
  return (
    <form
      className="clearFix"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="wireCheckForm"
    >
      <h3>Wire Info</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="bankName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Bank Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="accountName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Account Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="account"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Account Number" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="wireRouting"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Routing Number" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="bankAddress"
            control={control}
            render={({ field }) => (
              <TextArea
                {...setFieldProps(field, errors)}
                label="Bank Address"
              />
            )}
          />
        </Col>
      </Row>
      <button type="button" className="textLink" onClick={copyWireInfo}>
        <i className="fa fa-copy" /> Copy wire info
      </button>

      <h3>Check Info</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="payeeName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Payee Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <TextArea {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
      </Row>

      <Button type="submit" className="floatRight" loading={loading}>
        Save Wire & Check Info
      </Button>
    </form>
  );
};

export default WireAndCheckForm;
