import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import {
  namedOperations,
  PostAuthor,
  useDeletePostAuthorMutation,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedPostAuthor: PostAuthor | null;
  setSelectedPostAuthorState: (PostAuthor: PostAuthor | null) => void;
}

const messages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the post author',
  success: 'Post author deleted',
};

const DeletePostAuthorModal: React.FC<Props> = ({
  setSelectedPostAuthorState,
  selectedPostAuthor,
}) => {
  const postAuthorId = selectedPostAuthor?.id as string;
  const [deletePostAuthorMutation, deletePostAuthorMutationState] =
    useDeletePostAuthorMutation({
      variables: {
        postAuthorId,
      },
      refetchQueries: [namedOperations.Query.getPostAuthors],
    });

  const handleDeletePostAuthor = () => {
    callMutationWithToastMessages(deletePostAuthorMutation, messages).then(
      () => {
        setSelectedPostAuthorState(null);
        deletePostAuthorMutationState.reset();
      },
    );
  };

  const hideDeleteModal = () => setSelectedPostAuthorState(null);

  return (
    <Modal
      data-testid="deletePostAuthorModal"
      open={selectedPostAuthor !== null}
      title={<h6>Are you sure you want to delete this post author?</h6>}
      closeButtonText="Cancel"
      submitButton={
        <Button variant="orange" onClick={() => handleDeletePostAuthor()}>
          Yes, Delete It
        </Button>
      }
      onClose={hideDeleteModal}
    >
      <div className="margin30">
        <div data-testid="deleteModalPostAuthorName">
          <strong>Name:</strong>{' '}
          {`${selectedPostAuthor?.firstName} ${selectedPostAuthor?.lastName}`}
        </div>
        <div data-testid="deleteModalPostAuthorEmail">
          <strong>Email:</strong> {selectedPostAuthor?.email}
        </div>
      </div>
    </Modal>
  );
};

export default DeletePostAuthorModal;
