const offeringTaxStatusDocumentType = {
  div_1099: '1099-DIV',
  grantor_trust_letter: 'Grantor Trust Letter',
  int_1099: '1099-INT',
  k_1: 'K-1',
  k_1_estimate: 'K-1 Estimate',
  misc_1099: '1099-MISC',
  state_k_1: 'State K-1',
  withholding_form: 'Withholding Form',
  n1099: '1099',
};

const offeringTaxStatusStatus = {
  available: 'Available',
  delayed: 'Delayed',
  processing: 'Processing',
};

const offeringTaxStatusStep = {
  initial_data_preparation: '1. Initial Data Preparation',
  tax_document_requested: '2. Tax Documents Requested',
  tax_document_received_and_under_review:
    '3. Tax Documents Received and Under Review',
  preparing_investor_tax_document: '4. Preparing Investor Tax Documents',
  tax_document_available: '5. Tax Documents Available',
};

export type OfferingTaxStatusStepKey = keyof typeof offeringTaxStatusStep;

export type OfferingTaxStatusEstimateStatusKey =
  keyof typeof offeringTaxStatusEstimateStatus;

const offeringTaxStatusEstimateStatus = {
  estimate_anticipated: 'Estimate Anticipated',
  no_estimate_anticipated: 'No Estimate Anticipated',
  estimate_uploaded: 'Estimate Uploaded',
};

const stepOptions = [
  {
    label: offeringTaxStatusStep.initial_data_preparation,
    value: 'initial_data_preparation',
  },
  {
    label: offeringTaxStatusStep.tax_document_requested,
    value: 'tax_document_requested',
  },
  {
    label: offeringTaxStatusStep.tax_document_received_and_under_review,
    value: 'tax_document_received_and_under_review',
  },
  {
    label: offeringTaxStatusStep.preparing_investor_tax_document,
    value: 'preparing_investor_tax_document',
  },
  {
    label: offeringTaxStatusStep.tax_document_available,
    value: 'tax_document_available',
  },
];

const estimateOptions = [
  {
    label: 'None',
    value: '',
  },
  {
    label: offeringTaxStatusEstimateStatus.estimate_anticipated,
    value: 'estimate_anticipated',
  },
  {
    label: offeringTaxStatusEstimateStatus.no_estimate_anticipated,
    value: 'no_estimate_anticipated',
  },
  {
    label: offeringTaxStatusEstimateStatus.estimate_uploaded,
    value: 'estimate_uploaded',
  },
];
export {
  estimateOptions,
  offeringTaxStatusDocumentType,
  offeringTaxStatusEstimateStatus,
  offeringTaxStatusStatus,
  offeringTaxStatusStep,
  stepOptions,
};
