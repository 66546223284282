import {
  BankAccountType,
  BusinessType,
  DocumentType,
  InvestmentAccountType,
} from '../../__generated__';
import states from '../../constants/states';

export const investmentAccountTypeLabels: Record<
  InvestmentAccountType,
  string
> = {
  entity: 'Entity',
  individual: 'Individual',
  ira: 'IRA',
  joint_account: 'Joint Account',
  trust: 'Trust',
};

export const businessTypeLabels: Record<BusinessType, string> = {
  corporation: 'Corporation',
  individual: 'Individual',
  joint_account: 'Joint Account',
  llc: 'LLC',
  other: 'Other',
  partnership: 'Partnership',
  roth: 'Roth',
  roth_ira: 'Roth IRA',
  sep: 'SEP',
  sep_ira: 'SEP IRA',
  simple: 'SIMPLE',
  soleproprietorship: 'Sole Proprietorship',
  traditional: 'Traditional',
  traditional_ira: 'Traditional IRA',
  trust_irrevocable: 'Trust Irrevocable',
  trust_revocable: 'Trust Revocable',
  single_member_llc: 'Single Member LLC',
};

interface BankAccountTypeOption {
  label: string;
  value: BankAccountType;
}

export const bankAccountTypeOptions: BankAccountTypeOption[] = [
  {
    label: 'Checking',
    value: BankAccountType.Checking,
  },
  {
    label: 'Savings',
    value: BankAccountType.Savings,
  },
];

export const stateOptions = states.map((state) => ({
  label: state.code,
  value: state.code,
}));

interface DocumentTypeOption {
  label: string;
  value: DocumentType;
}

export const documentIdTypes: DocumentTypeOption[] = [
  {
    label: 'Passport',
    value: DocumentType.Passport,
  },
  {
    label: 'License',
    value: DocumentType.License,
  },
  {
    label: 'ID Card',
    value: DocumentType.IdCard,
  },
  {
    label: 'Other',
    value: DocumentType.Other,
  },
];

export interface BusinessTypeOption {
  label: string;
  value: BusinessType;
}

export const iraBusinessTypeOptions: BusinessTypeOption[] = [
  {
    label: 'Traditional',
    value: BusinessType.Traditional,
  },
  {
    label: 'Roth',
    value: BusinessType.Roth,
  },
  {
    label: 'SEP',
    value: BusinessType.Sep,
  },
  {
    label: 'SIMPLE',
    value: BusinessType.Simple,
  },
  {
    label: 'Other',
    value: BusinessType.Other,
  },
];

export const iraCustodians = [
  'Alto IRA',
  'Equity Trust Company',
  'IRA Services Trust Company',
  'Millennium Trust Company',
  'Rocket Dollar',
  'STRATA Trust Company',
  'Other',
];
