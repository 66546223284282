import { useDeleteDocumentsMutation, UserDocument } from '__generated__';
import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';
import { deleteObjectFromCache } from 'utils/apolloCacheHelpers';
import { snakeToTitleCase } from 'utils/stringFormatting';

import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedDocument: UserDocument | null;
  setSelectedDocumentState: (document: UserDocument | null) => void;
}

const messages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the document',
  success: 'Document deleted',
};

const DeleteDocumentModal: React.FC<Props> = ({
  setSelectedDocumentState,
  selectedDocument,
}) => {
  const documentId = selectedDocument?.id as string;
  const [deleteDocumentsMutation, deleteDocumentsMutationState] =
    useDeleteDocumentsMutation({
      variables: {
        documentId,
      },
      update(cache) {
        deleteObjectFromCache(cache, 'UserDocument', documentId);
      },
    });

  const handleDeleteDocument = () => {
    callMutationWithToastMessages(deleteDocumentsMutation, messages).then(
      () => {
        setSelectedDocumentState(null);
        deleteDocumentsMutationState.reset();
      },
    );
  };

  const hideDeleteModal = () => setSelectedDocumentState(null);

  return (
    <Modal
      data-testid="deleteDocumentModal"
      open={selectedDocument !== null}
      title={<h6>Are you sure you want to delete this document?</h6>}
      closeButtonText="Cancel"
      submitButton={
        <Button variant="orange" onClick={() => handleDeleteDocument()}>
          Yes, Delete It
        </Button>
      }
      onClose={hideDeleteModal}
    >
      <div className="margin30">
        <div data-testid="deleteModalDocumentName">
          <strong>Name:</strong> {selectedDocument?.filename || '-'}
        </div>
        <div data-testid="deleteModalDocumentType">
          <strong>Type:</strong>{' '}
          {selectedDocument?.type && snakeToTitleCase(selectedDocument.type)}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;
