import {
  EditAdminAccountDefaultInput,
  GetAdminAccountDefaultQuery,
  useEditAdminAccountDefaultMutation,
} from '__generated__';
import { Button, DateSelect, Input, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import dwollaBusinessClassifications from 'constants/dwollaBusinessClassifications';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { setDateSelectFieldProps, setFieldProps } from 'utils/formHelpers';
import { ssn, ssnMaskOptions } from 'utils/masks';
import { sortArrayofObjectsByProperty } from 'utils/sorting';

import {
  businessClassOptions,
  entityBusinessTypeOptions,
  stateOptions,
} from '../constants';
import { adminAccountDefaultSchema } from './validation';

const messages = {
  loading: 'Saving',
  error: 'An error occurred while updating the account default values',
  success: 'Account default values updated',
};

interface IndustryOption {
  label: string;
  value: string;
}

export const getIndustryClass = (
  businessClassValue: string | null | undefined,
): IndustryOption[] => {
  if (businessClassValue) {
    const businessClasses =
      dwollaBusinessClassifications._embedded['business-classifications'];
    const industries = businessClasses.find(
      (businessClass) => businessClass.id === businessClassValue,
    )?._embedded['industry-classifications'];

    if (industries) {
      sortArrayofObjectsByProperty(industries, 'name');

      return industries.map((industry) => ({
        label: industry.name,
        value: industry.id,
      }));
    }

    return [];
  }
  return [];
};

type Props = { data?: GetAdminAccountDefaultQuery };

export const DwollaConfigurationForm: React.FC<Props> = ({ data }) => {
  const [editAdminAccountDefault, editAdminAccountDefaultState] =
    useEditAdminAccountDefaultMutation();

  const { __typename: typeName, ...adminAccountDefault } =
    data?.adminAccountDefault || {};

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditAdminAccountDefaultInput>({
    mode: 'onBlur',
    resolver: yupResolver(adminAccountDefaultSchema),
    defaultValues: adminAccountDefault,
  });

  const onSubmit = (values: EditAdminAccountDefaultInput) => {
    callMutationWithToastMessages(editAdminAccountDefault, messages, {
      variables: {
        adminAccountDefault: { ...values },
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="dwollaConfigurationForm"
    >
      <Row>
        <Col md={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            name="address1"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (optional)"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                label="State"
                options={stateOptions}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="9{5}"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            name="ssn"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="SSN"
                mask={ssn}
                inputMaskOptions={ssnMaskOptions}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Phone Number"
                mask="(999) 999-9999[ ext 999999]"
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <Controller
            name="businessClassification"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={businessClassOptions}
                onChange={(e) => {
                  field.onChange(e);
                  setValue('industryClassification', '');
                }}
                label="Business"
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            name="industryClassification"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={getIndustryClass(getValues().businessClassification)}
                onChange={(e) => {
                  field.onChange(e);
                }}
                label="Industry"
                helperText={
                  getValues().businessClassification
                    ? ''
                    : 'Select a business to view options'
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            name="businessType"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={entityBusinessTypeOptions}
                label="Business Type"
              />
            )}
          />
        </Col>
      </Row>
      <Button
        type="submit"
        className="floatRight"
        loading={editAdminAccountDefaultState.loading}
      >
        Save
      </Button>
    </form>
  );
};
