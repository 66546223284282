import {
  Button,
  Card,
  Input,
  RadioButton,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from '@mui/material';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { setFieldProps } from 'utils/formHelpers';
import {
  iraBusinessTypeOptions,
  iraCustodians,
} from 'views/Accounts/constants';

import {
  useCreateInvestmentAccountMutation,
  useGetUsersLazyQuery,
  UserStage,
} from '../../../../__generated__';
import { iraInvestmentAccountSchema } from './validation';

const messages = {
  loading: 'Creating investment acccount',
  error: 'An error occurred while creating the investment account',
  success: 'Investment account created',
};

const defaultValues = {
  businessType: '',
  customEntityName: 'Other',
  entityName: '',
  iraAccountFboName: '',
  iraAccountNumber: '',
  iraRoutingNumber: '',
  type: 'ira',
  user: null,
};

const CreateInvestmentAccount = () => {
  const navigate = useNavigate();
  const [createInvestmentAccount, createInvestmentAccountState] =
    useCreateInvestmentAccountMutation();

  const getUsersVariables = {
    confirmed: true,
    pagination: {
      page: 1,
      pageSize: 50,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(iraInvestmentAccountSchema),
    defaultValues,
  });
  const entityName = watch('entityName');

  const [getUsers] = useGetUsersLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const loadUserOptions = (searchValue, callback) => {
    getUsers({
      variables: {
        ...getUsersVariables,
        filter: searchValue,
      },
      notifyOnNetworkStatusChange: true,
    }).then((res) => {
      const userOptions = res.data.users.data
        .filter(
          (userRes) => userRes.investorProfile.stage !== UserStage.Activate,
        )
        .map((userRes) => ({
          label: `${userRes.firstName} ${userRes.lastName} - (${userRes.email}, ID: ${userRes.id})`,
          value: userRes.id,
        }));

      callback(userOptions);
    });
  };

  const onSubmit = (values) => {
    const submitValues = { ...values };
    const userId = values.user.value;
    submitValues.entityName =
      submitValues.entityName === 'Other'
        ? submitValues.customEntityName
        : submitValues.entityName;
    delete submitValues.user;
    delete submitValues.customEntityName;

    callMutationWithToastMessages(createInvestmentAccount, messages, {
      variables: {
        investmentAccount: submitValues,
        userId,
      },
    }).then((res) => {
      if (res.data.createInvestmentAccount.investmentAccount?.id) {
        navigate(`/accounts/#investment_accounts`);
      }
    });
  };

  const userOptions = debounce(loadUserOptions, 300);

  const submitting = createInvestmentAccountState.loading;

  return (
    <>
      <h2 className="contentNarrow">Create Investment Account</h2>
      <Card className="contentNarrow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="margin20">
            <Col md={8}>
              <p>Investment account type</p>
              <Controller
                name="type"
                control={control}
                render={() => (
                  <RadioButton
                    id="typeSelect"
                    name="type"
                    label="IRA"
                    disabled
                    checked={true}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="margin20">
            <Col md={8}>
              <Controller
                name="user"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="User"
                    async
                    defaultOptions
                    loadOptions={userOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    placeholder="Search by email, ID, first name or last name"
                    data-testid="userSelect"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Controller
                name="entityName"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={iraCustodians.map((custodian) => ({
                      label: custodian,
                      value: custodian,
                    }))}
                    onChange={(e) => {
                      resetField('customEntityName', { keepError: false });
                      field.onChange(e);
                    }}
                    label="Custodian"
                    data-testid="entityNameSelect"
                  />
                )}
              />
            </Col>
          </Row>
          <p>
            Select "Other" from the select menu to enter a custom Custodian for
            this account
          </p>
          {entityName === 'Other' && (
            <Row>
              <Col md={8}>
                <Controller
                  name="customEntityName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      label="Custom Custodian"
                      data-testid="customEntityNameInput"
                    />
                  )}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={8}>
              <Controller
                name="iraAccountFboName"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Account / FBO Name"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Controller
                name="iraAccountNumber"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Custodian Account Number"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Controller
                name="iraRoutingNumber"
                control={control}
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    label="Custodian Routing Number (Optional)"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Controller
                name="businessType"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={iraBusinessTypeOptions}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    label="IRA Account Type"
                  />
                )}
              />
            </Col>
          </Row>
          <Button className="floatRight" type="submit" loading={submitting}>
            Submit
          </Button>
        </form>
      </Card>
    </>
  );
};

export default CreateInvestmentAccount;
