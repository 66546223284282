import * as yup from 'yup';

import {
  BusinessType,
  EditInvestmentAccountInput,
} from '../../../../__generated__';
import states from '../../../../constants/states';
import { SchemaShape } from '../../../../types/yup';
import {
  ageOver18,
  date,
  ein,
  email,
  pastDate,
  phone,
  ssn,
  yupBool,
  yupString,
} from '../../../../utils/validations';
import { businessClassesAlphabetized, jointSubTypes } from './constants';

const stateCodes = states.map((state) => state.code);
const businessClasses = businessClassesAlphabetized.map(
  (businessClass) => businessClass.name,
);

const industryClasses: string[] = [];
businessClassesAlphabetized.forEach((businessClass) => {
  const industries = businessClass._embedded['industry-classifications'];
  industries.forEach((industry) => industryClasses.push(industry.name));
});

// Validations shared between multiple schemas
const dob = date.required('Date of birth is required').test(ageOver18);
const city = yupString.required('City is required');
const address = yupString.required('Address is required');
const state = yupString
  .oneOf(stateCodes, 'State is required and must be a valid state')
  .required('State is required');
const postalCode = yupString.required('Postal code is required');
const firstName = yupString.required('First name is required');
const lastName = yupString.required('Last name is required');
const businessType = yupString
  .oneOf(
    Object.values(BusinessType),
    'Business type is required and must be a valid value',
  )
  .required('Business type is required');
const entityName = yupString.required('Entity name is required');

export const individualAccountFormSchema = yup.object<
  SchemaShape<EditInvestmentAccountInput>
>({
  user: yup.object().shape({
    dob,
    ssn,
    firstName,
    lastName,
    phone,
    address,
    address2: yupString,
    city,
    state,
    postalCode,
  }),
});

export const jointAccountFormSchema = yup.object<
  SchemaShape<EditInvestmentAccountInput>
>({
  subType: yupString
    .oneOf(
      jointSubTypes,
      'Type of joint account is required and must be a valid type',
    )
    .required('Type of joint account is required'),
  user: yup.object().shape({
    phone,
    dob,
    address,
    address2: yupString,
    city,
    state,
    postalCode,
  }),
  accountHolder2: yup.object().shape({
    firstName,
    lastName,
    dob,
    email,
    addressIsSame: yupBool,
    address: yupString.notRequired().when('addressIsSame', {
      is: false,
      then: () => address,
    }),
    address2: yupString,
    city: yupString.notRequired().when('addressIsSame', {
      is: false,
      then: () => city,
    }),
    postalCode: yupString.notRequired().when('addressIsSame', {
      is: false,
      then: () => postalCode,
    }),
    region: yupString.notRequired().when('addressIsSame', {
      is: false,
      then: () => state,
    }),
    ssn,
  }),
});

export const entityAccountFormSchema = yup.object<
  SchemaShape<EditInvestmentAccountInput>
>({
  entityName,
  businessType,
  ein,
  jurisdictionOfBusinessRegistration: yupString
    .oneOf(
      stateCodes,
      'State where organized is required and must be a valid state',
    )
    .required('State where organized is required'),
  dateOfFormation: date
    .required('Date of formation is required')
    .test(pastDate),
  dateOfRegistration: date
    .required('Date of registration is required')
    .test(pastDate),
  businessClass: yupString
    .oneOf(
      businessClasses,
      'Business class is required and must be a valid value',
    )
    .required('Business class is required'),
  industryClass: yupString
    .oneOf(
      industryClasses,
      'Industry class is required and must be a valid value',
    )
    .required('Industry class is required'),
  address,
  city,
  region: state,
  postalCode,
  officerTitle: yupString.required('Title is required'),
  user: yup.object().shape({
    firstName: yupString.required('First name is required'),
    lastName: yupString.required('Last name is required'),
    phone,
    dob,
    ssn,
  }),
});

export const iraAccountFormSchema = yup.object<
  SchemaShape<EditInvestmentAccountInput>
>({
  businessType: yupString.required('IRA account type is required'),
  entityName: yupString.required('Custodian is required'),
  iraAccountFboName: yupString.required('IRA account / FBO name is required'),
  iraAccountNumber: yupString.required('Custodian account number is required'),
});
