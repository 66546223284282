import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DeactivateInvestmentAccountModal from './DeactivateInvestmentAccountModal';

export interface Props {
  dwollaCustomerStatus: string | null | undefined;
  email: string;
  hasVerifiedBankAccount: boolean;
  id: string;
}

const showDwollaBalancesLink = (
  status: string | null | undefined,
  hasVerifiedBankAccount: boolean,
) => {
  if (status !== 'verified') return false;
  return hasVerifiedBankAccount;
};

const InvestmentAccountActions: React.FC<Props> = ({
  email,
  id,
  dwollaCustomerStatus,
  hasVerifiedBankAccount,
}) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const handleToggleDeactivateModal = () => {
    setDeactivateModalOpen(!deactivateModalOpen);
  };

  return (
    <div data-testid="investmentAccountActions">
      {deactivateModalOpen && (
        <DeactivateInvestmentAccountModal
          handleCloseModal={handleToggleDeactivateModal}
          id={id}
          email={email}
        />
      )}
      <ToggleableTooltip
        data-testid="toggleable-tooltip"
        triggerType="kebab"
        placement="leftStart"
      >
        <Link to={`/investment_accounts/${id}/edit`}>Edit</Link>
        <button
          type="button"
          className="textLink"
          onClick={handleToggleDeactivateModal}
        >
          Deactivate
        </button>
        {showDwollaBalancesLink(
          dwollaCustomerStatus,
          hasVerifiedBankAccount,
        ) && (
          <Link to={`/investment_accounts/${id}/move_dwolla_balances`}>
            Move Dwolla Balances
          </Link>
        )}
      </ToggleableTooltip>
    </div>
  );
};

export default InvestmentAccountActions;
