import {
  Button,
  DateSelect,
  FileUploader,
  Select,
} from '@equitymultiple/react-eui';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { longFormat } from 'utils/formatDate';
import { enumValueToLabel } from 'utils/stringFormatting';

import {
  AccreditationStatus,
  Document,
  EditInvestmentAccountInput,
  InvestmentAccount,
  InvestmentAccountType,
  ManualVerificationMethod,
  namedOperations,
  ProfessionalLetterStatus,
  useEditAccreditationMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import {
  setDateSelectFieldProps,
  setDefaultValues,
  setFieldProps,
} from '../../../../utils/formHelpers';
import { professionalLetterStatusOptions } from './constants';

interface Props {
  account: InvestmentAccount;
  professionalLetter: Document;
}

type FormValues = EditInvestmentAccountInput;

const messages = {
  loading: 'Updating account',
  error: 'An error occurred while updating the investment account',
  success: 'Account updated',
};

const getDefaultValues = (
  account: InvestmentAccount,
  professionalLetter: Document,
) => {
  const { id, accreditationLetterIssuedOn, professionalLetterStatus } = account;

  const fields: EditInvestmentAccountInput = {
    id: id as string,
    accreditationLetterIssuedOn: accreditationLetterIssuedOn as Date,
    professionalLetter: professionalLetter
      ? {
          url: professionalLetter.url,
          name: professionalLetter.filename,
        }
      : null,
    professionalLetterStatus:
      professionalLetterStatus as ProfessionalLetterStatus,
  };

  return setDefaultValues(fields as Record<string, unknown>);
};

const InvestmentAccountAccreditationForm: React.FC<Props> = ({
  account,
  professionalLetter,
}) => {
  let accountDefaultValues;
  if (account) {
    accountDefaultValues = getDefaultValues(account, professionalLetter);
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: accountDefaultValues,
  });

  const [editAccreditation, editAccreditationState] =
    useEditAccreditationMutation();
  const { loading } = editAccreditationState;

  const onSubmit = (values: FormValues) => {
    const professionalLetterUpdated = values.professionalLetter instanceof File;
    if (!professionalLetterUpdated) {
      delete values.professionalLetter;
    }

    callMutationWithToastMessages(editAccreditation, messages, {
      variables: {
        investmentAccount: {
          ...values,
        },
      },
      refetchQueries: [
        namedOperations.Query.getInvestmentAccount,
        namedOperations.Query.getInvestmentAccountDocuments,
      ],
    });
  };

  return (
    <form
      className="clearFix"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="investmentAccountAccreditationForm"
    >
      <hr />
      <h3>Accreditation</h3>
      <div data-testid="accreditationStatus">
        <strong>Accreditation Status:</strong>{' '}
        {enumValueToLabel(AccreditationStatus, account.accreditationStatus) ||
          'N/A'}
      </div>
      <div data-testid="manualVerificationMethod">
        <strong>Accreditation Method:</strong>{' '}
        {enumValueToLabel(
          ManualVerificationMethod,
          account.manualVerificationMethod,
        ) || 'N/A'}
      </div>
      <div data-testid="accountName">
        <strong>Account Name:</strong> {account.entityName || 'N/A'}
      </div>
      <div data-testid="accountType">
        <strong>Account Type:</strong>{' '}
        {enumValueToLabel(InvestmentAccountType, account.type)}
      </div>
      <h4>Professional Letter Verification</h4>
      <Row data-testid="professionalLetter">
        <Col lg={8} md={12} id="professionalLetter">
          <Controller
            name="professionalLetter"
            control={control}
            render={({ field }) => (
              <FileUploader
                {...setFieldProps(field, errors)}
                acceptedFileTypes={['JPG', 'PNG', 'PDF']}
                existingFile={field.value}
                onRemove={() => field.onChange(null)}
                showImage
                showLink
                upload={(uploadData: File) => field.onChange(uploadData)}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="accreditationLetterIssuedOn"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Letter Issued"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="professionalLetterStatus"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={professionalLetterStatusOptions}
                onChange={(e) => field.onChange(e)}
                label="Approval"
              />
            )}
          />
        </Col>
      </Row>
      <div data-testid="professionalLetterUploadedAt">
        <strong>Document Uploaded:</strong>{' '}
        {longFormat(professionalLetter?.updatedAt)}
      </div>
      <div data-testid="accreditationLetterExpireOn">
        <strong>Letter Expiration:</strong>{' '}
        {longFormat(account.accreditationLetterExpireOn)}
      </div>
      <div data-testid="accreditedExpireAt">
        <strong>Accreditation Expiration:</strong>{' '}
        {longFormat(account.accreditedExpireAt)}
      </div>
      <div data-testid="accreditationReviewedOn">
        <strong>Review Date:</strong>{' '}
        {longFormat(account.accreditationReviewedOn)}
      </div>
      <div data-testid="accreditationReviewedBy">
        <strong>Reviewed By:</strong> {account.accreditationReviewedBy || 'N/A'}
      </div>
      <Button type="submit" className="floatRight" loading={loading}>
        Save Accreditation
      </Button>
    </form>
  );
};

export default InvestmentAccountAccreditationForm;
