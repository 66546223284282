import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CreatePeriodInput,
  namedOperations,
  useCreatePeriodMutation,
  useGetPreviousAllocationsForOfferingQuery,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import PeriodForm from '../PeriodForm/PeriodForm';
import { periodSchema } from '../PeriodForm/validation';
import { normalizeCreatePeriodInput } from '../transactionCodeMapper';

const messages = {
  loading: 'Adding period',
  error: 'An error occurred while adding a period',
  success: 'Period added',
};

const NewPeriod: React.FC = () => {
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const [createPeriod, createPeriodState] = useCreatePeriodMutation();

  const { data: previousAllocationsData, loading: previousAllocationsLoading } =
    useGetPreviousAllocationsForOfferingQuery({
      variables: {
        offeringId: offeringId as string,
      },
    });

  const onSubmit = async (submitData: CreatePeriodInput) => {
    const isValid = await periodSchema.isValid(submitData);

    if (isValid) {
      const normalizedInput = normalizeCreatePeriodInput(submitData);

      const { data } = await callMutationWithToastMessages(
        createPeriod,
        messages,
        {
          variables: {
            period: normalizedInput,
          },
          refetchQueries: [namedOperations.Query.getPaymentPeriods],
        },
      );

      const periodId = data?.createPeriod.period?.id;
      if (periodId) {
        navigate(
          `/payments/schedule/${offeringId}/periods/${periodId}/allocate`,
        );
      }
    }
  };

  const headingText = (title?: string | null): string => {
    return `New Period ${title ? `- ${title}` : ''}`;
  };

  const previousAllocations = previousAllocationsData?.previousAllocations.data;
  const defaultValues = {
    allocations: previousAllocations?.map((allocation) => ({
      amount: allocation.amount,
      method: allocation.method,
      type: allocation.type.toString(),
      value: allocation.value,
    })),
    offeringId: offeringId,
  };

  return (
    <PeriodForm
      headingText={headingText}
      onSubmit={onSubmit}
      dataSubmitting={createPeriodState.loading}
      dataLoading={previousAllocationsLoading}
      defaultValues={defaultValues}
    />
  );
};

export default NewPeriod;
