import { Button, Card, Input, Select, Table } from '@equitymultiple/react-eui';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';

import {
  ClosingStage,
  DistributionFrequency,
  GetPaymentsQueryVariables,
  PaymentList,
  SortOrder,
  useGetPaymentsQuery,
} from '../../__generated__';
import { enumValueToLabel } from '../../utils/stringFormatting';

const columnHeaders = [
  'ID',
  'Offering Title',
  'Entity Name',
  'Distribution Frequency',
  'Next Payment On',
  'Transaction Schedules',
  'Payment Schedules',
];

const columnHeaderToSortField = {
  ID: 'id',
  'Offering Title': 'title',
  'Entity Name': 'entity_name',
  'Distribution Frequency': 'distribution_frequency',
  'Next Payment On': 'next_payment_on',
};

const stages = [
  { label: 'All Offerings', value: '' },
  { label: 'Funded', value: ClosingStage.Funded },
  { label: 'Cashflowing', value: ClosingStage.Cashflowing },
  { label: 'Exited', value: ClosingStage.Exited },
];

const Payments: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [visibleStages, setVisibleStages] = useState(['']);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);

  const getPaymentsVariables: GetPaymentsQueryVariables = {
    filter: debouncedSearchValue,
    stage: visibleStages.join(','),
    pagination: {
      page,
      pageSize: rowsPerPage,
    },
  };

  if (sortField && sortOrder) {
    getPaymentsVariables.sort = {
      field: sortField,
      order: sortOrder,
    };
  }

  const { data, loading, refetch, fetchMore } = useGetPaymentsQuery({
    variables: getPaymentsVariables,
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  useEffect(() => {
    if (sortField && sortOrder) refetch();
  }, [sortField, sortOrder, refetch]);

  const fetchMoreOfferings = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        status: visibleStages.join(','),
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreOfferings(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setPage(1);
    setRowsPerPage(pageSize);
    fetchMoreOfferings(1, pageSize);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handleSortColumnChange = (
    columnIndex: number,
    newSortOrder: SortOrder,
  ) => {
    setPage(1);
    setSortField(columnHeaderToSortField[columnHeaders[columnIndex]]);
    setSortOrder(newSortOrder);
  };

  const handleVisibleStagesChange = (stage: string[]) => {
    if (stage && stage.length > 1 && stage.indexOf('') > -1) {
      if (stage.indexOf('') === stage.length - 1) {
        stage.splice(0, stage.length - 1);
      } else {
        stage.splice(stage.indexOf(''), 1);
      }
    }

    setVisibleStages(stage || ['']);
  };

  const hasOfferings = data?.payments?.data && data.payments.data.length > 0;

  const getRows = (payments) => {
    return payments.map((payment: PaymentList) => ({
      cells: [
        parseInt(payment.id),
        payment.title,
        payment.entityName,
        enumValueToLabel(DistributionFrequency, payment.distributionFrequency),
        payment.nextPaymentOn ?? 'TBD',
        <Button
          key="viewTransactions"
          variant="outlined"
          wrapper={
            <Link to={`/payments/schedule/${payment.id}/transactions`} />
          }
        >
          View
        </Button>,
        <Button
          key="runTransactions"
          variant="outlined"
          wrapper={<Link to={`/payments/transactions/${payment.id}/run`} />}
        >
          View
        </Button>,
      ],
    }));
  };

  return (
    <>
      <h1 data-testid="paymentsHeading">Payments</h1>
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="offeringSearch"
              label="Search Offerings"
              placeholder="Title, ID or Entity Name"
              onChange={handleSearchValueChange}
              value={searchValue}
            />
          </Col>
          <Col lg={4} md={6}>
            <Select
              id="closingStage"
              onChange={handleVisibleStagesChange}
              options={stages}
              label="Closing Stage"
              multi={true}
              value={visibleStages}
            />
          </Col>
        </Row>
        <Table
          allowSorting={[0, 1, 2, 3, 4]}
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={10}
          sortRows={false}
          onSort={(columnIndex, newSortOrder) =>
            handleSortColumnChange(columnIndex, newSortOrder as SortOrder)
          }
          pagination={{
            page: hasOfferings ? page - 1 : 0,
            rowsPerPage,
            onRowsPerPageChange: handleRowsPerPageChange,
            onPageChange: handlePageChange,
            count: data?.payments?.pageInfo?.count || 0,
          }}
          rows={
            hasOfferings
              ? getRows(data.payments.data as PaymentList[])
              : [{ cells: ['No payments found'] }]
          }
        />
      </Card>
    </>
  );
};

export default Payments;
