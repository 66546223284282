import { Button } from '@equitymultiple/react-eui';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import ToasterWrap from '../components/ToasterWrap/ToasterWrap';

// TODO eventually replace with REUI counterpart
const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
}: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ToasterWrap>
        <Router>{children}</Router>
      </ToasterWrap>
    </ErrorBoundary>
  );
};

export default AppProvider;
