import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { useDeletePeriodMutation } from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  handleCloseModal: () => void;
  periodData: {
    offeringId: string;
    periodId: string;
  } | null;
}

const messages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the period',
  success: 'Period deleted',
};

const DeletePeriodModal: React.FC<Props> = ({
  periodData,
  handleCloseModal,
}) => {
  const offeringId = periodData?.offeringId as string;
  const periodId = periodData?.periodId as string;
  const [deletePeriodMutation, deletePeriodMutationState] =
    useDeletePeriodMutation({
      variables: {
        offeringId,
        periodId,
      },
      update(cache) {
        deleteObjectFromCache(cache, 'PaymentPeriod', periodId);
      },
    });

  const handleDeletePeriod = () => {
    callMutationWithToastMessages(deletePeriodMutation, messages).then(() => {
      handleCloseModal();
      deletePeriodMutationState.reset();
    });
  };

  return (
    <Modal
      data-testid="deletePeriodModal"
      open={periodData !== null}
      title={<h6>Are you sure you want to delete this period?</h6>}
      closeButtonText="Cancel"
      submitButton={
        <Button variant="orange" onClick={handleDeletePeriod}>
          Yes, Delete It
        </Button>
      }
      onClose={handleCloseModal}
    >
      {' '}
      <p className="margin30">
        Deleting this period will also delete all associated transactions with
        this period.
      </p>
    </Modal>
  );
};

export default DeletePeriodModal;
