import {
  DwollaCustomer,
  DwollaFundingSource,
  InputMaybe,
  namedOperations,
  Scalars,
  useGetDwollaCustomerBalanceQuery,
  useMoveDwollaCustomerBalanceMutation,
} from '__generated__';
import {
  Button,
  Card,
  EMLoadingIcon,
  Input,
  Select,
} from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { dwollaCustomerFundingSourcesLink } from 'utils/constants';
import { setFieldProps } from 'utils/formHelpers';

import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import * as styles from './AdminAccounts.module.scss';
import { moveDwollaCustomerBalanceSchema } from './validation';

type DwollaBalanceInput = {
  destination?: InputMaybe<Scalars['ID']['input']>;
};

const toastMessages = {
  loading: 'Moving Dwolla balance',
  error: 'An error occurred while attempting to move the Dwolla balance',
  success: 'Dwolla balance moved',
};

const MoveDwollaBalance: React.FC = () => {
  const [bankAccountOptions, setBankAccountOptions] = useState<Option[]>([]);
  const [moveDwollaCustomerBalance, moveDwollaCustomerBalanceState] =
    useMoveDwollaCustomerBalanceMutation();
  const submitting = moveDwollaCustomerBalanceState.loading;

  const { id } = useParams();
  const { data, loading } = useGetDwollaCustomerBalanceQuery({
    variables: {
      dwollaCustomerId: id as string,
    },
  });

  const dwollaCustomer = data?.dwollaCustomer?.dwollaCustomer as DwollaCustomer;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues<DwollaBalanceInput>>({
    mode: 'onBlur',
    resolver: yupResolver(moveDwollaCustomerBalanceSchema),
  });

  useEffect(() => {
    if (dwollaCustomer?.fundingSources?.length > 0) {
      const bankAccounts = dwollaCustomer.fundingSources.map(
        (fundingSource: DwollaFundingSource) => ({
          value: fundingSource.id as string,
          label: `${fundingSource.bankAccount?.name} (${fundingSource.bankAccount?.accountNumber})`,
        }),
      );
      setBankAccountOptions(bankAccounts);
    }
  }, [dwollaCustomer]);

  const onSubmit = (submitData: DwollaBalanceInput) => {
    callMutationWithToastMessages(moveDwollaCustomerBalance, toastMessages, {
      variables: {
        dwollaCustomerId: dwollaCustomer.id as string,
        destination: submitData.destination,
      },
      refetchQueries: [namedOperations.Query.getDwollaCustomerBalance],
    }).then((res) => {
      if (!res.errors && !res?.data?.moveDwollaCustomerBalance?.error) {
        reset();
      }
    });
  };

  return loading ? (
    <EMLoadingIcon
      data-testid="emLoadingIcon"
      className={loadingIconStyles.cardLoader}
    />
  ) : (
    <>
      <h2 data-testid="moveDwollaBalance">
        {dwollaCustomer
          ? `Move Dwolla Balance - #${dwollaCustomer.id}`
          : 'Move Dwolla Balance'}
      </h2>
      <Card>
        <div className={styles.formTop}>
          <Link to="/accounts">&lt; Back</Link>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="moveDwollaCustomerBalance"
        >
          <Row>
            <Col lg={4} md={6}>
              <h4>Balance</h4>
              <Input
                value={dwollaCustomer.balance}
                allowDecimal
                dollarMask
                disabled
                label="Dwolla Balance"
                data-testid="dwolla-balance"
              />
              <a
                target="_blank"
                rel="noreferrer"
                href={dwollaCustomerFundingSourcesLink(dwollaCustomer)}
              >
                View in Dwolla
              </a>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              <h4>Destination</h4>
              <Controller
                name="destination"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={bankAccountOptions}
                    onChange={(e) => field.onChange(e)}
                    label="Select Bank Account"
                    data-testid="destination-funding-source"
                  />
                )}
              />
            </Col>
          </Row>
          <Button className="floatRight" type="submit" loading={submitting}>
            Move Dwolla Balance
          </Button>
        </form>
      </Card>
    </>
  );
};

export default MoveDwollaBalance;
