import { Closing, ClosingStage, DocumentType } from '__generated__';
import {
  Button,
  Checkbox,
  FileUploader,
  RadioButton,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DocumentSubtype,
  OfferingDocumentAcceptedFileTypes,
  OfferingDocumentAttachmentLabel,
  OfferingDocumentGrouping,
  OfferingDocumentGroupOptions,
  offeringDocumentSubtypeOptions,
  TaxYearOptions,
} from 'constants/documents';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import SelectOption from 'types/SelectOption';
import cloneObject from 'utils/cloneObject';
import { formatClosingName } from 'views/Offerings/helpers';

import { serverSideUploadLimit } from '../../../constants';
import { offeringDocumentSchema } from './validation';

// eslint-disable-next-line
type FormValues = Record<string, any>;

const allClosingsOption = {
  label: 'All Closings',
  value: '0',
};

const getClosingOptions = (closings: Closing[]) => {
  const availableClosings = closings?.filter((closing) =>
    [
      ClosingStage.Funded,
      ClosingStage.Cashflowing,
      ClosingStage.Exited,
    ].includes(closing.stage),
  );

  const closingOptions = availableClosings?.map((closing) => ({
    label: formatClosingName(closing),
    value: closing.id,
  }));

  return [...[allClosingsOption], ...(closingOptions ?? [])];
};

export interface Props {
  closings?: Closing[];
  defaultValues?: DefaultValues<FormValues>;
  documentTypeOptions: SelectOption[];
  loading: boolean;
  onSubmit(submitData: FormValues): unknown;
}

const OfferingDocumentForm: React.FC<Props> = ({
  onSubmit,
  loading,
  defaultValues,
  documentTypeOptions,
  closings,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(offeringDocumentSchema),
    defaultValues: {
      ...defaultValues,
      amended: false,
      documentReplace: false,
      closingId: null,
    },
  });

  const watchType = watch('type');
  const watchGroup = watch('groupDocument');
  const closingOptions = getClosingOptions(closings);

  const resetValues = {
    type: watchType,
    groupDocument: watchGroup,
    attachment: null,
    taxYear: '',
    documentType: null,
    amended: false,
    documentReplace: false,
  };

  useEffect(() => {
    reset({
      ...resetValues,
      closingId:
        watchGroup === OfferingDocumentGrouping.Group
          ? getValues('closingId')
          : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, watchGroup, watchType, getValues]);

  const handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (event.target?.checked) {
      setValue('type', value as DocumentType);
    }
  };

  const handleGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (event.target?.checked) {
      setValue('groupDocument', value as OfferingDocumentGrouping);
    }
  };

  const uploadDocument = async (data: FormValues) => {
    const dataCopy = cloneObject(data);
    dataCopy.attachment = data.attachment;
    dataCopy.fileSize = data.attachment ? data.attachment.size : 0;
    dataCopy.groupDocument =
      data.groupDocument === OfferingDocumentGrouping.Group
        ? 'true'
        : undefined;
    dataCopy.closingId = data.closingId === '0' ? undefined : data.closingId;
    dataCopy.amended =
      data.amended != undefined ? data.amended.toString() : undefined;
    dataCopy.documentReplace =
      data.documentReplace != undefined
        ? data.documentReplace.toString()
        : undefined;

    const response = await onSubmit(dataCopy);

    if (response) {
      reset(resetValues);
    }
  };

  return (
    <>
      <h4 data-testid="documentUpload" className="marginTop0">
        Document Upload
      </h4>
      <form
        onSubmit={handleSubmit(uploadDocument)}
        data-testid="offeringDocumentForm"
        className="clearFix"
      >
        <Row className="margin20">
          <Col xl={4} lg={6} md={6}>
            <p>Document Grouping</p>
            {OfferingDocumentGroupOptions.map(({ label, value }) => {
              return (
                <Controller
                  key={`doc-form-acc-group-${value}`}
                  name="groupDocument"
                  control={control}
                  render={({ field }) => (
                    <RadioButton
                      {...field}
                      id={`doc-form-acc-group-${value}`}
                      label={label}
                      checked={field.value == value}
                      onChange={(evt) => handleGroupChange(evt, value)}
                    />
                  )}
                />
              );
            })}
          </Col>
        </Row>
        <Row className="margin20">
          <Col xl={4} lg={6} md={6}>
            <p>Document Type</p>
            {documentTypeOptions.map(({ label, value }) => {
              return (
                <Controller
                  key={`doc-form-acc-type-${value}`}
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <RadioButton
                      {...field}
                      id={`doc-form-acc-type-${value}`}
                      label={label}
                      checked={field.value == value}
                      onChange={(evt) => handleTypeChange(evt, value)}
                    />
                  )}
                />
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={8} md={8}>
            {
              <p>
                {watchType &&
                  watchGroup &&
                  OfferingDocumentAttachmentLabel[watchGroup][watchType]}
              </p>
            }
            <Controller
              name="attachment"
              control={control}
              render={({ field }) => (
                <FileUploader
                  {...field}
                  onRemove={() => field.onChange(null)}
                  upload={(uploadData: File) => field.onChange(uploadData)}
                  existingFile={field.value}
                  errorMessage={errors.attachment?.message as string}
                  acceptedFileTypes={
                    OfferingDocumentAcceptedFileTypes[watchGroup]
                  }
                  reset={!field.value}
                  maxSize={serverSideUploadLimit}
                />
              )}
            />
          </Col>
        </Row>

        {watchGroup === OfferingDocumentGrouping.Group && (
          <Row className="margin20">
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="closingId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id={`doc-form-closing-id`}
                    label="Closing"
                    options={closingOptions}
                    errorMessage={errors.closingId?.message as string}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        <Row className="margin20">
          {watchType === DocumentType.TaxDocument && (
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="taxYear"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id={`doc-form-tax-year`}
                    label="Tax Year"
                    options={TaxYearOptions}
                    errorMessage={errors.taxYear?.message as string}
                  />
                )}
              />
            </Col>
          )}

          <Col xl={4} lg={6} md={6}>
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id={`doc-form-document-type`}
                  label={
                    DocumentSubtype[watchType || DocumentType.TaxDocument] +
                    ' Type'
                  }
                  options={offeringDocumentSubtypeOptions(
                    watchType,
                    watchGroup,
                  )}
                  errorMessage={errors.documentType?.message as string}
                />
              )}
            />
          </Col>
        </Row>

        <Row className="margin20">
          {watchType === DocumentType.TaxDocument && (
            <Col xl={4} lg={6} md={6}>
              <Controller
                name="documentReplace"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id={`doc-form-document-replace`}
                    label="Replace the most recently uploaded document with the same filename, type and tax year"
                    checked={field.value}
                  />
                )}
              />
            </Col>
          )}

          <Col xl={4} lg={6} md={6}>
            <Controller
              name="amended"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id={`doc-form-amended`}
                  label="Amended"
                  checked={field.value}
                />
              )}
            />
          </Col>
        </Row>

        <Button type="submit" className="floatRight margin40" loading={loading}>
          Submit
        </Button>
      </form>
    </>
  );
};

export default OfferingDocumentForm;
