import { Button, Card, Table } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Allocation,
  AllocationMethod,
  AllocationSummary,
  AllocationTransaction,
  namedOperations,
  TransactionType,
  useCreatePeriodTransactionsMutation,
  useGetAllocationSummaryQuery,
} from '../../../__generated__';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import formatCurrency from '../../../utils/formatCurrency';
import { enumValueToLabel } from '../../../utils/stringFormatting';
import * as styles from './ReviewAllocation.module.scss';

const createTransactionMessages = {
  loading: 'Creating transactions',
  error: 'An error occurred while creating the transactions',
  success: 'Transactions created',
};

const allocationTableColumnHeaders = [
  'Transaction Type',
  'Method',
  'Allotted Amount',
  'Actual Allocation',
];

const loadingTransactionTableColumnHeaders = ['Investor', 'Amount', 'Closing'];

const getTransactionTableColumnHeaders = (
  transactions: AllocationSummary[],
) => {
  const transactionTypes =
    transactions?.[0]?.allocations?.map((allocation) =>
      enumValueToLabel(TransactionType, allocation.type),
    ) || [];

  return ['Investor', 'Amount', 'Closing', ...transactionTypes];
};

const getAllocationAllottedAmount = (
  allocation: Allocation,
  transactions: AllocationSummary[],
) => {
  const totalAmount = transactions?.length
    ? transactions.reduce((total, transaction) => {
        const allocationTransaction = transaction.allocations?.find(
          (transactionAllocation: AllocationTransaction) =>
            transactionAllocation.type === allocation.type,
        );
        return (allocationTransaction?.amount || 0) + total;
      }, 0)
    : 0;
  return totalAmount;
};

const getAllocationRows = (
  transactions: AllocationSummary[],
  allocations: Allocation[],
) => {
  return allocations.map((allocation) => {
    return {
      cells: [
        enumValueToLabel(TransactionType, allocation.type),
        enumValueToLabel(AllocationMethod, allocation.method),
        allocation.amount || allocation.value,
        formatCurrency(getAllocationAllottedAmount(allocation, transactions)),
      ],
    };
  });
};

const getTransactionRows = (transactions: AllocationSummary[]) => {
  return transactions.map((transaction) => {
    const allocationAmounts =
      transaction.allocations?.map((allocation) =>
        formatCurrency(allocation.amount),
      ) || [];
    return {
      cells: [
        `${transaction.investor}\n(${transaction.account})`,
        formatCurrency(transaction.amount),
        transaction.closing,
        ...allocationAmounts,
      ],
    };
  });
};

const Review: React.FC = () => {
  const { offeringId, periodId } = useParams();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const { data, loading, error } = useGetAllocationSummaryQuery({
    variables: {
      offeringId: offeringId as string,
      periodId: periodId as string,
    },
  });

  const [createTransactions, createTransactionsState] =
    useCreatePeriodTransactionsMutation({
      variables: {
        offeringId: offeringId as string,
        periodId: periodId as string,
      },
    });

  const hasError = error || data?.allocationSummary.error;

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading allocations');
      navigate('/payments');
    }
  }, [hasError, navigate]);

  const handleCreateTransactions = () => {
    callMutationWithToastMessages(
      createTransactions,
      createTransactionMessages,
      {
        refetchQueries: [
          namedOperations.Query.getPaymentPeriods,
          namedOperations.Query.getPaymentTransactions,
        ],
      },
    ).then(() => {
      setModalOpen(false);
      navigate(`/payments/schedule/${offeringId}/transactions`);
    });
  };

  const transactions = data?.allocationSummary?.transactions;
  const allocations = data?.period.period?.allocations;

  return (
    <>
      {modalOpen && (
        <ConfirmationModal
          handleCloseModal={() => setModalOpen(false)}
          onSubmit={handleCreateTransactions}
          title={`Are you sure you want to create these transactions?`}
          content={
            <p>
              {(allocations?.length || 0) *
                (data?.allocationSummary.investmentCount || 0)}{' '}
              draft transactions will be created for{' '}
              {data?.allocationSummary.investmentCount} investors.
            </p>
          }
          buttonText={`Yes, create transactions`}
          loading={createTransactionsState.loading}
        />
      )}
      <h2>
        Review Period Allocation{' '}
        {loading ? '' : `for ${data?.offering?.offering?.title}`}
      </h2>
      <Card data-testid="allocationSummary">
        <h3>Allocation Summary</h3>
        <p>
          Review the allocation below. To create draft transactions, press
          Create Transactions.
        </p>
        <Table
          className="margin30"
          loading={loading}
          loadingRows={2}
          columnHeaders={allocationTableColumnHeaders}
          rows={
            loading
              ? []
              : getAllocationRows(
                  transactions as AllocationSummary[],
                  allocations as Allocation[],
                )
          }
        />
        <Row>
          <Col xs={3} className="alignItemsCenter">
            <Link
              type="button"
              className="textLink"
              to={`/payments/schedule/${offeringId}/periods/${periodId}/allocate`}
            >
              Back
            </Link>
          </Col>
          <Col xs={9}>
            <Button
              type="submit"
              className={`floatRight ${styles.backButton}`}
              disabled={loading}
              loading={createTransactionsState.loading}
              onClick={() => setModalOpen(true)}
            >
              Create Transactions
            </Button>
          </Col>
        </Row>
      </Card>
      <Card data-testid="transactionSummary">
        <h3>Transaction Summary</h3>
        {!loading && (
          <p>
            Showing allocations for {data?.allocationSummary.investmentCount}{' '}
            investments
          </p>
        )}
        <Table
          loading={loading}
          loadingRows={10}
          columnHeaders={
            loading
              ? loadingTransactionTableColumnHeaders
              : getTransactionTableColumnHeaders(
                  transactions as AllocationSummary[],
                )
          }
          rows={
            loading
              ? []
              : getTransactionRows(transactions as AllocationSummary[])
          }
        />
      </Card>
    </>
  );
};

export default Review;
