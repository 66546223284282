import React from 'react';
import { Toaster } from 'react-hot-toast';

interface Props {
  children: React.ReactNode;
}

const ToasterWrap: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Toaster
        position="top-right"
        containerClassName="toasterContainer"
        containerStyle={{
          top: 77,
        }}
        toastOptions={{
          style: {
            width: 350,
          },
          error: {
            duration: 8000,
          },
        }}
      />
      {children}
    </>
  );
};

export default ToasterWrap;
