import { Card } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import * as styles from './NoMatchingRoute.module.scss';

const NoMatchingRoute: React.FC = () => {
  return (
    <Card>
      <div className={styles.noMatch}>
        <h4>Nothing could be found at this location.</h4>
        <Link to="/">Return to homepage</Link>
      </div>
    </Card>
  );
};

export default NoMatchingRoute;
