import { yupBool, yupString } from 'utils/validations';
import * as yup from 'yup';

export const investmentSchema = yup.object().shape({
  fullRefund: yupBool.required('Refund type is required'),
  paymentMethod: yupString.required('Payment method is required'),
  amount: yupString
    .required('Amount is required')
    .test(
      'Amount must be present and greater that 0',
      'Amount is required',
      (val) => {
        return parseInt(val) > 0 && !isNaN(parseInt(val));
      },
    ),
});
