import { Card, MultiSelectFilter } from '@equitymultiple/react-eui';
import React, { useMemo } from 'react';
import arrayEqual from 'utils/arrayEqual';

import { OfferingList } from '../../../../__generated__';
import * as styles from '../../../Posts/posts.module.scss';
import {
  getLabelOptions,
  getOfferingOptions,
  getStatusOptions,
} from './filterOptions';

export interface Props {
  handleLabelFilterChange(filters: string[]): void;
  handleOfferingFilterChange(filters: number[]): void;
  handleStatusFilterChange(filters: string[]): void;
  labelFilters: string[];
  offeringFilters: number[];
  offerings: OfferingList[];
  statusFilters: string[];
}

const statusOptions = getStatusOptions();
const labelOptions = getLabelOptions();

const PostFilters: React.FC<Props> = ({
  statusFilters,
  handleStatusFilterChange,
  labelFilters,
  handleLabelFilterChange,
  offerings,
  offeringFilters,
  handleOfferingFilterChange,
}) => {
  const offeringOptions = useMemo(
    () => getOfferingOptions(offerings),
    [offerings],
  );

  const handleFilterChange = (newFilters: (string | number)[][]) => {
    if (!arrayEqual(newFilters[0], statusFilters))
      handleStatusFilterChange(newFilters[0] as string[]);

    if (!arrayEqual(newFilters[1], labelFilters))
      handleLabelFilterChange(newFilters[1] as string[]);

    if (!arrayEqual(newFilters[2], offeringFilters))
      handleOfferingFilterChange(newFilters[2] as number[]);
  };

  return (
    <>
      <Card className={styles.filtersCard}>
        <MultiSelectFilter
          filterGroups={[
            {
              hasClearAll: true,
              options: statusOptions,
              selectedOptions: [...statusFilters],
              title: 'Status',
            },
            {
              hasClearAll: true,
              options: labelOptions,
              selectedOptions: [...labelFilters],
              title: 'Post Type',
            },
            {
              hasClearAll: true,
              options: offeringOptions,
              selectedOptions: [...offeringFilters],
              title: 'Offerings',
            },
          ]}
          mobileLayoutEnabled={false}
          onChange={handleFilterChange}
        />
      </Card>
    </>
  );
};

export default PostFilters;
