import Option from '@equitymultiple/react-eui/dist/types/Select';

import { EnumLike } from '../types/EnumLike';
import { capitalToTitleCase } from './stringFormatting';

/**
 * Utility method for taking basic graphql-generated enums and generating
 * option values for `Select` elements.
 * @param enumType
 * @returns
 */
export const enumToSelectOptions = <T extends EnumLike>(
  enumType: T,
): Array<Option> => {
  const options = [];

  for (const [key, value] of Object.entries(enumType)) {
    options.push({ label: capitalToTitleCase(key), value });
  }

  return options;
};
