import { Alert, Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  InvestmentAccount,
  InvestmentAccountDocumentType,
  InvestmentAccountType,
  useGetInvestmentAccountDocumentsQuery,
  useGetInvestmentAccountQuery,
} from '../../../../__generated__';
import * as loadingIconStyles from '../../../../styles/components/EMLoadingIcon.module.scss';
import DeactivateInvestmentAccountModal from '../DeactivateInvestmentAccountModal';
import * as styles from '../InvestmentAccountForm.module.scss';
import DocumentsForm from './Documents/DocumentsForm';
import InvestmentAccountAccreditationForm from './InvestmentAccountAccreditationForm';
import InvestmentAccountEditForm from './InvestmentAccountEditForm';

const InvestmentAccountEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: accountData,
    error: accountError,
    loading: accountLoading,
  } = useGetInvestmentAccountQuery({
    variables: {
      investmentAccountId: id as string,
    },
  });

  const {
    data: documentsData,
    error: documentsError,
    loading: documentsLoading,
  } = useGetInvestmentAccountDocumentsQuery({
    variables: {
      investmentAccountId: id as string,
    },
  });

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const handleToggleDeactivateModal = () => {
    setDeactivateModalOpen(!deactivateModalOpen);
  };

  const account = accountData?.investmentAccount.investmentAccount;
  const documents = documentsData?.investmentAccountDocuments.documents;
  const hasError =
    accountError ||
    accountData?.investmentAccount?.error ||
    documentsError ||
    documentsData?.investmentAccountDocuments.error;
  const loading = accountLoading || documentsLoading;
  const showForm = !loading && !hasError;

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading the investment account');
      navigate('/accounts/#investment_accounts');
    }
  }, [hasError, navigate]);

  return (
    <>
      {account && deactivateModalOpen && (
        <DeactivateInvestmentAccountModal
          handleCloseModal={handleToggleDeactivateModal}
          id={account.id as string}
          email={account?.user?.email as string}
          onDeactivate={() => navigate('/accounts/#investment_accounts')}
        />
      )}
      <h2>
        {loading
          ? 'Edit Investment Account'
          : `Edit Investment Account - ${account?.referenceId}`}
      </h2>
      <Card className="firstChildMarginTop0">
        <div className={styles.formTop}>
          <Link to="/accounts/#investment_accounts">&lt; Back</Link>
          <Button variant="orange" onClick={handleToggleDeactivateModal}>
            Deactivate
          </Button>
        </div>
        {showForm ? (
          <>
            {account?.dwollaCustomer?.status === 'retry' && (
              <Alert type="warning">
                This account is in Retry. Please review the details below and
                click "Save Account" to enable the "Submit Retry Request"
                button. Clicking "Submit Retry Request" button will make the
                retry request to Dwolla with the given details.
              </Alert>
            )}
            <InvestmentAccountEditForm account={account as InvestmentAccount} />
            <InvestmentAccountAccreditationForm
              account={account as InvestmentAccount}
              professionalLetter={documents?.find(
                (doc) =>
                  doc.label ===
                  InvestmentAccountDocumentType.ProfessionalLetter,
              )}
            />
            {account?.type !== 'ira' && (
              <DocumentsForm
                accountId={account?.id as string}
                accountType={account?.type as InvestmentAccountType}
                documents={documents}
              />
            )}
          </>
        ) : (
          <EMLoadingIcon
            data-testid="emLoadingIcon"
            className={loadingIconStyles.cardLoader}
          />
        )}
      </Card>
    </>
  );
};

export default InvestmentAccountEdit;
