import {
  Button,
  DateSelect,
  Input,
  Select,
  Table,
  TextArea,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';

import {
  BeneficialOwner,
  BeneficialOwnerInput,
  DwollaCustomer,
  namedOperations,
  useCreateBeneficialOwnerMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import {
  setDateSelectFieldProps,
  setFieldProps,
} from '../../../../utils/formHelpers';
import { stateOptions } from '../../constants';
import { beneficialOwnersSchema } from '../validation';
import BeneficialOwnersActions from './BeneficialOwnersActions';

const toastMessages = {
  loading: 'Adding beneficial owner',
  error: 'An error occurred while attempting to add the beneficial owner',
  success: 'Beneficial owner added',
};

const columnHeaders = [
  'First Name',
  'Last Name',
  'Email',
  'Status',
  'Title',
  'Actions',
];

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  ssn: '',
  dob: '',
  phone: '',
  address: '',
  address2: '',
  city: '',
  postalCode: '',
};

export interface Props {
  dwollaCustomer: DwollaCustomer;
  loading: boolean;
}

const BeneficialOwners: React.FC<Props> = ({ loading, dwollaCustomer }) => {
  const [createBeneficialOwner, createBeneficialOwnerState] =
    useCreateBeneficialOwnerMutation();

  const creatingBeneficialOwner = createBeneficialOwnerState.loading;

  const beneficialOwners =
    dwollaCustomer?.beneficialOwners as BeneficialOwner[];

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<BeneficialOwnerInput>({
    mode: 'onBlur',
    resolver: yupResolver(beneficialOwnersSchema),
    defaultValues,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [reset, isSubmitSuccessful]);

  const onBeneficialOwnerSubmit = (submitData: BeneficialOwnerInput) => {
    submitData.dwollaCustomerId = dwollaCustomer.id as string;

    callMutationWithToastMessages(createBeneficialOwner, toastMessages, {
      variables: {
        beneficialOwnerInput: submitData,
      },
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
    });
  };

  const getRows = () => {
    return beneficialOwners && beneficialOwners.length > 0
      ? beneficialOwners.map((beneficialOwner) => {
          const {
            firstName,
            lastName,
            email,
            status,
            title,
            id: beneficialOwnerId,
          } = beneficialOwner;

          return {
            cells: [
              firstName,
              lastName,
              email,
              status,
              title,
              <BeneficialOwnersActions
                key={beneficialOwnerId}
                dwollaCustomerId={dwollaCustomer.id as string}
                beneficialOwnerId={beneficialOwnerId as string}
              />,
            ],
          };
        })
      : [{ cells: ['No beneficial owner found'] }];
  };

  return (
    <>
      <h3>Beneficial Owners</h3>
      <Row>
        <Col xl={8} lg={12}>
          <Table
            loading={loading}
            loadingRows={2}
            columnHeaders={columnHeaders}
            rows={getRows()}
          />
        </Col>
      </Row>
      <h3>Add New Beneficial Owner</h3>
      <form
        onSubmit={handleSubmit(onBeneficialOwnerSubmit)}
        className="clearFix"
      >
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="First Name" />
              )}
            />
          </Col>
          <Col lg={4} md={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="Last Name" />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="Email" />
              )}
            />
          </Col>
          <Col lg={4} md={6}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="Title" />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="ssn"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="SSN"
                  mask="999-99-9999"
                />
              )}
            />
          </Col>
          <Col lg={4} md={6}>
            <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <DateSelect
                  {...setDateSelectFieldProps(field, errors)}
                  label="Date of Birth"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="Phone Number"
                  mask="(999) 999-9999 [ext 999999]"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...setFieldProps(field, errors)}
                  label="Address Line 1"
                />
              )}
            />
          </Col>
          <Col lg={4} md={6}>
            <Controller
              name="address2"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...setFieldProps(field, errors)}
                  label="Address Line 2 (Optional)"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input {...setFieldProps(field, errors)} label="City" />
              )}
            />
          </Col>
          <Col lg={4} md={6}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  label="State"
                  options={stateOptions}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  mask="9{5}"
                  label="Postal Code"
                />
              )}
            />
          </Col>
        </Row>
        <Button
          type="submit"
          className="floatRight"
          loading={creatingBeneficialOwner}
        >
          Add Beneficial Owner
        </Button>
      </form>
    </>
  );
};

export default BeneficialOwners;
