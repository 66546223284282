import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import apolloCacheConfig from './apolloCacheConfig';
import getCookie from './getCookie';

// If any request 401s, log user out completely
const logoutLink = onError(({ networkError }: ErrorResponse) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    // window.location.replace('/login'); // TODO make this URL a constant, somewhere, later
  }
});

const uploadLink = createUploadLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
  headers: {
    'Csrf-Token': getCookie('__Host-Xsrf-Token'),
    'Apollo-Require-Preflight': 'true',
  },
}) as unknown as ApolloLink;

const client = new ApolloClient({
  link: logoutLink.concat(uploadLink),
  cache: new InMemoryCache(apolloCacheConfig),
});

export default client;
