import { yupString } from 'utils/validations';
import * as yup from 'yup';

export const iraInvestmentAccountSchema = yup.object().shape({
  businessType: yupString.required('IRA account type is required'),
  customEntityName: yupString.when('entityName', {
    is: (val: string | null) => val == 'Other',
    then: () =>
      yupString.required(
        'Custom custodian name is required when setting Custodian to "Other"',
      ),
  }),
  entityName: yupString.required('Custodian is required'),
  iraAccountFboName: yupString.required('IRA account / FBO name is required'),
  iraAccountNumber: yupString.required('Custodian account number is required'),
  user: yup.mixed().required('User is required'),
});
