import { Button, Modal } from '@equitymultiple/react-eui';
import { ButtonProps } from '@equitymultiple/react-eui/dist/components/Button/Button';
import React from 'react';
import * as modalStyles from 'styles/components/Modal.module.scss';

export interface Props {
  buttonText?: string;
  buttonVariant?: ButtonProps['variant'];
  content?: React.ReactNode;
  handleCloseModal: () => void;
  loading?: boolean;
  onSubmit: () => void;
  title?: string;
}

const ConfirmationModal: React.FC<Props> = ({
  buttonVariant,
  buttonText,
  content,
  loading,
  title,
  handleCloseModal,
  onSubmit,
}) => {
  return (
    <Modal
      open
      data-testid="confirmationModal"
      title={title && <h6>{title}</h6>}
      closeButtonText="Cancel"
      onClose={handleCloseModal}
      submitButton={
        <Button
          variant={buttonVariant || 'orange'}
          onClick={onSubmit}
          loading={loading}
        >
          {buttonText || 'Yes'}
        </Button>
      }
    >
      <div data-testid="modalContent" className={modalStyles.contentItem}>
        {content || ''}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
