import { yupString } from 'utils/validations';
import * as yup from 'yup';

export const transactionSchema = yup.object().shape({
  amount: yupString.required('Amount is required'),
  eventType: yupString.required('Event type is required'),
  type: yupString.required('Transaction type is required'),
  paymentMethod: yupString.required('Payment method is required'),
  effectiveDate: yupString.required('Effective date is required'),
});
