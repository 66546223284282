import { Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { OfferingInput, useGetSponsorsListQuery } from '../../../__generated__';
import { setFieldProps } from '../../../utils/formHelpers';

interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors;
}

const SponsorFields: React.FC<Props> = ({ control, errors }) => {
  const [sponsorOptions, setSponsorOptions] = useState<Option[]>([]);
  const { data, loading } = useGetSponsorsListQuery();

  useEffect(() => {
    if (data && data?.simpleSponsors?.data) {
      const newSponsorOptions = data?.simpleSponsors?.data.map((sponsor) => ({
        label: sponsor.name as string,
        value: sponsor.id as string,
      }));

      setSponsorOptions(newSponsorOptions);
    }
  }, [data]);

  return (
    <>
      <hr />
      <h3>Sponsors</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="sponsorIds"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={sponsorOptions}
                onChange={(val) => field.onChange(val)}
                label="Sponsors"
                disabled={loading}
                helperText={loading ? 'Loading sponsors...' : ''}
                helperTextAlwaysVisible
                multi
                showMultiLabelsBelow
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default SponsorFields;
