const formatCurrency = (amount: string | number, decimal = 2): string => {
  const numberAmount = typeof amount === 'string' ? parseInt(amount) : amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  }).format(numberAmount);
};

export default formatCurrency;
