import {
  DwollaCustomer,
  namedOperations,
  useEditDwollaCustomerMutation,
} from '__generated__';
import { Button, Input } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import { setFieldProps } from 'utils/formHelpers';

export interface Props {
  dwollaCustomer: DwollaCustomer;
  loading: boolean;
}

const toastMessages = {
  loading: 'Updating field',
  error: 'An error occurred while attempting to update the field',
  success: 'Field updated',
};

const EditDwollaCustomer = ({ dwollaCustomer, loading }: Props) => {
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      doingBusinessAs: '',
    },
  });
  const [editDwollaCustomer, editDwollaCustomerState] =
    useEditDwollaCustomerMutation();
  const submitting = editDwollaCustomerState.loading;

  useEffect(() => {
    if (dwollaCustomer) {
      reset({ doingBusinessAs: dwollaCustomer.doingBusinessAs || '' });
    }
  }, [dwollaCustomer, reset]);

  const onSubmit = async (submitData) => {
    callMutationWithToastMessages(editDwollaCustomer, toastMessages, {
      variables: {
        dwollaCustomer: {
          id,
          ...submitData,
        },
      },
      refetchQueries: [namedOperations.Query.getDwollaCustomer],
    });
  };

  return (
    <form
      data-testid="editDwollaCustomerForm"
      onSubmit={handleSubmit(onSubmit)}
      className="clearFix"
    >
      <hr />
      <h3>Change Doing Business As</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="doingBusinessAs"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Doing Business As"
                disabled={loading}
              />
            )}
          />
        </Col>
      </Row>
      <Button
        className="floatRight"
        type="submit"
        disabled={loading}
        loading={submitting}
      >
        Update Field
      </Button>
    </form>
  );
};

export default EditDwollaCustomer;
