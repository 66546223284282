import { Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import {
  InvestorPacketDocument,
  Offering,
  OfferingInput,
  OmcmsClassifiedOfferings,
  useGetInvestorPacketDocumentsLazyQuery,
} from '../../../__generated__';
import OptionGroup from '../../../types/OptionGroup';
import { setFieldProps } from '../../../utils/formHelpers';

type OptionsOrGroups = (Option | OptionGroup)[];

interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors;
  getValues: UseFormGetValues<OfferingInput>;
  offering: Offering;
  omcmsOfferings: OmcmsClassifiedOfferings;
  setValue: UseFormSetValue<OfferingInput>;
}

const OmcmsFields: React.FC<Props> = ({
  control,
  errors,
  getValues,
  offering,
  omcmsOfferings,
  setValue,
}) => {
  const { omcmsId } = getValues();
  const [omcmsOfferingOptions, setOmcmsOfferingOptions] =
    useState<OptionsOrGroups>([]);
  const [investorPacketOptions, setinvestorPacketOptions] = useState<Option[]>(
    [],
  );
  const [signDocumentOptions, setSignDocumentOptions] = useState<Option[]>([]);

  const [
    getInvestorPacketDocuments,
    {
      data: investorPacketDocumentsData,
      loading: loadinginvestorPacketDocuments,
      refetch: refetchInvestorPacketDocuments,
      called: getInvestorPacketDocumentsCalled,
    },
  ] = useGetInvestorPacketDocumentsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  // Build select for OMCMS offerings
  useEffect(() => {
    if (omcmsOfferings) {
      const { available, paired } = omcmsOfferings;

      const newOptions = [];

      if (offering.omcmsId) {
        newOptions.push({
          label: 'Disconnect from OMCMS',
          value: 'Disconnect',
        });
      }

      if (available.length) {
        const availableOptions = available.map((omcmsOffering) => ({
          label: omcmsOffering.name as string,
          value: omcmsOffering.id as string,
        }));
        newOptions.push({
          label: 'Available',
          options: availableOptions,
        });
      }

      if (paired.length) {
        const pairedOptions = paired.map((omcmsOffering) => ({
          label: omcmsOffering.name as string,
          value: omcmsOffering.id as string,
        }));
        newOptions.push({
          label: 'Paired',
          options: pairedOptions,
        });
      }

      setOmcmsOfferingOptions(newOptions);
    }
  }, [omcmsOfferings, offering.omcmsId]);

  // Build select for investor packet
  useEffect(() => {
    // Load investor packets if an OMCMS ID is already saved when the page loads
    if (offering.omcmsId && !getInvestorPacketDocumentsCalled)
      getInvestorPacketDocuments({
        variables: { omcmsOfferingId: offering.omcmsId },
      });

    // Set investor packet options whenever the OMCMS linked offering is changed and new investor packets are loaded
    if (investorPacketDocumentsData) {
      const investorPacketDocuments =
        investorPacketDocumentsData.investorPacketDocuments
          .investorPacketDocuments;

      if (investorPacketDocuments) {
        const newOptions = investorPacketDocuments.map(
          (document: InvestorPacketDocument) => ({
            label: document.name as string,
            value: document.url as string,
          }),
        );

        const newSignDocumentOptions = investorPacketDocuments.map(
          (document: InvestorPacketDocument) => ({
            label: document.name as string,
            value: JSON.stringify({ name: document.name, url: document.url }),
          }),
        );

        setinvestorPacketOptions(newOptions);
        setSignDocumentOptions(newSignDocumentOptions);
      }
    } else {
      setinvestorPacketOptions([]);
      setSignDocumentOptions([]);
    }
  }, [
    investorPacketDocumentsData,
    getInvestorPacketDocuments,
    getInvestorPacketDocumentsCalled,
    offering.omcmsId,
  ]);

  const handleOmcmsIdChange = (val: string) => {
    setValue('investorPacketUrl', null);
    setValue('addendumDocumentUrl', null);
    setValue('signRequiredDocuments', null);
    if (val === 'Disconnect') {
      setinvestorPacketOptions([]);
      setSignDocumentOptions([]);
    } else {
      const variables = {
        omcmsOfferingId: val,
      };
      if (!getInvestorPacketDocumentsCalled)
        getInvestorPacketDocuments({ variables });
      else refetchInvestorPacketDocuments(variables);
    }
  };

  const hideOmcmsDocumentField = [null, undefined, 'Disconnect'].includes(
    omcmsId as string,
  );

  let omcmsDocumentHelperText = '';
  // Loading omcms offering document
  if (loadinginvestorPacketDocuments)
    omcmsDocumentHelperText = 'Loading offering documents...';
  // Loaded, no document found
  else if (omcmsId && omcmsId !== 'Disconnect' && !investorPacketOptions.length)
    omcmsDocumentHelperText = 'No document found';

  return (
    <>
      <hr />
      <h3>Pair with OMCMS</h3>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="omcmsId"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={omcmsOfferingOptions}
                onChange={(val) => {
                  field.onChange(val);
                  handleOmcmsIdChange(val as string);
                }}
                label="OMCMS Offering"
                disabled={loadinginvestorPacketDocuments}
                helperText={omcmsDocumentHelperText}
                helperTextAlwaysVisible
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          {!hideOmcmsDocumentField && (
            <div data-testid="investorPacketSection">
              <Controller
                name="investorPacketUrl"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={investorPacketOptions}
                    onChange={(val) => field.onChange(val)}
                    label="Investor Packet"
                    disabled={loadinginvestorPacketDocuments}
                  />
                )}
              />
            </div>
          )}
        </Col>
      </Row>
      {!hideOmcmsDocumentField && (
        <Row>
          <Col lg={4} md={6}>
            <div data-testid="signRequiredDocumentSection">
              <Controller
                name="signRequiredDocuments"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={signDocumentOptions}
                    onChange={(val) => field.onChange(val)}
                    label="Sign: Required Documents"
                    disabled={loadinginvestorPacketDocuments}
                    multi
                    showMultiLabelsBelow
                  />
                )}
              />
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div data-testid="addendumDocumentSection">
              <Controller
                name="addendumDocumentUrl"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    options={investorPacketOptions}
                    onChange={(val) => field.onChange(val)}
                    label="Addendum Document"
                    disabled={loadinginvestorPacketDocuments}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default OmcmsFields;
