import { Button, EMLoadingIcon, Modal } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';

import {
  OfferingTaxStatus,
  OfferingTaxStatusEstimateStatus,
  OfferingTaxStatusStep,
  useEditOfferingTaxStatusMutation,
  useGetOfferingTaxStatusDocumentSummaryLazyQuery,
} from '../../../__generated__';
import * as modalStyles from '../../../styles/components/Modal.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import {
  offeringTaxStatusEstimateStatus,
  OfferingTaxStatusEstimateStatusKey,
  offeringTaxStatusStep,
  OfferingTaxStatusStepKey,
} from '../constants';

const editOfferingTaxStatusMessages = {
  loading: 'Updating tax status',
  error: 'An error occurred while attempting to updating the tax status',
  success: 'Tax status updated',
};

export interface EditOfferingTaxStatusModalInput {
  newEstimate?: OfferingTaxStatusEstimateStatus;
  newStep?: OfferingTaxStatusStep;
  offeringTaxStatus: OfferingTaxStatus;
}

export interface Props {
  handleCloseModal: () => void;
  params?: EditOfferingTaxStatusModalInput;
}

const EditOfferingTaxStatusModal: React.FC<Props> = ({
  params,
  handleCloseModal,
}) => {
  const { newEstimate, offeringTaxStatus, newStep } = params || {};

  const [getDocumentSummary, documentSummaryState] =
    useGetOfferingTaxStatusDocumentSummaryLazyQuery();
  const [editOfferingTaxStatusMutation, editOfferingTaxStatusMutationState] =
    useEditOfferingTaxStatusMutation({
      variables: {
        offeringTaxStatus: {
          id: offeringTaxStatus?.id as string,
          estimateStatus: newEstimate || undefined,
          step: newStep || undefined,
        },
      },
    });

  const shouldFetchDocumentSummary =
    newEstimate === OfferingTaxStatusEstimateStatus.EstimateUploaded ||
    newStep === OfferingTaxStatusStep.TaxDocumentAvailable;

  useEffect(() => {
    if (offeringTaxStatus?.id && shouldFetchDocumentSummary) {
      getDocumentSummary({
        variables: {
          offeringTaxStatusId: offeringTaxStatus?.id as string,
          documentType: newEstimate ? 'estimate' : '',
        },
      });
    }
  }, [
    getDocumentSummary,
    offeringTaxStatus?.id,
    shouldFetchDocumentSummary,
    newEstimate,
  ]);

  const handleEditOfferingTaxStatus = () => {
    callMutationWithToastMessages(
      editOfferingTaxStatusMutation,
      editOfferingTaxStatusMessages,
    ).then(() => {
      handleCloseModal();
      editOfferingTaxStatusMutationState.reset();
      documentSummaryState.refetch();
    });
  };

  const docInfoItemText = newEstimate ? 'estimates' : 'documents';

  const updateType = newEstimate ? 'Estimate status' : 'Step';
  return (
    <Modal
      data-testid="editOfferingTaxStatusModal"
      open={!!offeringTaxStatus}
      closeButtonText="Cancel"
      onClose={handleCloseModal}
      submitButton={
        <Button
          variant="orange"
          onClick={handleEditOfferingTaxStatus}
          loading={editOfferingTaxStatusMutationState.loading}
        >
          Confirm Change
        </Button>
      }
    >
      {documentSummaryState.loading ? (
        <EMLoadingIcon className={modalStyles.loadingIcon} />
      ) : (
        <>
          <div className={modalStyles.contentContainer}>
            <div className={modalStyles.contentItem}>
              <h5>Selected tax status</h5>
              <p>
                <span data-testid="offeringTaxStatusOffering">
                  <b>Offering Title: </b>
                  {offeringTaxStatus?.offeringTitle}
                </span>
                <br />
                <span data-testid="offeringTaxStatusEntity">
                  <b>Entity Name: </b>
                  {offeringTaxStatus?.entityName}
                </span>
                <br />
                <span data-testid="offeringTaxStatusSponsor">
                  <b>Sponsor Name: </b>
                  {offeringTaxStatus?.sponsorName}
                </span>
                <br />
              </p>
              <h5>{updateType} update</h5>
              <p>
                <span data-testid="offeringTaxStatusFrom">
                  <b>From: </b>
                  {newEstimate
                    ? offeringTaxStatusEstimateStatus[
                        offeringTaxStatus?.estimateStatus as OfferingTaxStatusEstimateStatusKey
                      ] || 'None'
                    : offeringTaxStatusStep[
                        offeringTaxStatus?.step as OfferingTaxStatusStepKey
                      ]}
                </span>
                <br />
                <span data-testid="offeringTaxStatusTo">
                  <b>To: </b>
                  {newEstimate
                    ? offeringTaxStatusEstimateStatus[
                        newEstimate as OfferingTaxStatusEstimateStatusKey
                      ]
                    : offeringTaxStatusStep[
                        newStep as OfferingTaxStatusStepKey
                      ]}
                </span>
                <br />
              </p>
              {shouldFetchDocumentSummary && (
                <>
                  <h5>Document information</h5>
                  <p>
                    <span data-testid="offeringTaxStatusTaxDocuments">
                      <b>Number of uploaded {docInfoItemText}: </b>
                      {
                        documentSummaryState.data
                          ?.offeringTaxStatusDocumentSummary
                          .offeringTaxStatusDocumentSummary?.uploadedTaxDocCount
                      }
                    </span>
                    <br />
                    <span data-testid="offeringTaxStatusInvestorsWithDocs">
                      <b>
                        Number of investors with uploaded {docInfoItemText}:{' '}
                      </b>
                      {
                        documentSummaryState.data
                          ?.offeringTaxStatusDocumentSummary
                          .offeringTaxStatusDocumentSummary
                          ?.countOfUniqueUsersWithUploadedTaxDocs
                      }
                    </span>
                    <br />
                    <span data-testid="offeringTaxStatusTotalInvestors">
                      <b>
                        Total number of investors expecting {docInfoItemText}:{' '}
                      </b>
                      {
                        documentSummaryState.data
                          ?.offeringTaxStatusDocumentSummary
                          .offeringTaxStatusDocumentSummary
                          ?.totalExpectedTaxDocCount
                      }
                    </span>
                    <br />
                  </p>
                  {newEstimate && (
                    <p className="textRed">
                      Please confirm that you have uploaded the Estimates for
                      ALL users in the offering.
                      <br />
                      This action will send a post and email to ALL users in
                      this offering.
                      <br />
                      You cannot undo this action.
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditOfferingTaxStatusModal;
