import { Card, Checkbox, Input, Table } from '@equitymultiple/react-eui';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';

import {
  OfferingList,
  OfferingStatus,
  useGetOfferingsQuery,
} from '../../__generated__';
import { marketplaceRoot } from '../../utils/constants';
import formatCurrency from '../../utils/formatCurrency';
import { capitalize } from '../../utils/stringFormatting';
import * as styles from './Offering.module.scss';
import OfferingActions from './OfferingActions/OfferingActions';
import OfferingRow from './OfferingRow/OfferingRow';

const columnHeaders = [
  'ID',
  'Title',
  'Entity Name',
  'Target Amount',
  'Investors',
  'Interest',
  'Signed',
  'Funded (Posted)',
  'Actions',
];

const getRows = (offerings: OfferingList[]) =>
  offerings.map((offering: OfferingList) => {
    return {
      cells: offering
        ? [
            offering.id,
            <>
              <div className="margin10">
                <Link to={`/offerings/${offering.id}/investments`}>
                  {offering.title}
                </Link>
              </div>
              <span
                className={`${
                  offering.status === 'published' ? 'textGreen' : 'textOrange'
                }`}
              >
                {capitalize(offering.status || '')}
              </span>
              {offering.status === 'published' && (
                <a
                  target="_blank"
                  href={`${marketplaceRoot}/invest/${offering.id}`}
                  rel="noreferrer"
                  className={styles.offeringLink}
                >
                  View In Marketplace
                </a>
              )}
            </>,
            offering.entityName,
            formatCurrency(offering.capitalRequest || 0),
            // These data points are loaded from the investment details query in OfferingRow, pass a blank string temporarily
            '',
            '',
            '',
            '',
            <OfferingActions
              key={offering.id}
              id={offering.id}
              status={offering.status as OfferingStatus}
            />,
          ]
        : [],
    };
  });

const Offerings: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [visibleStatuses, setVisibleStatuses] = useState(['published']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);

  const { data, loading, refetch, fetchMore } = useGetOfferingsQuery({
    variables: {
      filter: debouncedSearchValue,
      status: visibleStatuses.join(','),
      pagination: {
        page,
        pageSize: rowsPerPage,
      },
    },
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  const fetchMoreOfferings = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        status: visibleStatuses.join(','),
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreOfferings(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreOfferings(1, pageSize);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handleVisibleStatusChange = (status: string) => {
    const newVisibleStatuses = [...visibleStatuses];
    if (!newVisibleStatuses.includes(status)) newVisibleStatuses.push(status);
    else newVisibleStatuses.splice(newVisibleStatuses.indexOf(status), 1);
    setVisibleStatuses(newVisibleStatuses);
  };

  const hasOfferings = data?.offerings?.data && data.offerings.data.length > 0;

  return (
    <>
      <h1 data-testid="offeringsHeading">Offerings</h1>
      <Card className="firstChildMarginTop0">
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="offeringSearch"
              label="Search Offerings"
              placeholder="Title, ID or Entity Name"
              onChange={handleSearchValueChange}
              value={searchValue}
            />
          </Col>
          <Col lg={4} md={6}>
            <Checkbox
              id="publishedOfferings"
              label="Published"
              checked={visibleStatuses.includes('published')}
              onChange={() => handleVisibleStatusChange('published')}
              className="margin10"
            />
            <Checkbox
              id="draftOfferings"
              label="Draft"
              checked={visibleStatuses.includes('draft')}
              onChange={() => handleVisibleStatusChange('draft')}
            />
          </Col>
        </Row>
        <Table
          className={styles.table}
          columnHeaders={columnHeaders}
          loading={loading}
          loadingRows={10}
          tableRowComponent={OfferingRow}
          data-testid="table"
          pagination={{
            page: hasOfferings ? page - 1 : 0,
            rowsPerPage,
            onRowsPerPageChange: handleRowsPerPageChange,
            onPageChange: handlePageChange,
            count: data?.offerings?.pageInfo?.count || 0,
          }}
          rows={
            hasOfferings
              ? getRows(data.offerings.data as OfferingList[])
              : [{ cells: ['No offerings found'] }]
          }
        />
      </Card>
    </>
  );
};

export default Offerings;
