import { useApolloClient } from '@apollo/client';
import React from 'react';

// Component which resets the apollo store, clears server-side session, and redirects to EM homepage
const Login: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const client = useApolloClient();
  client.clearStore();

  const returnTo = searchParams.get('returnTo');
  const loginRoute = returnTo
    ? `/auth/login?returnTo=${returnTo}`
    : '/auth/login';

  window.location.replace(loginRoute);

  return null; // Stateless functional component with no render
};

export default Login;
