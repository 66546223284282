import {
  AttachmentList,
  Button,
  Card,
  DateTimePicker,
  FileUploader,
  Input,
  Select,
} from '@equitymultiple/react-eui';
import { Attachment } from '@equitymultiple/react-eui/dist/components/PostCard/Post';
import { yupResolver } from '@hookform/resolvers/yup';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Controller,
  ControllerRenderProps,
  DefaultValues,
  FieldError,
  useForm,
} from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import {
  Closing,
  PostLabel,
  useDeletePostDocumentMutation,
  useGetClosingsByOfferingIdLazyQuery,
  useGetManagersQuery,
  useGetOfferingsForPostFormQuery,
  useUploadPostDocumentMutation,
} from '../../../../__generated__/index';
import RichEditor from '../../../../components/RichEditor/RichEditor';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { postSchema } from './validation';

//type FormValues = CreatePostInput | EditPostInput;
// eslint-disable-next-line
type FormValues = Record<string, any>;

export interface Props {
  attachments?: Attachment[];
  cancelButtonText?: string;
  defaultValues?: DefaultValues<FormValues>;
  onSubmit(submitData: FormValues): unknown;
  submitting?: boolean;
}

const labelOptions = [
  {
    label: 'Investment Update',
    value: PostLabel.InvestmentUpdate,
  },
  {
    label: 'Tax',
    value: PostLabel.Tax,
  },
  {
    label: 'General',
    value: PostLabel.General,
  },
];

const uploadPostMessages = {
  loading: 'Uploading document',
  error: 'An error occurred while uploading a document',
  success: 'Document uploaded',
};

const deletePostMessages = {
  loading: 'Deleting document',
  error: 'An error occurred while deleting a document',
  success: 'Document deleted',
};

const PostForm: React.FC<Props> = ({
  onSubmit,
  submitting = false,
  defaultValues,
  attachments: defaultAttachments = [],
  cancelButtonText = 'Cancel',
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(postSchema),
    defaultValues,
  });
  watch('audience');
  const formValues = getValues();

  const navigate = useNavigate();

  const [deletePostDocument] = useDeletePostDocumentMutation();

  const [uploadPostDocument] = useUploadPostDocumentMutation();

  const {
    data: managers,
    loading: managersLoading,
    error: managersError,
  } = useGetManagersQuery();
  const {
    data: offerings,
    loading: offeringsLoading,
    error: offeringsError,
  } = useGetOfferingsForPostFormQuery();
  const [getClosingsByOfferingId, getClosingsByOfferingIdState] =
    useGetClosingsByOfferingIdLazyQuery();

  useEffect(() => {
    if (offeringsError || managersError || getClosingsByOfferingIdState.error) {
      toast.error('An error occurred while loading the post');
      navigate('/posts');
    }
  });

  const [attachments, setAttachments] = useState(
    defaultAttachments as Attachment[],
  );

  let audience: Closing[] = [];

  if (
    !getClosingsByOfferingIdState.loading &&
    getClosingsByOfferingIdState.data
  ) {
    audience = [
      { name: 'All', id: 'All' },
      ...(getClosingsByOfferingIdState.data?.closings.closings || []),
    ];
  }
  if (
    !audience.length &&
    !getClosingsByOfferingIdState.loading &&
    defaultValues?.offeringId
  ) {
    getClosingsByOfferingId({
      variables: { offeringId: defaultValues?.offeringId as string },
    });
  }

  const peopleInClosing =
    formValues.audience !== 'All'
      ? audience?.find(({ id }) => id === formValues.audience)
          ?.completedInvestments
      : audience?.reduce(
          (prev, closing) => prev + (closing.completedInvestments || 0),
          0,
        );

  const audienceHelperText = getClosingsByOfferingIdState.loading
    ? 'Loading audience'
    : audience.length
      ? 'Total people in closing: ' + peopleInClosing
      : 'Select an offering first';

  let offeringsHelperText = '';
  if (offeringsLoading) offeringsHelperText = 'Loading offerings';
  if (!offeringsLoading && !offerings?.offerings.data.length)
    offeringsHelperText = 'No offerings available';

  let managersHelperText = '';
  if (managersLoading) managersHelperText = 'Loading authors';
  if (!managersLoading && !managers?.managers.data.length)
    managersHelperText = 'No authors available';

  const onOfferingChangeHandler = (
    offeringId: string | number | undefined | null,
  ) => {
    if (offeringId) {
      getClosingsByOfferingId({
        variables: { offeringId: offeringId as string },
      });
      setValue('audience', 'All');
    }
  };

  const handleUpload = (
    field: ControllerRenderProps<FormValues, 'data.attachments'>,
  ) => {
    return (uploadData: File) => {
      callMutationWithToastMessages(uploadPostDocument, uploadPostMessages, {
        variables: { upload: uploadData },
      }).then(({ data }) => {
        setAttachments([
          ...attachments,
          data?.uploadPostDocument as Attachment,
        ]);
        field.onChange([...(field.value || []), data?.uploadPostDocument.id]);
      });
    };
  };

  function handleAttachmentDelete(
    field: ControllerRenderProps<FormValues, 'data.attachments'>,
  ) {
    return (attachment: Attachment) => {
      callMutationWithToastMessages(deletePostDocument, deletePostMessages, {
        variables: { postDocumentId: attachment.id ?? '' },
      }).then((res) => {
        if (!res.errors && !res.data?.deletePostDocument.error) {
          field.onChange(
            field.value?.filter((id: string) => id !== attachment.id),
          );
        }
      });
    };
  }

  useEffect(() => {
    if (defaultValues && !formValues?.title) {
      reset({ ...defaultValues });
    }
  }, [defaultValues, reset, formValues?.title]);

  return (
    <Card className="contentNarrow firstChildMarginTop0">
      <form onSubmit={handleSubmit(onSubmit)} data-testid="postForm">
        <Col>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                {...setFieldProps(field, errors)}
                label="Title"
                value={field.value || ''}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="offeringId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                {...setFieldProps(field, errors)}
                options={
                  offerings?.offerings.data.map((offering) => ({
                    label: offering?.title || '',
                    value: offering?.id || '',
                  })) || []
                }
                onChange={(value) => {
                  field.onChange(value);
                  onOfferingChangeHandler(value as number);
                }}
                disabled={offeringsLoading}
                helperText={offeringsHelperText}
                helperTextAlwaysVisible
                label="Offering"
              />
            )}
          />
        </Col>
        <Row>
          <Col md={6}>
            <Controller
              name="audience"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  {...setFieldProps(field, errors)}
                  options={
                    audience?.map(({ name, completedInvestments, id }) => ({
                      label: `${name}${
                        completedInvestments ? ` - ${completedInvestments}` : ''
                      }`,
                      value: id,
                    })) ?? []
                  }
                  disabled={
                    !formValues.offeringId ||
                    getClosingsByOfferingIdState.loading
                  }
                  label="Audience"
                  helperText={audienceHelperText}
                  helperTextAlwaysVisible
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Controller
              name="postDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  {...setFieldProps(field, errors)}
                  label="Post Date"
                  onChange={(date) => {
                    field.onChange((date as Moment).toISOString());
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Controller
              name="postAuthorId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  {...setFieldProps(field, errors)}
                  options={
                    managers?.managers.data.map((manager) => ({
                      value: manager.id || 0,
                      label: manager.name || '',
                    })) ?? []
                  }
                  disabled={managersLoading}
                  helperTextAlwaysVisible
                  helperText={managersHelperText}
                  label="Author"
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Controller
              name="label"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  multi={true}
                  options={labelOptions}
                  value={field.value as string[]}
                  id="label"
                  label="Labels"
                  errorMessage={(errors.label as FieldError)?.message}
                />
              )}
            />
          </Col>
        </Row>
        <p>Post Content</p>
        <Controller
          name="data.richText.content"
          control={control}
          render={({ field }) => (
            <RichEditor {...field} content={field.value as string} />
          )}
        />
        <br />
        <p>Post Attachments</p>
        <Controller
          name="data.attachments"
          control={control}
          render={({ field }) => (
            <>
              <FileUploader
                onRemove={() => null}
                upload={handleUpload(
                  field as ControllerRenderProps<
                    FormValues,
                    'data.attachments'
                  >,
                )}
                hideSelectedState={true}
              />
              <AttachmentList
                data-testid="attachment"
                onRemove={handleAttachmentDelete(
                  field as ControllerRenderProps<
                    FormValues,
                    'data.attachments'
                  >,
                )}
                attachments={
                  (field.value?.map((id: string) =>
                    attachments.find((attachment) => attachment.id === id),
                  ) || []) as Attachment[]
                }
              />
            </>
          )}
        />

        <Row>
          <Col className="alignItemsCenter" xs={8}>
            <Link type="button" className="textLink" to="/posts">
              {cancelButtonText}
            </Link>
          </Col>
          <Col xs={4}>
            <Button type="submit" className="floatRight" loading={submitting}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </Card>
  );
};

export default PostForm;
