import * as yup from 'yup';

import {
  BusinessType,
  EditAdminAccountDefaultInput,
} from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';
import {
  ageOver18,
  date,
  phone,
  ssn,
  state,
  yupString,
} from '../../../utils/validations';
import { businessClassesAlphabetized } from '../constants';

const industryClasses: string[] = [];
businessClassesAlphabetized.forEach((businessClass) => {
  const industries = businessClass._embedded['industry-classifications'];
  industries.forEach((industry) => industryClasses.push(industry.id));
});

const businessClasses = businessClassesAlphabetized.map(
  (businessClass) => businessClass.id,
);

export const adminAccountDefaultSchema = yup.object<
  SchemaShape<EditAdminAccountDefaultInput>
>({
  address1: yupString.required('Address is required'),
  address2: yupString,
  businessClassification: yupString
    .oneOf(
      businessClasses,
      'Business classification is required and must be a valid value',
    )
    .required('Business classification is required'),
  businessType: yupString
    .oneOf(
      Object.values(BusinessType),
      'Business type is required and must be a valid value',
    )
    .required('Business type is required'),
  city: yupString.required('City is required'),
  dateOfBirth: date.test(ageOver18),
  firstName: yupString.required('First name is required'),
  industryClassification: yupString
    .oneOf(
      industryClasses,
      'Industry classification is required and must be a valid value',
    )
    .required('Industry class is required'),
  lastName: yupString.required('Last name is required'),
  phone: phone,
  postalCode: yupString.required('Postal code is required'),
  ssn,
  state,
  title: yupString.required('Title is required'),
});
