import React from 'react';
import { Link } from 'react-router-dom';

import { PostStatus } from '../../../../__generated__';
import * as styles from '../../../Posts/posts.module.scss';

export interface Props {
  handleDeleteClick: (
    postId: string,
    postTitle: string,
    offeringTitle: string,
  ) => void;
  handlePublishClick: (postId: string, postMembersCount: number) => void;
  offeringTitle?: string | null;
  postId: string;
  postMembersCount?: number | null;
  postStatus?: string | null;
  postTitle?: string | null;
}

const PostActions: React.FC<Props> = ({
  postId,
  postStatus,
  postMembersCount,
  postTitle,
  offeringTitle,
  handlePublishClick,
  handleDeleteClick,
}) => {
  const publishClick = () => handlePublishClick(postId, postMembersCount ?? 0);
  const deleteClick = () =>
    handleDeleteClick(postId, postTitle ?? '', offeringTitle ?? '');

  return (
    <>
      <Link to={`/posts/${postId}/edit`}>
        <button
          type="button"
          className={`textLink ${styles.postAction}`}
          value={postId}
        >
          Edit
        </button>
      </Link>
      <button
        data-testid={`delete-${postId}`}
        type="button"
        className={`textLink  ${styles.postAction} ${styles.deleteAction}`}
        value={postId}
        onClick={deleteClick}
      >
        Delete
      </button>
      {postStatus === PostStatus.Draft && (
        <button
          data-testid={`publish-${postId}`}
          type="button"
          className={`textLink ${styles.postAction}`}
          value={postId}
          onClick={publishClick}
        >
          Publish
        </button>
      )}
    </>
  );
};

export default PostActions;
