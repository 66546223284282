import * as yup from 'yup';

import { InvestmentAccountMoveDwollaBalancesInput } from '../../../../__generated__';
import { SchemaShape } from '../../../../types/yup';
import { yupString } from '../../../../utils/validations';

export const moveDwollaBalanceSchema = yup.object<
  SchemaShape<InvestmentAccountMoveDwollaBalancesInput>
>({
  balance: yup
    .string()
    .test(
      'Is a valid balance',
      'Balance must be greater than $0.00',
      (val) => !val || parseFloat(val) > 0,
    )
    .required('Balance is required'),
  destination: yupString.required('Destination funding source is required'),
});
