import {
  InvestmentTransaction,
  PaymentMethod,
  PaymentTransaction,
  TransactionEventTypeCode,
  TransactionStatus,
} from '__generated__';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

const currentDate = momentTimezone(new Date())
  .tz('US/Central')
  .format('YYYY-MM-DD');

const dwollaCancellationTime = momentTimezone(new Date())
  .tz('US/Central')
  .startOf('day')
  .hour(16)
  .minute(0)
  .format();

export const canCancel = (
  transaction: PaymentTransaction | InvestmentTransaction,
) => {
  const { dwollaTransferCreatedAt, eventType, status, paymentMethod } =
    transaction;

  if (eventType === TransactionEventTypeCode.Contribution) {
    return false;
  }

  if (![TransactionStatus.Pending, TransactionStatus.Draft].includes(status)) {
    return false;
  }

  if (paymentMethod !== PaymentMethod.Ach) {
    return true;
  }

  const createdDate = momentTimezone(new Date(dwollaTransferCreatedAt))
    .tz('US/Central')
    .format('YYYY-MM-DD');
  const createdTime = momentTimezone(new Date(dwollaTransferCreatedAt))
    .tz('US/Central')
    .format();

  const createdToday = moment(createdDate).isSame(currentDate, 'day');
  const createdBeforeCancellationTime = moment(new Date(createdTime)).isBefore(
    dwollaCancellationTime,
  );
  const createdYesterday = moment(createdDate)
    .add(1, 'day')
    .isSame(currentDate, 'day');

  const createdYesterdayAfterCancellationTime = moment(new Date(createdTime))
    .add(1, 'day')
    .isAfter(dwollaCancellationTime);

  return (
    (createdToday && createdBeforeCancellationTime) ||
    (createdYesterday && createdYesterdayAfterCancellationTime)
  );
};
