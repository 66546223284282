import { InvestmentStatus, PaymentMethod } from '__generated__';

export const investmentTableColumnHeaders = [
  'ID',
  'Name',
  'Account',
  'Account Type',
  'Closing',
  'Status',
  'Accreditation Status',
  'Amount',
  'Actions',
];

export const investmentDetailstTableColumnHeaders = [
  'ID',
  'Name',
  'Account',
  'Account Type',
  'Closing',
  'Status',
  'Accreditation Status',
  'Amount',
];

export const investmentTransactionsTableColumnHeaders = [
  'ID',
  'Event Type',
  'Type',
  'Payment Method',
  'Date Posted',
  'Effective Date',
  'Source',
  'Destination',
  'Status',
  'Amount',
  'Actions',
];

export const investmentStatusOptions = {
  waitlist: {
    label: 'Waitlist',
    value: InvestmentStatus.Waitlist,
  },
  interest: {
    label: 'Interest',
    value: InvestmentStatus.Interest,
  },
  signed: {
    label: 'Signed',
    value: InvestmentStatus.Signed,
  },
  funded: {
    label: 'Funded',
    value: InvestmentStatus.Funded,
  },
  cancelled: {
    label: 'Cancelled',
    value: InvestmentStatus.Cancelled,
  },
  dropped: {
    label: 'Dropped',
    value: InvestmentStatus.Dropped,
  },
};

export const defaultPaymentMethods = [
  PaymentMethod.Ach,
  PaymentMethod.Wire,
  PaymentMethod.Check,
  PaymentMethod.Rollover,
  PaymentMethod.Redemption,
];
