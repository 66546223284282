import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';

import { Document } from '../../../__generated__';

export interface Props {
  deleteTransactions: (document: Document) => void;
  document: Document;
}

const TransactionsActions: React.FC<Props> = ({
  deleteTransactions,
  document,
}) => {
  return (
    <div data-testid={`kebab-${document.id}`}>
      <ToggleableTooltip triggerType="kebab" placement="leftStart">
        <button
          onClick={() => deleteTransactions(document)}
          type="button"
          className="textLink"
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default TransactionsActions;
