import { useApolloClient } from '@apollo/client';
import React from 'react';

// Component which resets the apollo store, clears server-side session, and redirects to EM homepage
const Logout: React.FC = () => {
  const client = useApolloClient();
  client.clearStore();
  window.location.replace('/auth/logout');

  return null; // Stateless functional component with no render
};

export default Logout;
