import { DateSelect, Input, Select } from '@equitymultiple/react-eui';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import {
  EditInvestmentAccountInput,
  InvestmentAccount,
} from '../../../../__generated__';
import dwollaBusinessClassifications from '../../../../constants/dwollaBusinessClassifications';
import {
  setDateSelectFieldProps,
  setFieldProps,
} from '../../../../utils/formHelpers';
import { ein, ssn, ssnMaskOptions } from '../../../../utils/masks';
import { sortArrayofObjectsByProperty } from '../../../../utils/sorting';
import {
  businessClassOptions,
  entityBusinessTypeOptions,
  stateOptions,
} from './constants';

interface Props {
  account: InvestmentAccount;
  control: Control<EditInvestmentAccountInput>;
  errors: FieldErrors;
  setValue: UseFormSetValue<EditInvestmentAccountInput>;
  watch: UseFormWatch<EditInvestmentAccountInput>;
}

const getIndustryClass = (businessClassValue: string | null | undefined) => {
  if (businessClassValue) {
    const businessClasses =
      dwollaBusinessClassifications._embedded['business-classifications'];
    const industries = businessClasses.find(
      (businessClass) => businessClass.name === businessClassValue,
    )?._embedded['industry-classifications'];

    if (industries) {
      sortArrayofObjectsByProperty(industries, 'name');

      return industries.map((industry) => ({
        label: industry.name,
        value: industry.name,
      }));
    }

    return [];
  }
  return [];
};

const FieldsEntity: React.FC<Props> = ({
  account,
  control,
  errors,
  setValue,
  watch,
}) => {
  const businessClass = watch('businessClass');

  return (
    <div className="firstChildMarginTop0">
      <h3>Entity Information</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="entityName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Entity Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="businessType"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={entityBusinessTypeOptions}
                onChange={(e) => {
                  field.onChange(e);
                }}
                label="Business Type"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="ein"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="EIN"
                mask={ein}
                inputMaskOptions={ssnMaskOptions}
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="jurisdictionOfBusinessRegistration"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={stateOptions}
                onChange={(e) => {
                  field.onChange(e);
                }}
                label="State Where Organized"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="dateOfFormation"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Formation"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="dateOfRegistration"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="State Business Registration Date"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="businessClass"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={businessClassOptions}
                onChange={(e) => {
                  field.onChange(e);
                  setValue('industryClass', null);
                }}
                label="Business"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="industryClass"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={getIndustryClass(businessClass)}
                onChange={(e) => {
                  field.onChange(e);
                }}
                label="Industry"
                helperText={
                  businessClass ? '' : 'Select a business to view options'
                }
              />
            )}
          />
        </Col>
      </Row>

      <h3>Entity Address</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Address" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Address 2 (Optional)"
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="City" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="region"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                options={stateOptions}
                onChange={(e) => {
                  field.onChange(e);
                }}
                label="State"
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="postalCode"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Postal Code"
                mask="9{5}"
              />
            )}
          />
        </Col>
      </Row>

      <h3>Representative Information</h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="user.firstName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="First Name" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="user.lastName"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Last Name" />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="officerTitle"
            control={control}
            render={({ field }) => (
              <Input {...setFieldProps(field, errors)} label="Title" />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            value={account.user?.email || ''}
            label="Email"
            id="email"
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="user.phone"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="Phone"
                mask="(999) 999-9999 [ext 999999]"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Controller
            name="user.ssn"
            control={control}
            render={({ field }) => (
              <Input
                {...setFieldProps(field, errors)}
                label="SSN"
                mask={ssn}
                inputMaskOptions={ssnMaskOptions}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="user.dob"
            control={control}
            render={({ field }) => (
              <DateSelect
                {...setDateSelectFieldProps(field, errors)}
                label="Date of Birth"
              />
            )}
          />
        </Col>
        <Col lg={4} md={6}>
          <Input
            label="Date Completed"
            id="dateCompleted"
            value={
              account.completedAt
                ? moment(new Date(account.completedAt)).format('MM/DD/YYYY')
                : ''
            }
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default FieldsEntity;
