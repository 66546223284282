/**
 * Utility method for checking basic equality between two arrays of either
 * `string`s or `number`s. Ordering of elements is not used for equality,
 * this is simply checking that the content in the two arrays are the same.
 *
 * @param arrayA
 * @param arrayB
 * @returns {boolean}
 * - `false`: if the lengths of the arrays or content does not match
 * - `true`: if both the length and the elements are the same between arrays
 */
const arrayEqual = (
  arrayA: (string | number)[],
  arrayB: (string | number)[],
): boolean => {
  let isEqual = false;
  if (arrayA.length === arrayB.length) {
    isEqual = arrayA.every((filter) => {
      return arrayB.includes(filter);
    });
  }

  return isEqual;
};

export default arrayEqual;
