import { useGetAdminAccountDefaultQuery } from '__generated__';
import { EMLoadingIcon } from '@equitymultiple/react-eui';
import React from 'react';
import * as loadingIconStyles from 'styles/components/EMLoadingIcon.module.scss';

import { DwollaConfigurationForm } from './DwollaConfigurationForm';

export const DwollaConfiguration: React.FC = () => {
  const { data, loading } = useGetAdminAccountDefaultQuery();

  return (
    <>
      <h2 data-testid="dwollaConfigurationHeading" className="marginTop0">
        EM Entity Account Default Values
      </h2>
      {loading ? (
        <EMLoadingIcon
          data-testid="emLoadingIcon"
          className={loadingIconStyles.cardLoader}
        />
      ) : (
        <DwollaConfigurationForm data={data} />
      )}
    </>
  );
};
