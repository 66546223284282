import {
  DateSelect,
  Input,
  Select,
  Table,
  TableRow,
} from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { numberMaskOptions } from 'utils/masks';
import { yupString } from 'utils/validations';
import { getPaymentMethodOptions } from 'views/Offerings/OfferingInvestments/helpers';

import {
  PaymentMethod,
  PaymentTransaction,
  TransactionStatus,
} from '../../../__generated__';
import { eventTypes, transactionStatuses, transactionTypes } from './constants';
import TransactionActions from './TransactionActions';
import * as styles from './TransactionsTable.module.scss';

interface Props {
  dirtyTransaction?: PaymentTransaction;
  handleTransactionEdit: (transaction: PaymentTransaction) => void;
  loading: boolean;
  offeringId: string;
  transactions?: PaymentTransaction[];
}

const columnHeaders = [
  'Status',
  'Effective Date',
  'User',
  'Payment Method',
  'Event Type',
  'Transaction Type',
  'Amount',
  'Actions',
];

const paymentMethodOptions = getPaymentMethodOptions();

const amountSchema = yupString
  .required('Invalid amount')
  .test(
    'Amount must be present and greater that 0',
    'Invalid amount',
    (val) => {
      return parseFloat(val) > 0 && !isNaN(parseFloat(val));
    },
  );

const TransactionsTable: React.FC<Props> = ({
  dirtyTransaction,
  handleTransactionEdit,
  loading,
  offeringId,
  transactions,
}) => {
  const [errors, setErrors] = useState(null);
  const [editingAmount, setEditingAmount] = useState(false);

  const handleAmountChange = (transaction, amountValue) => {
    const parsedAmount = amountValue ? parseFloat(amountValue) : amountValue;
    const amount =
      parsedAmount !== transaction.amount ? parsedAmount : amountValue;

    handleTransactionEdit({ ...transaction, amount });
    if (!editingAmount) setEditingAmount(true);
    amountSchema
      .validate(amountValue)
      .then(() => errors && setErrors(null))
      .catch((error) => setErrors(error.errors[0]));
  };

  useEffect(() => {
    if (!dirtyTransaction && editingAmount) {
      setEditingAmount(false);
    }
  }, [dirtyTransaction, editingAmount]);

  useEffect(() => {
    if (!dirtyTransaction && errors) {
      setErrors(false);
    }
  }, [dirtyTransaction, errors]);

  const getCellsFromTransaction = (transaction: PaymentTransaction) => {
    const canEdit =
      !dirtyTransaction || transaction.id === dirtyTransaction?.id;
    const editingTransactionAmount = canEdit && editingAmount;
    const disabled = !canEdit || transaction.status !== TransactionStatus.Draft;

    return [
      transaction.status && (
        <span className={styles[transaction.status]}>
          {transactionStatuses[transaction.status]}
        </span>
      ),
      <DateSelect
        compact
        disabled={disabled}
        key={`${transaction.id}effectiveDate`}
        id={`${transaction.id}effectiveDate`}
        className={styles.tableRowInput}
        value={
          transaction.effectiveDate
            ? moment(new Date(transaction.effectiveDate))
                .utc()
                .format('MM/DD/YYYY')
            : ''
        }
        onChange={(value) => {
          const newDate = value
            ? moment(new Date(value)).format('YYYY-MM-DD')
            : '';
          if (newDate !== transaction.effectiveDate) {
            handleTransactionEdit({ ...transaction, effectiveDate: newDate });
          }
        }}
      />,
      `${transaction.user?.firstName} ${transaction.user?.lastName}`,
      <Select
        compact
        key={`${transaction.id}paymentMethodSelect`}
        id={`${transaction.id}paymentMethodSelect`}
        data-testid={`${transaction.id}paymentMethodSelect`}
        disabled={disabled}
        value={transaction.paymentMethod}
        className={styles.tableRowInput}
        onChange={(value) => {
          if (value !== transaction.paymentMethod) {
            handleTransactionEdit({
              ...transaction,
              paymentMethod: value as PaymentMethod,
            });
          }
        }}
        options={paymentMethodOptions}
      />,
      transaction.eventType && eventTypes[transaction.eventType],
      transaction.type && transactionTypes[transaction.type],
      <Input
        compact
        allowDecimal
        dollarMask
        className={styles.tableRowInput}
        id={`${transaction.id}amount`}
        key={`${transaction.id}amount`}
        inputMaskOptions={numberMaskOptions}
        value={
          editingTransactionAmount
            ? transaction.amount
            : transaction.amount.toFixed(2)
        }
        onBlur={() => setEditingAmount(false)}
        label="Amount"
        disabled={disabled}
        onChange={(event) =>
          handleAmountChange(transaction, event.target.value)
        }
        errorMessage={canEdit && errors}
      />,
    ];
  };

  const getCollapsibleCell = (collapsedTransactions: PaymentTransaction[]) => {
    return (
      <div className={styles.collapsedRowsWrap}>
        {collapsedTransactions.map((transaction: PaymentTransaction) => {
          const row = {
            cells: getCellsFromTransaction(transaction),
            uuid: `${transaction.id}row`,
          };
          return (
            <TableRow
              row={row}
              key={transaction.id}
              totalRow={false}
              hasCollapsibleSibling
            />
          );
        })}
      </div>
    );
  };

  const getRows = () => {
    const eventRelationships: Record<string, PaymentTransaction[]> = {};

    let hasCollapsibleSibling = false;

    transactions.forEach((transaction) => {
      const eventRelationship = transaction.eventRelationships;

      if (eventRelationship) {
        if (!eventRelationships[eventRelationship])
          eventRelationships[eventRelationship] = [transaction];
        else {
          eventRelationships[eventRelationship].push(transaction);
          hasCollapsibleSibling = true;
        }
      }
    });

    return Object.values(eventRelationships).map((eventTransactions) => {
      const firstTransaction = eventTransactions[0];
      const hasMultipleTransactions = eventTransactions.length > 1;
      let collapsedTransactions = null;

      if (hasMultipleTransactions) {
        collapsedTransactions = [...eventTransactions];
        collapsedTransactions.splice(0, 1);
      }

      const collapsibleCell = hasMultipleTransactions
        ? getCollapsibleCell(collapsedTransactions as PaymentTransaction[])
        : null;

      const showActions = ![TransactionStatus.Cancelled].includes(
        firstTransaction.status as TransactionStatus,
      );

      return {
        cells: [
          ...getCellsFromTransaction(firstTransaction),
          showActions && (
            <TransactionActions
              key="actions"
              offeringId={offeringId}
              transactions={eventTransactions}
            />
          ),
        ],
        collapsibleCell,
        hasCollapsibleSibling,
        uuid: `${firstTransaction.id}row`,
      };
    });
  };

  return (
    <Table
      className={styles.transactionsTable}
      columnHeaders={columnHeaders}
      rows={
        transactions?.length
          ? getRows()
          : [{ cells: ['No transactions found'] }]
      }
      loading={loading}
      loadingRows={10}
    />
  );
};

export default TransactionsTable;
