import * as yup from 'yup';

import { EditPeriodInput, PeriodSelectionType } from '../../../__generated__';
import { SchemaShape } from '../../../types/yup';
import { yupString } from '../../../utils/validations';

export const periodAllocationSchema = yup.object<SchemaShape<EditPeriodInput>>({
  selection: yup.object({
    type: yupString.required('Allocation type is required'),
    value: yup
      .array()
      .nullable()
      .when('type', {
        is: PeriodSelectionType.Closing,
        then: () =>
          yup.array(yupString).min(1, 'Please select at least one closing'),
      })
      .when('type', {
        is: PeriodSelectionType.Investment,
        then: () =>
          yup.array(yupString).min(1, 'Please select at least one investor'),
      }),
  }),
});
