import {
  Button,
  PostCard,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
} from '@equitymultiple/react-eui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Post,
  useGetOfferingsForPostsListQuery,
  useGetPostsQuery,
} from '../../__generated__';
import * as styles from '../Posts/posts.module.scss';
import PostAuthors from './PostAuthors/PostAuthors';
import DeletePostModal from './Posts/DeletePostModal/DeletePostModal';
import PostActions from './Posts/PostActions/PostActions';
import PostFilters from './Posts/PostFilters/PostFilters';
import PublishPostModal from './Posts/PublishPostModal/PublishPostModal';

const scrollTimeoutMs = 100;
const scrollDepthBuffer = 100;
const defaultPagination = {
  page: 1,
  pageSize: 20,
};

type PublishPostState = {
  postData: null | {
    postId: string;
    postMembersCount: number;
  };
};

type DeletePostState = {
  postData: null | {
    offeringTitle: string;
    postId: string;
    postTitle: string;
  };
};

const getTabIndexFromHash = (hash: string) => {
  switch (hash) {
    case '#post_authors':
      return 1;
    default:
      return 0;
  }
};

const Posts: React.FC = () => {
  const { hash } = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const offeringId = useQuery().get('offering_id');
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [labelFilters, setLabelFilters] = useState<string[]>([]);
  const [offeringFilters, setOfferingFilters] = useState<number[]>(
    offeringId ? [parseInt(offeringId)] : [],
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [publishPostState, setPublishPostState] = useState<PublishPostState>({
    postData: null,
  });
  const [deletePostState, setDeletePostState] = useState<DeletePostState>({
    postData: null,
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const pageSize = defaultPagination.pageSize;

  const {
    data: postsData,
    loading: postsLoading,
    refetch,
    fetchMore,
  } = useGetPostsQuery({
    variables: {
      pagination: {
        page: pageNumber,
        pageSize,
      },
      status: statusFilters,
      labels: labelFilters,
      offeringIds: offeringFilters,
    },
  });

  const { data: offeringsData, loading: offeringsLoading } =
    useGetOfferingsForPostsListQuery();

  const posts = postsData?.posts?.data;
  const postsCount = postsData?.posts.pageInfo.count ?? 0;
  const hasMore = postsCount > pageNumber * pageSize;
  const offerings = offeringsData?.offerings?.data;
  const loading = postsLoading || offeringsLoading;

  const navigate = useNavigate();

  const handleAddPost = () => {
    navigate('/posts/new');
  };

  const throttle = useRef<boolean>(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  const currentScrollPosition = () => {
    return window.innerHeight + window.scrollY + scrollDepthBuffer;
  };

  const atBottom = useCallback(() => {
    return currentScrollPosition() > document.body.scrollHeight;
  }, []);

  const shouldFetch = useCallback(() => {
    return atBottom() && hasMore;
  }, [atBottom, hasMore]);

  const handleScrollEvent = useCallback(() => {
    if (shouldFetch()) {
      const newPageNumber = pageNumber + 1;
      setFetching(true);
      setPageNumber(newPageNumber);
      fetchMore({
        variables: {
          pagination: {
            page: newPageNumber,
            pageSize,
          },
        },
      }).finally(() => {
        throttle.current = false;
        setFetching(false);
      });
    } else {
      throttle.current = false;
    }
  }, [fetchMore, pageNumber, shouldFetch, pageSize]);

  const scrollEventListener = useCallback(() => {
    if (!throttle.current) {
      throttle.current = true;
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        handleScrollEvent();
      }, scrollTimeoutMs);
    }
  }, [handleScrollEvent]);

  useEffect(() => {
    const newTabIndex = getTabIndexFromHash(hash);
    setActiveTabIndex(newTabIndex);
  }, [hash]);

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener);
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      window.removeEventListener('scroll', scrollEventListener);
    };
  }, [scrollEventListener]);

  useEffect(() => {
    if (!fetching && throttle.current) {
      throttle.current = false;
    }
  }, [fetching]);

  const resetScrollPosition = () => {
    if (atBottom()) {
      const newScrollValue =
        document.body.scrollHeight - window.innerHeight - scrollDepthBuffer;
      window.scrollTo(0, newScrollValue);
    }
  };

  const handleRefetchForFilterChange = (
    refetchQuery: Record<string, (string | number)[]>,
  ) => {
    throttle.current = true;
    setFetching(true);
    setPageNumber(defaultPagination.page);
    refetch({
      ...refetchQuery,
      pagination: defaultPagination,
    }).finally(() => {
      resetScrollPosition();
      setFetching(false);
    });
  };

  const handleStatusFilterChange = (newFilters: string[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ status: newFilters });
      setStatusFilters([...newFilters]);
    }
  };

  const handleLabelFilterChange = (newFilters: string[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ labels: newFilters });
      setLabelFilters([...newFilters]);
    }
  };

  const handleOfferingFilterChange = (newFilters: number[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ offeringIds: newFilters });
      setOfferingFilters([...newFilters]);
    }
  };

  const handlePublishClick = (postId: string, postMembersCount: number) => {
    setPublishPostState({ postData: { postId, postMembersCount } });
  };

  const handleDeleteClick = (
    postId: string,
    postTitle: string,
    offeringTitle: string,
  ) => {
    setDeletePostState({ postData: { postId, postTitle, offeringTitle } });
  };

  const handleClosePublishModal = () => {
    setPublishPostState({ postData: null });
  };

  const handleCloseDeleteModal = () => {
    setDeletePostState({ postData: null });
  };

  const getLoadingPost = () => {
    return (
      <PostCard data-testid="loadingPost" key={'loading-post'} loading={true} />
    );
  };

  const getPostActions = (post: Post) => {
    return (
      <PostActions
        postId={post.id}
        postStatus={post.status}
        postMembersCount={post.postMembersCount}
        postTitle={post.title}
        offeringTitle={post.offering?.title}
        handlePublishClick={handlePublishClick}
        handleDeleteClick={handleDeleteClick}
      />
    );
  };

  const getRows = () => {
    if (loading) {
      return getLoadingPost();
    } else if (posts && posts.length > 0) {
      const postRows = (posts as Post[]).map((post) => {
        return (
          <PostCard
            data-testid={`post-${post.id}`}
            key={post.id}
            post={post}
            showStatus={true}
            postActions={getPostActions(post)}
          />
        );
      });

      // Relies on `fetching` due to a known issue with apollo client not
      // updating the `loading` state on `fetchMore` calls.
      if (fetching) {
        postRows.push(getLoadingPost());
      }

      return postRows;
    } else {
      return <h2 data-testid="noPostsFound">No posts found</h2>;
    }
  };

  const setPath = (tab: string) => {
    if (tab === 'postAuthors') {
      navigate('/posts#post_authors');
    } else if (tab === 'posts') {
      navigate('/posts');
    }
  };

  return (
    <>
      <h1>Posts</h1>
      <Tabs
        value={activeTabIndex}
        onChange={(_, tabIndex) => {
          let path = 'posts';
          if (tabIndex === 1) path = 'postAuthors';
          setPath(path);
          setActiveTabIndex(tabIndex as number);
        }}
      >
        <TabsList>
          <Tab data-testid="tabPosts">Posts</Tab>
          <Tab data-testid="tabPostAuthors">Post Authors</Tab>
        </TabsList>
        <TabPanel value={0}>
          <PublishPostModal
            postData={publishPostState.postData}
            handleCloseModal={handleClosePublishModal}
          />
          <DeletePostModal
            postData={deletePostState.postData}
            handleCloseModal={handleCloseDeleteModal}
          />
          <Row>
            <Col className={styles.postHeadingContainer}>
              <Button data-testid="newPostButton" onClick={handleAddPost}>
                New Post
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              {!offeringsLoading && offerings && (
                <PostFilters
                  statusFilters={statusFilters}
                  handleStatusFilterChange={handleStatusFilterChange}
                  labelFilters={labelFilters}
                  handleLabelFilterChange={handleLabelFilterChange}
                  offerings={offerings}
                  offeringFilters={offeringFilters}
                  handleOfferingFilterChange={handleOfferingFilterChange}
                />
              )}
            </Col>
            <Col data-testid="postsWrapper" lg={8}>
              {getRows()}
            </Col>
          </Row>
        </TabPanel>
        <TabPanel value={1}>
          <PostAuthors />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default Posts;
