import React from 'react';
import { Container } from 'react-grid-system';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import * as styles from './Layout.module.scss';
import SessionExpiration from './SessionExpiration/SessionExpiration';
import UserAuth from './UserAuth/UserAuth';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <UserAuth>
      <div className={styles.app}>
        <SessionExpiration />
        <Header />
        <div>
          <Container
            className={styles.contentContainer}
            data-testid="contentContainer"
          >
            {children}
          </Container>
        </div>
        <Footer />
      </div>
    </UserAuth>
  );
};

export default Layout;
