import formatCurrency from 'utils/formatCurrency';
import { yupBool, yupString } from 'utils/validations';
import * as yup from 'yup';

import {
  defaultInvestmentIncrement,
  defaultMinimumInvestment,
} from '../../../../constants';

export const reinvestmentElectionSchema = (
  originalAmount,
  investmentIncrement = defaultInvestmentIncrement,
  minimumInvestment = defaultMinimumInvestment,
) =>
  yup.object().shape({
    reinvestmentOption: yupString.required('Reinvestment option is required'),
    reinvestInterest: yupBool.required(
      'Interest reinvestment selection is required',
    ),
    reinvestmentAmount: yupString.when('reinvestmentOption', {
      is: (val: string | null) => val !== null && val === 'partial',
      then: () =>
        yupString
          .required('Reinvestment amount is required')
          .test(
            'must be greater than minimumInvestment',
            `Reinvestment amount must be greater than ${formatCurrency(
              minimumInvestment,
            )}`,
            (val) =>
              parseFloat(val) >= minimumInvestment && !isNaN(parseFloat(val)),
          )
          .test(
            'must be multiple of investmentIncrement',
            `Reinvestment amount must be a multiple of ${formatCurrency(
              investmentIncrement,
            )}`,
            (val) =>
              !isNaN(parseFloat(val)) &&
              parseFloat(val) % investmentIncrement === 0,
          )
          .test(
            'reinvestment amount can not exceed original investment amount',
            `Reinvestment amount can not exceed original investment of ${formatCurrency(
              originalAmount,
            )}`,
            (val) =>
              !isNaN(parseFloat(val)) &&
              parseFloat(val) <= parseFloat(originalAmount),
          ),
    }),
  });
