import { Button, Card, Input, Table } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';

import { Sponsor, useGetSponsorsQuery } from '../../__generated__';
import * as styles from '../Sponsors/sponsors.module.scss';
import DeleteSponsorModal from './DeleteSponsorModal/DeleteSponsorModal';
import SponsorsActions from './SponsorActions/SponsorsActions';

const Sponsors: React.FC = () => {
  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);

  const [searchValue, setSearchValue] = useState('');

  const { data, loading } = useGetSponsorsQuery();

  const hasSponsors = data?.sponsors.data;

  const navigate = useNavigate();

  const columnHeaders = [
    'Sponsor Name',
    'Number of Offerings',
    'Created On',
    'Last Modified On',
    'Actions',
  ];

  const setSelectedSponsorState = (sponsor: Sponsor | null) => {
    setSelectedSponsor(sponsor);
  };

  const handleAddSponsor = () => {
    navigate('/sponsors/new');
  };

  const getRows = (sponsors: Array<Sponsor>) => {
    const filteredSponsors = searchValue
      ? sponsors.filter((sponsor) => {
          const lowerCaseName = sponsor.name?.toLowerCase();
          return lowerCaseName?.includes(searchValue.toLowerCase());
        })
      : sponsors;

    return filteredSponsors.map((sponsor) => {
      const { name, offeringCount, createdAt, updatedAt, id } = sponsor;

      return {
        cells: [
          name,
          offeringCount,
          moment.utc(new Date(createdAt)).format('M/D/YYYY'),
          moment.utc(new Date(updatedAt)).format('M/D/YYYY'),
          <SponsorsActions
            key={id}
            setSelectedSponsor={setSelectedSponsor}
            sponsor={sponsor as Sponsor | null}
          />,
        ],
      };
    });
  };

  return (
    <>
      <DeleteSponsorModal
        setSelectedSponsorState={setSelectedSponsorState}
        selectedSponsor={selectedSponsor}
      />
      <h1 data-testid="sponsorsHeading">Sponsors</h1>
      <Card>
        <Row>
          <Col lg={4} md={6}>
            <Input
              id="sponsorSearch"
              label="Search"
              placeholder="Sponsor Name"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </Col>
          <Col lg={8} md={6}>
            <Button
              className={`floatRight margin20 ${styles.addSponsorButton}`}
              onClick={handleAddSponsor}
            >
              Add Sponsor
            </Button>
          </Col>
        </Row>
        <Table
          loading={loading}
          loadingRows={10}
          columnHeaders={columnHeaders}
          rows={
            hasSponsors
              ? getRows(data?.sponsors.data)
              : [{ cells: ['No sponsors found'] }]
          }
        />
      </Card>
    </>
  );
};

export default Sponsors;
