import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreateSponsorInput,
  CreateSponsorPayload,
  namedOperations,
  useCreateSponsorMutation,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import SponsorForm from '../SponsorForm/SponsorForm';
import { sponsorSchema } from '../SponsorForm/validation';

const messages = {
  loading: 'Adding sponsor',
  error: 'An error occurred while adding the sponsor',
  success: 'Sponsor added',
};

const AddSponsor: React.FC = () => {
  const [createSponsor, createSponsorState] = useCreateSponsorMutation();

  const loading = createSponsorState.loading;

  const navigate = useNavigate();

  const onSubmit = async (submitData: CreateSponsorInput) => {
    const isValid = await sponsorSchema.isValid(submitData);

    if (isValid) {
      const { data } = await callMutationWithToastMessages(
        createSponsor,
        messages,
        {
          variables: {
            sponsor: submitData,
          },
          refetchQueries: [namedOperations.Query.getSponsors],
        },
      );
      const { sponsor } = data?.createSponsor as CreateSponsorPayload;

      if (sponsor) {
        navigate('/sponsors');
      }
    }
  };

  return (
    <>
      <h2 className="contentNarrow">Add Sponsor</h2>
      <SponsorForm onSubmit={onSubmit} loading={loading} />
    </>
  );
};

export default AddSponsor;
