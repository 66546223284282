import { Button, Input, Table } from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { getAccreditationStatusColor } from 'views/Offerings/OfferingInvestments/helpers';

import {
  AccreditationStatus,
  InvestmentAccount,
  InvestmentAccountStatus,
  useGetInvestmentAccountsQuery,
} from '../../../__generated__';
import useDebounce from '../../../hooks/useDebounce';
import { capitalize, enumValueToLabel } from '../../../utils/stringFormatting';
import { businessTypeLabels, investmentAccountTypeLabels } from '../constants';
import InvestmentAccountActions from './InvestmentAccountActions';

const columnHeaders = [
  'ID',
  'Status',
  'Account Number',
  'Entity Name',
  'Account Type',
  'Business Type',
  'User',
  'Dwolla Customer',
  'Verified Bank Accounts',
  'Accreditation Status',
  'Actions',
];

const getAccountStatusColor = (
  status: InvestmentAccountStatus | null | undefined,
) => {
  if (status === 'flagged') return 'textRed';
  if (['approved', 'created'].includes(status as string)) return 'textGreen';
  if (['pending', 'in_progress', 'to_do'].includes(status as string))
    return 'textOrange';
  return '';
};

const getDwollaStatusColor = (status: string | null | undefined) => {
  if (status === 'suspended') return 'textRed';
  if (status === 'verified') return 'textGreen';
  return 'textOrange';
};

const getRows = (accounts: (InvestmentAccount | null | undefined)[]) =>
  accounts.map((account: InvestmentAccount | null | undefined) => ({
    cells: account
      ? [
          account.id,
          <div
            key="accountStatus"
            data-testid="accountStatus"
            className={getAccountStatusColor(
              account.needsUpdate
                ? InvestmentAccountStatus.Flagged
                : account.status,
            )}
          >
            {account.needsUpdate
              ? 'Flagged'
              : account.status
                ? capitalize(account.status)
                : null}
          </div>,
          ,
          account.referenceId,
          account.entityName,
          account.type ? investmentAccountTypeLabels[account.type] : null,
          account.businessType
            ? businessTypeLabels[account.businessType]
            : account.entityType,
          <div key="userInfo">
            <div className="margin10">{`${account.user?.firstName} ${account.user?.lastName}`}</div>
            {account.user?.email}
          </div>,
          <div key="dwollaCustomer">
            <div
              data-testid="dwollaStatus"
              className={`margin10 ${getDwollaStatusColor(
                account.dwollaCustomer?.status,
              )}`}
            >
              {capitalize(account.dwollaCustomer?.status || '')}
            </div>
            {account.dwollaCustomer?.email}
          </div>,
          account.dwollaCustomer?.bankAccountsCount,
          <div
            key="accreditationStatus"
            data-testid="accreditationStatus"
            className={getAccreditationStatusColor(account.accreditationStatus)}
          >
            {enumValueToLabel(AccreditationStatus, account.accreditationStatus)}
          </div>,
          <InvestmentAccountActions
            id={account.id as string}
            email={account.user?.email as string}
            dwollaCustomerStatus={account.dwollaCustomer?.status}
            hasVerifiedBankAccount={
              account.dwollaCustomer?.bankAccountsCount
                ? account.dwollaCustomer?.bankAccountsCount > 0
                : false
            }
            key="actions"
          />,
        ]
      : [],
  }));

const InvestmentAccounts: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);

  const { data, loading, refetch, fetchMore } = useGetInvestmentAccountsQuery({
    variables: {
      filter: debouncedSearchValue,
      pagination: {
        page,
        pageSize: rowsPerPage,
      },
    },
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  const fetchMoreAccounts = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreAccounts(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreAccounts(1, pageSize);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const hasAccounts =
    data?.investmentAccounts?.data && data.investmentAccounts.data.length > 0;

  return (
    <>
      <h2 data-testid="headingInvestment">Investment Accounts</h2>
      <Row>
        <Col lg={4} md={6}>
          <Input
            id="accountsSearch"
            label="Search"
            placeholder="First name, email, or entity name"
            onChange={handleSearchValueChange}
            value={searchValue}
          />
        </Col>
        <Col
          lg={8}
          md={6}
          className={`alignItemsCenter justifyContentEnd ${'styles.newAccountButton'}`}
        >
          <Button
            wrapper={<Link to="/investment_accounts/new" />}
            className="alignItemsCenter"
            data-testid="newAccountButton"
          >
            New Account
          </Button>
        </Col>
      </Row>
      <Table
        data-testid="table"
        columnHeaders={columnHeaders}
        loading={loading}
        loadingRows={10}
        pagination={{
          // MUI expects 0 based index, API expects 1 based
          // MUI throws out of range error if page > 1 and there are no accounts
          page: hasAccounts ? page - 1 : 0,
          rowsPerPage,
          onRowsPerPageChange: handleRowsPerPageChange,
          onPageChange: handlePageChange,
          count: data?.investmentAccounts?.pageInfo?.count || 0,
        }}
        rows={
          hasAccounts
            ? getRows(data?.investmentAccounts?.data as InvestmentAccount[])
            : [{ cells: ['No accounts found'] }]
        }
      />
    </>
  );
};

export default InvestmentAccounts;
