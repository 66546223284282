import { Select, Tooltip } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import {
  OfferingInput,
  OfferingStatus,
  useGetSegmentAudiencesQuery,
} from '../../../__generated__';
import { setFieldProps } from '../../../utils/formHelpers';
import * as styles from './Offering.module.scss';

interface Props {
  control: Control<OfferingInput>;
  errors: FieldErrors;
  getValues: UseFormGetValues<OfferingInput>;
  offeringStatus: OfferingStatus;
  setValue: UseFormSetValue<OfferingInput>;
}

const SegmentAudiencesFields: React.FC<Props> = ({
  control,
  errors,
  offeringStatus,
  getValues,
  setValue,
}) => {
  let helperText = '';

  const { hidden, segmentAudienceIds } = getValues();
  const [segmentAudiencesOptions, setSegmentAudiencesOptions] = useState<
    Option[]
  >([]);
  const { data, loading } = useGetSegmentAudiencesQuery();

  const disabled = loading || hidden;

  if (loading) helperText = 'Loading audiences...';
  else if (hidden) helperText = 'This offering is set to Hidden.';

  useEffect(() => {
    if (data && data?.segmentAudiences?.data) {
      const willBeDisabled = (id: string): boolean => {
        return (
          offeringStatus === OfferingStatus.Published &&
          (segmentAudienceIds.includes(id) ||
            (segmentAudienceIds.length === 1 &&
              segmentAudienceIds.includes('-1')))
        );
      };

      const newSegmentAudiencesOptions = data?.segmentAudiences?.data.map(
        (segmentAudience) => ({
          label: `${segmentAudience.name} | ${segmentAudience.userCount} users`,
          value: segmentAudience.id,
          isDisabled: willBeDisabled(segmentAudience.id),
        }),
      );

      newSegmentAudiencesOptions.unshift({
        label: 'All',
        value: '-1',
        isDisabled: false,
      });

      setSegmentAudiencesOptions(newSegmentAudiencesOptions);
    }
  }, [data, offeringStatus, segmentAudienceIds]);

  const handleAudienceChange = (audiences: string[]) => {
    if (audiences) {
      let newAudiences = [...audiences];
      if (newAudiences.length > 1 && newAudiences.indexOf('-1') > -1) {
        if (offeringStatus === OfferingStatus.Published) {
          newAudiences = [...new Set(audiences.concat(segmentAudienceIds))];
        }
        if (newAudiences.indexOf('-1') === newAudiences.length - 1) {
          newAudiences.splice(0, newAudiences.length - 1);
        } else {
          newAudiences.splice(newAudiences.indexOf('-1'), 1);
        }
      }
      setValue('segmentAudienceIds', newAudiences);
    } else {
      setValue('segmentAudienceIds', ['-1']);
    }
  };

  return (
    <>
      <hr />
      <h3>
        Segment Audiences
        {offeringStatus === OfferingStatus.Published && (
          <Tooltip
            tooltipContent="Audiences cannot be limited on published offerings"
            className={`inlineTooltip ${styles.audienceTooltip}`}
            infoIcon
          />
        )}
      </h3>
      <Row>
        <Col lg={4} md={6}>
          <Controller
            name="segmentAudienceIds"
            control={control}
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                id="segmentAudienceIds"
                options={segmentAudiencesOptions}
                onChange={handleAudienceChange}
                label="Segment Audiences"
                disabled={disabled}
                helperText={helperText}
                helperTextAlwaysVisible={disabled}
                multi
                showMultiLabelsBelow
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default SegmentAudiencesFields;
