import { BusinessTypeOption } from 'views/Accounts/constants';

import {
  BusinessType,
  ProfessionalLetterStatus,
} from '../../../../__generated__';
import countries from '../../../../constants/countries';
import dwollaBusinessClassifications from '../../../../constants/dwollaBusinessClassifications';
import states from '../../../../constants/states';
import { sortArrayofObjectsByProperty } from '../../../../utils/sorting';

export const countryOptions = countries.map((country) => ({
  label: country.name,
  value: country.name,
}));

export const stateOptions = states.map((state) => ({
  label: state.name,
  value: state.code,
}));

export const residenceStatuses = [
  'U.S. Citizen',
  'U.S. Permanent Resident',
  'U.S. Resident',
  'Other',
];

export const residenceStatusOptions = residenceStatuses.map((status) => ({
  label: status,
  value: status,
}));

export const businessClassesAlphabetized = sortArrayofObjectsByProperty(
  dwollaBusinessClassifications._embedded['business-classifications'],
  'name',
);

export const businessClassOptions = businessClassesAlphabetized.map(
  (businessClass) => ({
    label: businessClass.name,
    value: businessClass.name,
  }),
);

export const jointSubTypes = [
  'Joint Tenancy with Right of Survivorship',
  'Community Property',
  'Tenants in Common',
];

export const jointSubTypeOptions = jointSubTypes.map((subType) => ({
  label: subType,
  value: subType,
}));

export const entityBusinessTypeOptions: BusinessTypeOption[] = [
  {
    label: 'LLC',
    value: BusinessType.Llc,
  },
  {
    label: 'Partnership',
    value: BusinessType.Partnership,
  },
  {
    label: 'Sole Proprietorship',
    value: BusinessType.Soleproprietorship,
  },
  {
    label: 'Corporation',
    value: BusinessType.Corporation,
  },
  {
    label: 'Trust Irrevocable',
    value: BusinessType.TrustIrrevocable,
  },
  {
    label: 'Trust Revocable',
    value: BusinessType.TrustRevocable,
  },
];

interface ProfessionalLetterStatusOption {
  label: string;
  value: ProfessionalLetterStatus;
}

export const professionalLetterStatusOptions: ProfessionalLetterStatusOption[] =
  [
    {
      label: 'Verified',
      value: ProfessionalLetterStatus.Verified,
    },
    {
      label: 'Not Verified',
      value: ProfessionalLetterStatus.NotVerified,
    },
    {
      label: 'In Review',
      value: ProfessionalLetterStatus.InReview,
    },
    {
      label: 'Not Uploaded',
      value: ProfessionalLetterStatus.NotUploaded,
    },
  ];
