import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  handleDeletePeriod: (offeringId: string, periodId: string) => void;
  offeringId: string;
  periodId: string;
}

const PaymentPeriodActions: React.FC<Props> = ({
  offeringId,
  periodId,
  handleDeletePeriod,
}) => {
  return (
    <div data-testid={`periodActions-${periodId}`}>
      <ToggleableTooltip
        data-testid={`tooltip-box-${periodId}`}
        triggerType="kebab"
        placement="leftStart"
      >
        <Link to={`/payments/schedule/${offeringId}/periods/${periodId}/edit`}>
          Edit
        </Link>
        <button
          data-testid={`delete-${periodId}`}
          type="button"
          className="textLink textLeft"
          onClick={() => handleDeletePeriod(offeringId, periodId)}
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default PaymentPeriodActions;
