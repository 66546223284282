import { Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import { Attachment } from '@equitymultiple/react-eui/dist/components/PostCard/Post';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EditPostInput,
  useEditPostMutation,
  useGetPostQuery,
} from '../../../../__generated__';
import * as loadingIconStyles from '../../../../styles/components/EMLoadingIcon.module.scss';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import PostForm from '../PostForm/PostForm';
import { postSchema } from '../PostForm/validation';

const editPostMessages = {
  loading: 'Updating',
  error: 'An error occurred while updating Post',
  success: 'Post Updated',
};

const EditPost: React.FC = () => {
  const { id: postId } = useParams();
  const navigate = useNavigate();

  const getPostQueryState = useGetPostQuery({
    variables: { id: postId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      getPostQueryState.error ||
      getPostQueryState.data?.post.error?.message
    ) {
      toast.error('Post not found');
      navigate('/posts');
    }
  });

  const post = getPostQueryState.data?.post.post;

  const [editPostMutation, editPostMutationState] = useEditPostMutation();

  const submitting = editPostMutationState.loading;

  const defaultValues = {
    audience: post?.audience,
    data: {
      attachments: post?.data?.attachments?.map((attachment) => attachment?.id),
      richText: { content: post?.data?.richText?.content },
    },
    label: post?.label,
    offeringId: post?.offering?.id,
    postDate: post?.postDate,
    title: post?.title,
    postAuthorId: post?.postAuthorId,
  } as EditPostInput;

  const onSubmit = async (submitData: EditPostInput) => {
    const isValid = await postSchema.isValid(submitData);
    submitData.id = postId;

    if (isValid) {
      const { data } = await callMutationWithToastMessages(
        editPostMutation,
        editPostMessages,
        {
          variables: {
            post: submitData,
          },
        },
      );

      if (data?.editPost.post) {
        navigate('/posts');
      }
    }
  };

  return (
    <>
      <h2 className="contentNarrow">
        {getPostQueryState.loading ? 'Edit Post' : `Edit Post - ${post?.title}`}
      </h2>

      {getPostQueryState.loading ? (
        <Card className="contentNarrow firstChildMarginTop0">
          <EMLoadingIcon
            data-testid="emLoadingIcon"
            className={loadingIconStyles.cardLoader}
          />
        </Card>
      ) : (
        <PostForm
          onSubmit={onSubmit}
          submitting={submitting}
          defaultValues={defaultValues}
          cancelButtonText="Back"
          attachments={(post?.data?.attachments || []) as Attachment[]}
        />
      )}
    </>
  );
};

export default EditPost;
