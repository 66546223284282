import { Button, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import {
  useVerifyBankAccountMutation,
  VerifyBankAccountInput,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../../utils/formHelpers';
import { verifyBankAccountSchema } from '../validation';

const toastMessages = {
  loading: 'Verifying bank account',
  error: 'An error occurred while attempting to verify the bank account',
  success: 'Bank account verified',
};

export interface Props {
  bankAccountId: string;
  dwollaCustomerId: string;
}

const defaultValues = {
  amount1: '',
  amount2: '',
};

const VerifyBankAccount: React.FC<Props> = ({
  dwollaCustomerId,
  bankAccountId,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues<VerifyBankAccountInput>>({
    mode: 'onBlur',
    resolver: yupResolver(verifyBankAccountSchema),
    defaultValues,
  });

  const [verifyBankAccount, verifyBankAccountState] =
    useVerifyBankAccountMutation();

  const loading = verifyBankAccountState.loading;

  const onSubmit = async (submitData: Partial<VerifyBankAccountInput>) => {
    await callMutationWithToastMessages(verifyBankAccount, toastMessages, {
      variables: {
        dwollaCustomerId: dwollaCustomerId as string,
        bankAccountInput: {
          id: bankAccountId as string,
          amount1: submitData.amount1 as string,
          amount2: submitData.amount2 as string,
        },
      },
    });
    reset();
  };

  return (
    <Container>
      <h4 data-testid="verifyBankAccountHeading">Verify Bank Account</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="verifyBankAccountForm"
      >
        <Row>
          <Col lg={6} md={6}>
            <Controller
              name="amount1"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  mask="0.09"
                  label="Amount 1"
                />
              )}
            />
          </Col>
          <Col lg={6} md={6}>
            <Controller
              name="amount2"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  mask="0.09"
                  label="Amount 2"
                />
              )}
            />
          </Col>
        </Row>
        <Button className="floatRight" type="submit" loading={loading}>
          Verify
        </Button>
      </form>
    </Container>
  );
};

export default VerifyBankAccount;
