import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useGetActiveUserQuery, User } from '../../../../__generated__';
import { dataClipsUrl } from '../../../../utils/constants';
import * as styles from './MainNavigation.module.scss';

export const navLinks = [
  ['Offerings', '/'],
  ['Sponsors', '/sponsors'],
  ['Users', '/users'],
  ['Posts', '/posts'],
  ['Accounts', '/accounts'],
  ['Payments', '/payments'],
  ['Tax Tracker', '/tax_tracker'],
  ['Configuration', '/configuration'],
];

const MainNavigation: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { data } = useGetActiveUserQuery();
  //@ts-expect-error code works
  const user = data?.activeUser as User;

  const toggleMenuOpen = () => setMenuOpen(!menuOpen);

  const renderNavLink = (label: string, route: string) => {
    return (
      <NavLink
        to={route}
        className={(linkState) => (linkState?.isActive ? styles.active : '')}
        onClick={toggleMenuOpen}
        key={label}
      >
        {label}
      </NavLink>
    );
  };

  return (
    <div className={styles.mainNavigation}>
      <button
        className={styles.navToggle}
        type="button"
        aria-label="Show Navigation Menu"
        onClick={toggleMenuOpen}
        data-testid="navToggle"
      >
        <div className={styles.navToggleBar} />
        <div className={styles.navToggleBar} />
        <div className={styles.navToggleBar} />
      </button>
      <div
        className={`${styles.menuWrap} ${menuOpen ? styles.open : ''}`}
        data-testid="menuWrap"
      >
        <nav>
          <button
            className={styles.navClose}
            type="button"
            aria-label="Close"
            onClick={toggleMenuOpen}
            data-testid="navClose"
          />
          {navLinks.map((link) => renderNavLink(link[0], link[1]))}
          <a href={dataClipsUrl} onClick={toggleMenuOpen} key="dataclips">
            Dataclips
          </a>
        </nav>
        {user && (
          <div className={styles.user}>
            <div className={styles.email}>{user.email}</div>
            <Link to="/logout" onClick={toggleMenuOpen}>
              Log Out
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainNavigation;
