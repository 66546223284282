import { Button, Checkbox, Modal } from '@equitymultiple/react-eui';
import React, { useState } from 'react';

import { usePublishPostMutation } from '../../../../__generated__';
import * as modalStyles from '../../../../styles/components/Modal.module.scss';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';

const publishPostMessages = {
  loading: 'Publishing',
  error: 'An error occurred while attempting to publish the post',
  success: 'Post published',
};

export interface Props {
  handleCloseModal: () => void;
  postData: {
    postId: string;
    postMembersCount: number;
  } | null;
}

type FormState = {
  emailNotification: boolean;
  emailWithContent: boolean;
};

const PublishPostModal: React.FC<Props> = ({ postData, handleCloseModal }) => {
  const [formState, setFormState] = useState<FormState>({
    emailNotification: true,
    emailWithContent: true,
  });

  const [publishPostMutation, publishPostMutationState] =
    usePublishPostMutation({
      variables: {
        publishPostInput: {
          id: postData?.postId as string,
          emailNotification: formState.emailNotification,
          emailWithContent: formState.emailWithContent,
        },
      },
    });

  const handlePublishPost = () => {
    callMutationWithToastMessages(
      publishPostMutation,
      publishPostMessages,
    ).then(() => {
      handleCloseModal();
      publishPostMutationState.reset();
    });
  };

  const handleNotificationChange = () => {
    const newFormState = {
      ...formState,
      emailNotification: !formState.emailNotification,
    };
    if (!newFormState.emailNotification) {
      newFormState.emailWithContent = false;
    }
    setFormState(newFormState);
  };

  const handleWithContentChange = () => {
    const newFormState = {
      ...formState,
      emailWithContent: !formState.emailWithContent,
    };
    setFormState(newFormState);
  };

  return (
    <Modal
      onClose={handleCloseModal}
      data-testid="publishPostModal"
      open={postData !== null}
      closeButtonText="Cancel"
      submitButton={
        <Button
          onClick={handlePublishPost}
          loading={publishPostMutationState.loading}
        >
          Publish
        </Button>
      }
    >
      <div className={modalStyles.contentContainer}>
        <Checkbox
          id="emailNotifcation"
          label="Notify users about this update"
          checked={formState.emailNotification}
          onChange={handleNotificationChange}
          className={modalStyles.formInput}
        />
        <Checkbox
          id="emailWithContent"
          label="Show update text in email"
          checked={formState.emailWithContent}
          onChange={handleWithContentChange}
          disabled={!formState.emailNotification}
          className={modalStyles.formInput}
        />
        <div className={modalStyles.note} data-testid="publishNote">
          <span>
            Note, checking this will send an email to{' '}
            <span className="bold">{postData?.postMembersCount}</span> users.
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default PublishPostModal;
