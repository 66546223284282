import { Document } from '__generated__';
import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';

import * as styles from './DocumentsActions.module.scss';

export interface Props {
  deleteDocuments: (document: Document) => void;
  document: Document;
  restorePreviousDocuments: (document: Document) => void;
}

const DocumentsActions: React.FC<Props> = ({
  deleteDocuments,
  document,
  restorePreviousDocuments,
}) => {
  return (
    <div data-testid={`kebab-${document.id}`}>
      <ToggleableTooltip
        triggerType="kebab"
        placement="leftStart"
        tooltipBoxClassName={styles.tooltipBox}
      >
        <button
          onClick={() => deleteDocuments(document)}
          type="button"
          className={`${styles.actionButton} textLink`}
        >
          Delete Documents
        </button>
        <button
          onClick={() => restorePreviousDocuments(document)}
          type="button"
          className={`${styles.actionButton} textLink`}
          disabled={!document.meta?.replace}
        >
          Restore Previous Version
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default DocumentsActions;
