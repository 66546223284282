import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreatePostAuthorInput,
  CreatePostAuthorPayload,
  namedOperations,
  useCreatePostAuthorMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import PostAuthorForm from '../PostAuthorForm/PostAuthorForm';
import { postAuthorSchema } from '../PostAuthorForm/validation';

const messages = {
  loading: 'Adding post author',
  error: 'An error occurred while adding the post author',
  success: 'Post author added',
};

const AddPostAuthor: React.FC = () => {
  const [createPostAuthor, createPostAuthorState] =
    useCreatePostAuthorMutation();

  const submitting = createPostAuthorState.loading;

  const navigate = useNavigate();

  const onSubmit = async (submitData: CreatePostAuthorInput) => {
    const isValid = await postAuthorSchema.isValid(submitData);

    if (isValid) {
      const { data } = await callMutationWithToastMessages(
        createPostAuthor,
        messages,
        {
          variables: {
            postAuthor: submitData,
          },
          refetchQueries: [namedOperations.Query.getPostAuthors],
        },
      );
      const { postAuthor } = data?.createPostAuthor as CreatePostAuthorPayload;

      if (postAuthor) {
        navigate('/posts#post_authors');
      }
    }
  };

  return (
    <>
      <h2 className="contentNarrow">Add Post Author</h2>
      <PostAuthorForm onSubmit={onSubmit} submitting={submitting} />
    </>
  );
};

export default AddPostAuthor;
