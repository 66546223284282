import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import { DwollaCustomer } from '../../../__generated__';

export interface Props {
  dwollaCustomer: DwollaCustomer | null;
}

const AdminAccountActions: React.FC<Props> = ({ dwollaCustomer }) => {
  return (
    <div data-testid={`kebab-${dwollaCustomer?.id}`}>
      <ToggleableTooltip
        data-testid="toggleable-tooltip"
        triggerType="kebab"
        placement="leftStart"
      >
        <Link to={`/accounts/${dwollaCustomer?.id}/edit`}>Edit</Link>
        {dwollaCustomer.status === 'verified' &&
          dwollaCustomer.bankAccountsCount > 0 && (
            <Link to={`/accounts/${dwollaCustomer?.id}/balance`}>
              Move Dwolla Balance
            </Link>
          )}
      </ToggleableTooltip>
    </div>
  );
};

export default AdminAccountActions;
