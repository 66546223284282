import {
  Closing,
  Investment,
  InvestmentStatus,
  useEditInvestmentMutation,
} from '__generated__';
import { Button, Input, Modal } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import * as modalStyles from 'styles/components/Modal.module.scss';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import cloneObject from 'utils/cloneObject';
import { capitalize } from 'utils/stringFormatting';
import { formatClosingName } from 'views/Offerings/helpers';

import { formatAccountName } from '../helpers';
import { EditableInvestmentValues } from '../types';

const messages = {
  loading: 'Updating investment',
  error: 'An error occurred while updating the investment',
  success: 'Investment updated',
};

interface Props {
  closings: Closing[];
  handleClose: () => void;
  investment?: Investment;
  isOpen: boolean;
  newInvestmentValues: EditableInvestmentValues;
  offeringId: string;
}

const InvestmentEditModal = ({
  closings,
  handleClose,
  investment,
  isOpen,
  newInvestmentValues,
  offeringId,
}: Props) => {
  const [dropReason, setDropReason] = useState(investment?.dropReason || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [editInvestment, editInvestmentState] = useEditInvestmentMutation();
  const { loading } = editInvestmentState;

  const getCopy = () => {
    const { user } = investment;
    const userName = `${user.firstName} ${user.lastName}`;
    const currentClosing = closings.find(
      (closing) => closing.id === investment.closingId,
    );

    let updatedField = 'investmentAccountId';
    if (newInvestmentValues.closingId) updatedField = 'closingId';
    else if (newInvestmentValues.status) updatedField = 'status';

    switch (updatedField) {
      case 'investmentAccountId':
        return (
          <p>
            Are you sure you want to update the account of {userName}'s
            investment (ID: {investment.id}) from{' '}
            <strong>{formatAccountName(investment.investmentAccount)}</strong>{' '}
            to{' '}
            <strong>
              {formatAccountName(
                investment.user.investmentAccounts.data.find(
                  (account) =>
                    account.id === newInvestmentValues.investmentAccountId,
                ),
              )}
            </strong>
            ?
          </p>
        );

      case 'closingId':
        return (
          <p>
            Are you sure you want to update the closing of {userName}'s
            investment (ID: {investment.id}) from{' '}
            <strong>{formatClosingName(currentClosing)}</strong> to{' '}
            <strong>
              {formatClosingName(
                closings.find(
                  (closing) => closing.id === newInvestmentValues.closingId,
                ),
              )}
            </strong>
            ?
          </p>
        );

      case 'status':
        return (
          <p>
            Are you sure you want to update the status of {userName}'s
            investment (ID: {investment.id}) from{' '}
            <strong>{capitalize(investment.status)}</strong> to{' '}
            <strong>{capitalize(newInvestmentValues.status)}</strong>?
          </p>
        );

      default:
        return '';
    }
  };

  const handleOnChange = (value) => {
    if (value?.length && errorMessage.length) {
      setErrorMessage('');
    }
    setDropReason(value);
  };

  const handleSubmit = () => {
    const submitValues = cloneObject(newInvestmentValues);
    delete submitValues.closingName;

    if (submitValues.status === InvestmentStatus.Dropped) {
      if (!dropReason?.length) {
        setErrorMessage('Drop reason is required');
        return;
      }
      submitValues.dropReason = dropReason;
    }

    callMutationWithToastMessages(editInvestment, messages, {
      variables: {
        investment: {
          ...submitValues,
          offeringId,
        },
      },
    }).then(() => {
      handleClose();
    });
  };

  return isOpen ? (
    <Modal
      open
      title={<h6>Confirm Investment Changes</h6>}
      onClose={handleClose}
      closeButtonText="Cancel"
      submitButton={
        <Button onClick={handleSubmit} loading={loading}>
          Yes, Update Investment
        </Button>
      }
      data-testid="confirmationModal"
    >
      <div className={modalStyles.contentContainer}>
        <div className={modalStyles.contentItem} data-testid="modalCopy">
          {getCopy()}

          {newInvestmentValues.status === InvestmentStatus.Dropped && (
            <Input
              id="drop-reason-input"
              label="Drop Reason"
              value={dropReason}
              onChange={(e) => handleOnChange(e.currentTarget.value)}
              errorMessage={errorMessage}
            />
          )}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default InvestmentEditModal;
