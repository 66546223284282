import { Button, Modal } from '@equitymultiple/react-eui';
import React from 'react';

import { Sponsor, useDeleteSponsorMutation } from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

export interface Props {
  selectedSponsor: Sponsor | null;
  setSelectedSponsorState: (sponsor: Sponsor | null) => void;
}

const messages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the sponsor',
  success: 'Sponsor Deleted',
};

const DeleteSponsorModal: React.FC<Props> = ({
  setSelectedSponsorState,
  selectedSponsor,
}) => {
  const sponsorId = selectedSponsor?.id as string;
  const [deleteSponsorMutation, deleteSponsorMutationState] =
    useDeleteSponsorMutation({
      variables: {
        sponsorId,
      },
      update(cache) {
        deleteObjectFromCache(cache, 'Sponsor', sponsorId);
      },
    });

  const handleDeleteSponsor = () => {
    callMutationWithToastMessages(deleteSponsorMutation, messages).then(() => {
      setSelectedSponsorState(null);
      deleteSponsorMutationState.reset();
    });
  };

  const hideDeleteModal = () => setSelectedSponsorState(null);

  return (
    <Modal
      data-testid="deleteSponsorModal"
      open={selectedSponsor !== null}
      title={<h6>Are you sure you want to delete this sponsor?</h6>}
      closeButtonText="Cancel"
      submitButton={
        <Button variant="orange" onClick={() => handleDeleteSponsor()}>
          Yes, Delete It
        </Button>
      }
      onClose={hideDeleteModal}
    >
      <p className="margin30">
        <strong>Name:</strong>{' '}
        {selectedSponsor && (selectedSponsor as Sponsor).name}
        <br />
        <strong>Number of Offerings: </strong>{' '}
        {/* span is for testing purposes */}
        <span>
          {selectedSponsor && (selectedSponsor as Sponsor).offeringCount}
        </span>
      </p>
    </Modal>
  );
};

export default DeleteSponsorModal;
