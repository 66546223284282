import {
  AllocationInput,
  CreatePeriodInput,
  EditPeriodInput,
  TransactionTypeCode,
} from '../../__generated__';

const transactionCodeMap = {
  [TransactionTypeCode.InterestEarned]: 'TT-0001',
  [TransactionTypeCode.AccruedInterest]: 'TT-0002',
  [TransactionTypeCode.PrincipalPayment]: 'TT-0003',
  [TransactionTypeCode.PeakEquity]: 'TT-0004',
  [TransactionTypeCode.Dividend]: 'TT-0005',
  [TransactionTypeCode.ReturnOfCapital]: 'TT-0006',
  [TransactionTypeCode.ReferralBonus]: 'TT-0007',
  [TransactionTypeCode.AssetManagementFee]: 'TT-0008',
  [TransactionTypeCode.InterestParticipation]: 'TT-0009',
  [TransactionTypeCode.Investment]: 'TT-0010',
  [TransactionTypeCode.WithdrawAccount]: 'TT-0011',
  [TransactionTypeCode.FundAccount]: 'TT-0012',
  [TransactionTypeCode.Refund]: 'TT-0013',
  [TransactionTypeCode.EarningsCorrection]: 'TT-0014',
  [TransactionTypeCode.CapitalCall]: 'TT-0015',
  [TransactionTypeCode.ProfitParticipation]: 'TT-0016',
  [TransactionTypeCode.AdditionalInterest]: 'TT-0017',
  [TransactionTypeCode.DividendReinvestment]: 'TT-0018',
  [TransactionTypeCode.OriginationFee]: 'TT-0019',
  [TransactionTypeCode.AdministrativeExpenseReimbursement]: 'TT-0020',
  [TransactionTypeCode.CapitalCorrection]: 'TT-0021',
};

export const transactionTypeToCode = (type: string): string => {
  return transactionCodeMap[type as TransactionTypeCode] ?? '';
};

const normalizeAllocations = (allocations: AllocationInput[]) => {
  return allocations.map((allocation) => ({
    ...allocation,
    type: transactionTypeToCode(allocation.type),
  }));
};

export const normalizeCreatePeriodInput = (
  period: CreatePeriodInput,
): CreatePeriodInput => {
  return {
    ...period,
    allocations: normalizeAllocations(period.allocations),
  };
};

export const normalizeEditPeriodInput = (
  period: EditPeriodInput,
): EditPeriodInput => {
  return {
    ...period,
    allocations: normalizeAllocations(period.allocations),
  };
};
