import { AccreditationStatus, InvestmentAccount } from '__generated__';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';

import { defaultPaymentMethods } from './constants';

export const formatAccountName = (account: InvestmentAccount) =>
  `${account.entityName} (${investmentAccountTypeLabels[account.type]})`;

export const getAccreditationStatusColor = (
  status: AccreditationStatus | null | undefined,
) => {
  switch (status) {
    case AccreditationStatus.NotStarted:
      return 'textGray';
    case AccreditationStatus.Verified:
      return 'textGreen';
    case AccreditationStatus.Incomplete:
    case AccreditationStatus.Pending:
      return 'textOrange';
    case AccreditationStatus.Expired:
    case AccreditationStatus.NotVerified:
      return 'textRed';
    default:
      return '';
  }
};

export function getPaymentMethodOptions(
  paymentMethods = defaultPaymentMethods,
) {
  return paymentMethods.map((paymentMethod) => ({
    label: paymentMethod.toUpperCase(),
    value: paymentMethod,
  }));
}
