import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EditPeriodInput,
  namedOperations,
  useEditPeriodMutation,
  useGetPeriodQuery,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import PeriodForm from '../PeriodForm/PeriodForm';
import { periodSchema } from '../PeriodForm/validation';
import { normalizeEditPeriodInput } from '../transactionCodeMapper';

const editPeriodMessages = {
  loading: 'Updating period',
  error: 'An error occurred while updating a period',
  success: 'Period updated',
};

const EditPeriod: React.FC = () => {
  const { offeringId, periodId } = useParams();
  const navigate = useNavigate();
  const [editPeriod, editPeriodState] = useEditPeriodMutation();

  const getPeriodQueryState = useGetPeriodQuery({
    variables: {
      periodId: periodId as string,
      offeringId: offeringId as string,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      getPeriodQueryState.error ||
      getPeriodQueryState.data?.period.error?.message
    ) {
      toast.error('Period not found');
      navigate(`/payments/schedule/${offeringId}/transactions`);
    }
  });
  const onSubmit = async (submitData: EditPeriodInput) => {
    const isValid = await periodSchema.isValid(submitData);

    if (isValid) {
      const normalizedInput = normalizeEditPeriodInput(submitData);

      const { data } = await callMutationWithToastMessages(
        editPeriod,
        editPeriodMessages,
        {
          variables: {
            period: normalizedInput,
          },
          // This is needed because editPeriod returns Period while this returns PaymentPeriod which are different objects
          refetchQueries: [namedOperations.Query.getPaymentPeriods],
        },
      );

      const id = data?.editPeriod.period?.id;
      if (id) {
        navigate(`/payments/schedule/${offeringId}/periods/${id}/allocate`);
      }
    }
  };

  const period = getPeriodQueryState.data?.period.period;
  const dataLoading = getPeriodQueryState.loading;

  const defaultValues = {
    allocations: period?.allocations.map((allocation) => ({
      amount: allocation.amount,
      method: allocation.method,
      type: allocation.type.toString(),
      value: allocation.value,
    })),
    endDate: period?.endDate,
    id: periodId,
    offeringId: offeringId,
    selection: {
      type: period?.selection?.type,
      value: period?.selection?.value,
    },
    startDate: period?.startDate,
  };

  const headingText = (title?: string | null): string => {
    return period && title
      ? `Edit Period (${period.endDate}) - ${title}`
      : 'Edit Period';
  };

  return (
    <PeriodForm
      headingText={headingText}
      onSubmit={onSubmit}
      dataSubmitting={editPeriodState.loading}
      dataLoading={dataLoading}
      defaultValues={defaultValues}
    />
  );
};

export default EditPeriod;
