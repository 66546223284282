import { buildOptions } from 'utils/formHelpers';

import { OfferingList, PostLabel, PostStatus } from '../../../../__generated__';

export const getStatusOptions = (): { label: string; value: string }[] => {
  return buildOptions(Object.entries(PostStatus));
};

export const getLabelOptions = (): { label: string; value: string }[] => {
  return buildOptions(Object.entries(PostLabel));
};

export const getOfferingOptions = (
  offeringList: OfferingList[],
): { label: string; value: number }[] => {
  const options: { label: string; uuid: string; value: number }[] = [];
  offeringList.map((offering) => {
    options.push({
      label: `${offering.entityName?.toUpperCase()} - ${offering.title}`,
      value: parseInt(offering.id),
      uuid: offering.id,
    });
  });
  return options;
};
