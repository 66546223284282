import { EnumLike } from '../types/EnumLike';

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const snakeToCamelCase = (string: string): string =>
  string
    .toLowerCase()
    .replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());

export const snakeToTitleCase = (string: string): string =>
  string
    .split('_')
    .map((element) =>
      element[0] ? element[0].toUpperCase() + element.substr(1) : null,
    )
    .join(' ');

export const capitalToTitleCase = (string: string): string =>
  string.split(/(?=[A-Z])/).join(' ');

/**
 * Utility function for mapping enums with simple capital case keys to a space
 * -delimited string based on provided value (e.g. `RedBlueRed` =>
 * "Red Blue Red"). Enums with keys containing any non-alpha characters will
 * not provide any special formatting for those characters. Allows for falsy
 * values to skip type checking but will throw an error if the supplied value
 * is not falsy and does not exist within the enum.
 * @param enumType enum-like type
 * @param enumValue value to format
 * @returns formatted label
 */
export const enumValueToLabel = <T extends EnumLike>(
  enumType: T,
  enumValue?: string | null,
): string => {
  let formattedLabel = '';
  if (enumValue) {
    const [keyToFormat] = Object.keys(enumType).filter((enumKey) => {
      return enumType[enumKey] === enumValue;
    });
    if (keyToFormat) {
      formattedLabel = capitalToTitleCase(keyToFormat);
    } else {
      console.warn(`Value "${enumValue}" not present in enum`);
      return enumValue;
    }
  }

  return formattedLabel;
};
