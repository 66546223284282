import { PaymentMethod } from '__generated__';
import { yupString } from 'utils/validations';
import * as yup from 'yup';

export const investmentSchema = yup.object().shape({
  user: yup.mixed().required('User is required'),
  closingId: yupString.required('Closing is required'),
  amount: yupString.required('Amount is required'),
  investmentAccountId: yupString.required('Investment account is required'),
  bankAccountId: yupString.when('paymentMethod', {
    is: PaymentMethod.Ach,
    then: () => yupString.required('Bank account is required'),
  }),
});
