import { Button } from '@equitymultiple/react-eui';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Col, Row } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';

import {
  useEditSettingMutation,
  useGetSettingsQuery,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import DraggablePillar from './DraggablePillar';
import * as styles from './InvestPage.module.scss';

const messages = {
  loading: 'Updating pillar order',
  error: 'An error occurred while attempting to update the pillar order',
  success: 'Pillar order updated',
};

const settingName = 'pillar_order';

const InvestPage: React.FC = () => {
  const [pillars, setPillars] = useState(['keep', 'earn', 'grow']);

  const { data, loading } = useGetSettingsQuery({
    variables: {
      name: settingName,
    },
  });

  const [editSetting, editSettingState] = useEditSettingMutation();
  const { loading: saving } = editSettingState;

  useEffect(() => {
    if (data?.settings.settings?.[0]) {
      const savedPillarString = data.settings.settings[0].value;
      const savedPillars = savedPillarString?.split(',');
      if (savedPillars) setPillars(savedPillars);
    }
  }, [data]);

  const reorderPillar = useCallback((dragIndex: number, hoverIndex: number) => {
    setPillars((prevPillars) => {
      const newPillars = [...prevPillars];
      // Remove item that was dragged
      newPillars.splice(dragIndex, 1);
      // Reinsert it at the new index
      newPillars.splice(hoverIndex, 0, prevPillars[dragIndex]);
      return newPillars;
    });
  }, []);

  const savePillarOrder = () => {
    const pillarOrder = pillars.join();
    callMutationWithToastMessages(editSetting, messages, {
      variables: {
        setting: {
          id: data?.settings?.settings?.[0]?.id || '',
          value: pillarOrder,
        },
      },
    });
  };

  return (
    <>
      <h2 data-testid="investPageHeading" className="marginTop0">
        Invest Page
      </h2>
      <h4>Pillar Order</h4>
      <p className="margin40">Drag and drop to reorder pillars</p>
      <Row>
        <Col lg={6}>
          {loading ? (
            <div data-testid="skeletonLoaders">
              <Skeleton className={styles.loadingSkeleton} />
              <Skeleton className={styles.loadingSkeleton} />
              <Skeleton className={styles.loadingSkeleton} />
            </div>
          ) : (
            <div className={styles.pillarWrap} data-testid="pillarWrap">
              <DndProvider backend={HTML5Backend}>
                {pillars.map((pillar, index) => (
                  <DraggablePillar
                    pillar={pillar}
                    key={pillar}
                    index={index}
                    reorderPillar={reorderPillar}
                  />
                ))}
              </DndProvider>
            </div>
          )}
          <Button
            onClick={savePillarOrder}
            disabled={loading}
            loading={saving}
            className={styles.submitButton}
          >
            Save Pillar Order
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InvestPage;
