import { Button, Table } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';

import {
  DwollaCustomer,
  useGetDwollaCustomersQuery,
} from '../../../__generated__';
import AccountActions from './AdminAccountActions';
import * as styles from './AdminAccounts.module.scss';

const columnHeaders = [
  'ID',
  'Status',
  'Business Name',
  'Email',
  'Verified Bank Accounts',
  'Actions',
];

const getAccountStatus = (account: DwollaCustomer) => {
  if (account.status === 'verified')
    return <div className="textGreen">Verified</div>;
  else return <div className="textRed">Suspended</div>;
};

const getRows = (accounts: (DwollaCustomer | null | undefined)[]) =>
  accounts.map((account: DwollaCustomer | null | undefined) => ({
    cells: account
      ? [
          account.id,
          getAccountStatus(account),
          account.businessName,
          account.email,
          account.bankAccountsCount,
          <AccountActions key={account.id} dwollaCustomer={account} />,
        ]
      : [],
  }));

const AdminAccounts: React.FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { data, loading, fetchMore } = useGetDwollaCustomersQuery({
    variables: {
      pagination: {
        page,
        pageSize: rowsPerPage,
      },
    },
  });

  const fetchMoreAccounts = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreAccounts(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreAccounts(1, pageSize);
  };

  const navigate = useNavigate();

  const handleNewAccount = () => {
    navigate('/accounts/new');
  };

  const hasAccounts =
    data?.dwollaCustomers?.data && data.dwollaCustomers.data.length > 0;

  return (
    <>
      <Row>
        <Col sm={6}>
          <h2 data-testid="headingAdmin">Admin Accounts</h2>
        </Col>
        <Col
          sm={6}
          className={`alignItemsCenter justifyContentEnd ${styles.newAccountButton}`}
        >
          <Button onClick={handleNewAccount} className="alignItemsCenter">
            New Account
          </Button>
        </Col>
      </Row>

      <Table
        columnHeaders={columnHeaders}
        loading={loading}
        data-testid="table"
        loadingRows={10}
        pagination={{
          // MUI expects 0 based index, API expects 1 based
          // MUI throws out of range error if page > 1 and there are no accounts
          page: hasAccounts ? page - 1 : 0,
          rowsPerPage,
          onRowsPerPageChange: handleRowsPerPageChange,
          onPageChange: handlePageChange,
          count: data?.dwollaCustomers?.pageInfo?.count || 0,
        }}
        rows={
          hasAccounts
            ? getRows(data?.dwollaCustomers?.data as DwollaCustomer[])
            : [{ cells: ['No accounts found'] }]
        }
      />
    </>
  );
};

export default AdminAccounts;
